import { axiosInstance } from '../Https/axios-instance'
import { GetReturnDepartament } from './../types/tagType'

class DepartamentsService {
  async get(flowId: string) {
    return await axiosInstance.get<GetReturnDepartament>('/departments', { headers: { flowid: flowId } })
  }

  async filter(flowId: string, query: string) {
    return await axiosInstance.get('/departments', { params: { query }, headers: { flowid: flowId } })
  }
}

export const departamentsService = new DepartamentsService()
