import { Dialog } from 'primereact/dialog'

import { Button } from 'primereact/button'
import { Divider } from 'primereact/divider'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { MultiSelect } from 'primereact/multiselect'
import { Toast } from 'primereact/toast'
import { Tooltip } from 'primereact/tooltip'
import { Fragment, useEffect, useRef, useState } from 'react'
import { BiTrash } from 'react-icons/bi'
import { RxSwitch } from 'react-icons/rx'
import { v4 as uuidv4 } from 'uuid'
import { useCustomizeFieldsStore } from '../../../../store/customizeFields'
import useStore from '../../../../store/store'
import { OPTIONS_CONDITIONS, OptionsConditionsEnum } from '../../../../utils/constants'
import { formatUseIn, handleGetMaskByType } from '../../../../utils/format'
import { itemTemplate } from '../../../../utils/templates'
import styles from './styles.module.css'

type IConditionsType = {
  [key: string]: {
    fieldType: string
    fieldValue: string
    conditionType: string
    conditions: {
      validation: string
      value: string | string[]
    }[]
  }[]
}

export default function ModalCondition({
  isOpen,
  onClose,
  id,
  data,
}: {
  isOpen: boolean
  onClose: () => void
  id: string
  data: any
}) {
  const toast = useRef<Toast>(null)
  const { tags, edges, deleteNoNescessariEdge } = useStore((state) => ({
    tags: state.tags,
    edges: state.edges,
    deleteNoNescessariEdge: state.deleteNoNescessariEdge,
  }))

  const { onToggleModalCustomizeField, optionsFieldFormatted, optionsField } = useCustomizeFieldsStore((state) => ({
    onToggleModalCustomizeField: state.onToggleModalCustomizeField,
    optionsFieldFormatted: state.optionsFieldFormatted({
      otherGroupOptions: [
        {
          id: 'TAGS',
          value: 'Etiquetas',
          useIn: 'OTHERS',
        },
        {
          id: 'SERVICE_HOURS',
          value: 'Horário de atendimento',
          useIn: 'OTHERS',
        },
      ],
    }),
    optionsField: state.optionsField,
  }))

  const updateNodeData = useStore((state) => state.updateNodeData)

  const [fields, setFields] = useState<IConditionsType | null>(null)

  const header = () => {
    return (
      <>
        <div className="flex align-items-center">
          <div className={`w-2rem h-2rem flex justify-content-center align-items-center border-circle bg-teal-300`}>
            <RxSwitch size={20} color="white" />
          </div>
          <span className="text-600 text-base ml-2">Condição</span>
        </div>
        <Divider />
      </>
    )
  }

  const groupedItemTemplate = (option: any) => {
    return (
      <>
        {option.label === 'add-new-field' ? (
          <div
            className="flex align-items-center cursor-pointer font-normal"
            onClick={() => onToggleModalCustomizeField(true)}>
            <span className="text-blue-500">+ Adicionar novo campo</span>
          </div>
        ) : (
          <div className="flex align-items-center">
            <div>{formatUseIn(option.label)}</div>
          </div>
        )}
      </>
    )
  }

  const handleAddNewCondition = () => {
    const newCondition = { validation: '', value: '' }
    const id = uuidv4()

    setFields((prevFields) => ({
      ...prevFields,
      [id]: [
        {
          fieldType: '',
          fieldValue: '',
          conditionType: 'and',
          conditions: [newCondition],
        },
      ],
    }))
  }

  const handleRemoveCondition = (key: string) => {
    setFields((prevFields) => {
      const updatedFields = { ...prevFields }
      delete updatedFields[key]

      const updatedFieldsWithSameKeys = Object.entries(updatedFields).reduce((acc, [currKey, value]) => {
        if (currKey !== key) {
          return { ...acc, [currKey]: value }
        }
        return acc
      }, {})

      return updatedFieldsWithSameKeys
    })
  }

  const handleAddNewValidation = (key: string) => {
    setFields((prevFields) => {
      const updatedFields = { ...prevFields }
      const conditions = updatedFields[key][0].conditions
      const newValidation = {
        validation: '',
        value: '',
      }
      conditions.push(newValidation)
      return updatedFields
    })
  }

  const handleRemoveValidation = (key: string, conditionIndex: number) => {
    setFields((prevFields) => {
      const updatedFields = { ...prevFields }
      const conditions = updatedFields[key][0].conditions
      conditions.splice(conditionIndex, 1)
      return updatedFields
    })
  }

  const getCodeById = (itemId: string) => {
    let foundCode = null
    optionsFieldFormatted.some((section: any) => {
      const foundItem = section.items.find((item: any) => item.id === itemId)
      if (foundItem) {
        foundCode = section.code
        return true
      }
    })
    return foundCode
  }

  const handleGetOptionsConditionsByType = (fieldValue: string) => {
    const customFieldType = optionsField.find((item) => item.id === fieldValue)
    console.log(fieldValue)
    if (fieldValue === OptionsConditionsEnum.TAGS) {
      return OPTIONS_CONDITIONS.filter((item) => item.use.includes(OptionsConditionsEnum.TAGS))
    }

    if (fieldValue === OptionsConditionsEnum.SERVICE_HOURS) {
      return OPTIONS_CONDITIONS.filter((item) => item.use.includes(OptionsConditionsEnum.SERVICE_HOURS))
    }

    const value = OPTIONS_CONDITIONS.filter((item) =>
      item.use.includes(customFieldType?.useIn as OptionsConditionsEnum)
    ).filter((item) => item.typeAccept.includes(customFieldType?.type?.toUpperCase() || ''))

    return value
  }

  const handleGetCustomFieldType = (value: string) => {
    const customFieldType = optionsField.find((item) => item.id === value)

    return customFieldType?.type
  }

  const handleGetOptionsFieldValue = (value: string) => {
    switch (value) {
      case 'BOOLEAN':
        return [
          {
            name: 'Verdadeiro',
            id: 'true',
          },
          {
            name: 'Falso',
            id: 'false',
          },
        ]

      default:
        return []
    }
  }

  const handleVerifyErrorsInFields = () => {
    for (const itemKey in fields) {
      const items = fields[itemKey]

      for (const item of items) {
        if (!item.fieldValue) {
          return false
        }

        for (const condition of item.conditions) {
          if (
            condition.validation === 'isFilled' ||
            condition.validation === 'notIsFilled' ||
            condition.validation === 'isNotOnSchedule' ||
            condition.validation === 'isOnSchedule'
          ) {
            continue
          }

          if (Array.isArray(condition.value)) {
            const hasEmptyValue = condition.value.some((value) => !value)
            if (hasEmptyValue) {
              return false
            }
          } else {
            if (!condition.value) {
              return false
            }
          }
        }
      }
    }
  }

  const handleSubmit = async () => {
    try {
      const error = handleVerifyErrorsInFields()
      if (error === false) {
        return toast?.current?.show({
          severity: 'error',
          summary: 'Ops! Algo deu errado.',
          detail: 'Verifique se você preencheu todos os campos.',
          life: 3000,
        })
      }

      const nodeData = {
        conditions: fields,
      }

      const ids = Object.keys(fields!)

      const removedEdges = edges.filter(
        (edge) => edge.source === id && !ids.includes(edge.sourceHandle || '') && edge.sourceHandle !== 'error'
      )

      if (removedEdges.length) {
        removedEdges.forEach((edge) => {
          const idSourceHandle = edge.sourceHandle?.toString()

          deleteNoNescessariEdge(id, idSourceHandle || '')
        })
      }

      updateNodeData(id, nodeData)
      onClose()
    } catch (error) {
      console.log('Failed to update')
    }
  }

  const hasCondition = Object.keys(data?.conditions || {}).length > 0

  useEffect(() => {
    const uuid = uuidv4()

    setFields(
      hasCondition
        ? data.conditions
        : {
            [uuid]: [
              {
                fieldType: '',
                fieldValue: '',
                conditionType: 'and',
                conditions: [
                  {
                    validation: '',
                    value: '',
                  },
                ],
              },
            ],
          }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Dialog
      className={styles.mypanel}
      header={header}
      onHide={onClose}
      visible={isOpen}
      style={{ width: '600px' }}
      draggable={false}>
      <Toast ref={toast} />
      <div className="mb-4">
        {!!fields &&
          Object.entries(fields).map(([key, value]) => (
            <Fragment key={key}>
              <div className="flex align-items-center justify-content-center">
                <Divider>
                  <span className="text-base font-bold text-gray-800">
                    {key !== Object.keys(fields)[0] ? 'Ou, se' : 'Verifique se'}
                  </span>
                </Divider>
                <header className="flex justify-content-between align-items-center ml-2">
                  <div className="flex align-items-center w-full justify-content-end">
                    <Tooltip target=".delete-condition" />
                    <div
                      onClick={() => handleRemoveCondition(key)}
                      data-pr-tooltip={`Apagar condição`}
                      data-pr-position="left"
                      className="delete-condition cursor-pointer text-sm text-gray-600 flex align-items-center gap-2">
                      <BiTrash color="red" size={20} />
                    </div>
                  </div>
                </header>
              </div>
              <div>
                {value.map((field, index) => (
                  <Fragment key={index}>
                    <div className="mb-1 text-sm text-gray-600 font-bold">o item</div>
                    <div className="w-full mb-2">
                      <Dropdown
                        value={field.fieldValue}
                        onChange={(e) => {
                          const { value } = e.target

                          setFields((prevFields) => {
                            const updatedFields = { ...prevFields }
                            updatedFields[key][0].fieldType = getCodeById(value) || ''
                            updatedFields[key][0].fieldValue = value
                            updatedFields[key][0].conditions = updatedFields[key][0].conditions.map((cond) => ({
                              validation: cond.validation,
                              value: '',
                            }))
                            return updatedFields
                          })
                        }}
                        options={optionsFieldFormatted}
                        itemTemplate={itemTemplate}
                        optionLabel="value"
                        optionValue="id"
                        optionGroupLabel="label"
                        optionGroupChildren="items"
                        optionGroupTemplate={groupedItemTemplate}
                        className="w-full"
                        placeholder="Selecione um item para verificar o valor"
                      />
                    </div>

                    <div className="mb-4 text-sm text-gray-600 font-bold">
                      corresponde a
                      <Tooltip target=".condition-type" />
                      <button
                        data-pr-tooltip={`clique e troque para ${
                          field.conditionType === 'or'
                            ? 'todas as seguintes validações'
                            : 'qualquer uma das seguintes validações'
                        }`}
                        data-pr-position="bottom"
                        onClick={() => {
                          setFields((prevFields) => {
                            const updatedFields = { ...prevFields }
                            updatedFields[key][0].conditionType =
                              updatedFields[key][0].conditionType === 'and' ? 'or' : 'and'
                            return updatedFields
                          })
                        }}
                        className="condition-type text-sm text-purple-600 font-bold cursor-pointer border-none"
                        style={{
                          background: 'transparent',
                        }}>
                        {field.conditionType === 'and'
                          ? 'todas as seguintes validações'
                          : 'qualquer uma das seguintes validações'}
                      </button>
                    </div>
                    {field.conditions.map((condition, index) => {
                      const typeCustomField = handleGetCustomFieldType(field.fieldValue)?.toUpperCase()

                      const hideInput = typeCustomField !== 'BOOLEAN' && field.fieldValue !== OptionsConditionsEnum.TAGS

                      return (
                        <div className="flex w-full justify-content-between gap-4 mb-3" key={index}>
                          <div className="w-full">
                            <div className="mb-1 text-sm text-gray-600 font-bold">se</div>
                            <Dropdown
                              className={`w-full`}
                              placeholder="Validação"
                              options={handleGetOptionsConditionsByType(field.fieldValue)}
                              disabled={!field.fieldType}
                              value={condition.validation}
                              onChange={(e) => {
                                const { value } = e.target
                                setFields((prevFields) => {
                                  const updatedFields = { ...prevFields }
                                  updatedFields[key][0].conditions[index].validation = value
                                  return updatedFields
                                })
                              }}
                            />
                          </div>
                          <div className="w-full">
                            <div className="mb-1 text-sm text-gray-600 font-bold">o valor</div>
                            <div className="flex align-items-center gap-2">
                              {field.fieldValue === 'TAGS' && (
                                <MultiSelect
                                  style={{
                                    width: '230px',
                                  }}
                                  options={tags}
                                  display="chip"
                                  optionLabel="name"
                                  optionValue="id"
                                  multiple={true}
                                  placeholder="Selecione etiquetas"
                                  value={condition.value}
                                  onChange={(e) => {
                                    const { value } = e.target

                                    setFields((prevFields) => {
                                      const updatedFields = { ...prevFields }
                                      updatedFields[key][0].conditions[index].value = value
                                      return updatedFields
                                    })
                                  }}
                                />
                              )}

                              {hideInput && (
                                <InputText
                                  className={`w-full`}
                                  placeholder="Valor"
                                  disabled={
                                    !field.fieldType ||
                                    condition.validation === 'isFilled' ||
                                    condition.validation === 'notIsFilled' ||
                                    condition.validation === 'isOnSchedule' ||
                                    condition.validation === 'isNotOnSchedule'
                                  }
                                  value={condition.value as string}
                                  onChange={(e) => {
                                    const { value } = e.target

                                    const maskedValue = handleGetMaskByType(value, field.fieldValue, optionsField)

                                    setFields((prevFields) => {
                                      const updatedFields = { ...prevFields }
                                      updatedFields[key][0].conditions[index].value = maskedValue
                                      return updatedFields
                                    })
                                  }}
                                />
                              )}

                              {['BOOLEAN'].includes(typeCustomField || '') && (
                                <Dropdown
                                  style={{
                                    width: '230px',
                                  }}
                                  optionLabel="name"
                                  optionValue="id"
                                  placeholder="Selecione um valor"
                                  value={condition.value}
                                  options={handleGetOptionsFieldValue(typeCustomField || '')}
                                  onChange={(e) => {
                                    const { value } = e.target

                                    setFields((prevFields) => {
                                      const updatedFields = { ...prevFields }
                                      updatedFields[key][0].conditions[index].value = value
                                      return updatedFields
                                    })
                                  }}
                                />
                              )}
                              <div className="cursor-pointer" onClick={() => handleRemoveValidation(key, index)}>
                                <BiTrash color="red" size={20} />
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                    <div
                      className="flex align-items-center cursor-pointer font-normal mt-3"
                      onClick={() => handleAddNewValidation(key)}>
                      <span className="text-blue-500">+ Adicionar validação</span>
                    </div>
                  </Fragment>
                ))}
              </div>
            </Fragment>
          ))}

        <div className="flex justify-content-center">
          <button
            className="cursor-pointer mb-2 mt-5 w-16rem h-3rem border-round-md border-1 border-purple-500 text-sm font-bold text-purple-500 bg-white-alpha-10"
            onClick={handleAddNewCondition}>
            + Adicionar outra condição
          </button>
        </div>
      </div>
      <Button label="Salvar" onClick={handleSubmit} className="p-button-help w-full mt-4" />
    </Dialog>
  )
}
