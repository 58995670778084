import { memo, useState } from 'react'
import { Handle, NodeProps, Position } from 'reactflow'
import { BsStickies } from 'react-icons/bs'
import { Image } from 'primereact/image'
import { OptionsHover } from '../../components/OptionsHover'
import ModalFile from '../../components/ModalFile'

const StickNode = ({ id, isConnectable }: NodeProps) => {
  const [hover, setHover] = useState('hidden')
  const [title, setTitle] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [image, setImage] = useState('')

  return (
    <div onMouseEnter={() => setHover('flex')} onMouseLeave={() => setHover('hidden')}>
      <OptionsHover id={id} className={hover} setModalOpen={setModalOpen} />
      <ModalFile
        type="image"
        fileFromNode={image}
        setShowFile={setImage}
        hasFileUpload={true}
        visible={modalOpen}
        onHide={() => setModalOpen(false)}
        text="stick"
        icon={<BsStickies color="white" />}
        accept="image/*"
      />

      <div className="border-round-lg w-18rem" style={{ border: '1px solid #1FCEC3', backgroundColor: '#FFF' }}>
        <div className="flex pl-4 pr-3 py-3 relative border-bottom-1" style={{ borderColor: '#9E9E9E' }}>
          <div>
            <div className="w-2rem h-2rem flex justify-content-center align-items-center border-circle bg-purple-300">
              <BsStickies size={26} color="white" />
            </div>
            <div>
              <Handle
                type="target"
                position={Position.Left}
                id="a"
                style={{
                  background: '#FFF',
                  border: 'solid 1px #1FCEC3',
                  height: '16px',
                  width: '16px',
                  left: '-9px',
                }}
                isConnectable={isConnectable}
              />
            </div>
          </div>
          <div className="flex ml-3 flex-column align-items-start">
            <div className="">
              <input
                className="input-textNode-styled border-none w-9rem border-bottom-1 border-dashed font-bold h-1rem line-height-2 text-base vertical-align-middle"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Enviar stick"
                name=""
                id=""
              />
            </div>
            <div className="font-normal text-sm text-purple-300">Stick</div>
          </div>
        </div>
        {image ? (
          <>
            <div className="flex justify-content-center py-2 cursor-pointer">
              <div
                className="bg-purple-300 flex flex-column align-items-start py-2 cursor-pointer w-15rem p-2 border-round-lg border-1 text-left border-purple-400"
                onClick={() => setModalOpen(true)}>
                <span className="">
                  <Image width="100%" src={image} />
                </span>
              </div>
            </div>
            <div
              className="py-4 justify-content-end flex aling-items-center relative h-2rem border-top-1 border-round-bottom-lg "
              style={{ borderColor: '#E5E7EB', backgroundColor: '#FBFBFC' }}>
              <div className="flex justify-content-center align-items-center">
                <span className="text-xs line-height-1 cursor-pointer mr-3">Próximo passo</span>
              </div>
              <Handle
                type="source"
                position={Position.Right}
                id="a"
                style={{
                  background: '#FFF',
                  border: 'solid 1px #1FCEC3',
                  height: '18px',
                  width: '18px',
                  right: '-9px',
                }}
                isConnectable={isConnectable}
              />
            </div>
          </>
        ) : (
          <div onClick={() => setModalOpen(true)} className="text-blue-400 my-3 cursor-pointer">
            Configurar etapa do fluxo
          </div>
        )}
      </div>
    </div>
  )
}

StickNode.displayName = 'StickNode'

export default memo(StickNode)
