import { memo, useEffect, useState } from 'react'
import { AiOutlineThunderbolt } from 'react-icons/ai'
import { Handle, NodeProps, Position } from 'reactflow'
import styled from 'styled-components'
import { Departament } from '../../data/types/tagType'
import useStore from './../../store/store'
import { OptionsHover } from '../../components/OptionsHover'
import ModalFile from '../../components/ModalFile'
import { convertStringToDb, operationNamed } from '../../utils/convertStrings'
import { useCustomizeFieldsStore } from '../../store/customizeFields'
import { IActionProps } from '../../components/Tag'

interface Tag {
  name: string
  color?: string
  companyId?: string
  createdAt?: string
  id: string
}

const TagDiv = styled.div`
  :not(:last-child)&::after {
    content: ',';
  }
`

const ActionNode = ({ id, data, isConnectable }: NodeProps) => {
  const { optionsField } = useCustomizeFieldsStore((state) => ({
    optionsField: state.optionsField,
  }))
  const [hover, setHover] = useState('hidden')
  const [title, setTitle] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [acaoList, setacaoList] = useState<IActionProps[]>()
  const [old, setOld] = useState<any>()

  const [tags] = useState()

  const updateNodeData = useStore((state) => state.updateNodeData)

  function changeActionType(type: string, attendantName?: string) {
    switch (type) {
      case 'ADD_TAGS':
        return 'Adicionar Etiqueta'
      case 'REMOVE_TAGS':
        return 'Remover Etiqueta'
      case 'ADD_DEPARTMENTS':
        return 'Transferir ao departamento'
      case 'REMOVE_DEPARTMENTS':
        return 'Remover do departamento'
      case 'NOTIFICATION':
        return 'Notificar usuários pelo whatsapp'
      case 'UPDATE_FIELD':
        return 'Atualizar valor de campo personalizado'
      case 'CLEAR_FIELD':
        return 'Limpar valor de campo personalizado'
      case 'MOVE_ATTENDANT':
        return `Atribuir conversa a este atendente ${attendantName}`
      case 'END_SERVICE':
        return `Finalizar atendimento`
    }
  }

  useEffect(() => {
    if (modalOpen) return
    if (acaoList === old) return
    if (acaoList === undefined) return
    const updateNode = {
      actions: acaoList.map((acao) => {
        const tags = acao.tags?.map((tags) => {
          return tags.id
        })
        const departments = acao.departments?.map((departament) => {
          return departament.id
        })

        if (acao.actionType === 'MOVE_ATTENDANT') {
          return {
            actionType: acao.actionType,
            attendant: acao.attendant,
            attendantName: acao.attendantName,
          }
        }

        if (acao.actionType === 'UPDATE_FIELD' || acao.actionType === 'CLEAR_FIELD') {
          const fieldType = optionsField.find((item) => item.id === acao.customFields?.field)?.type

          return {
            actionType: acao.actionType,
            customFields: {
              field: acao.customFields?.field,
              ...(acao.customFields?.value && {
                value: acao.customFields?.value,
              }),
              ...(acao.customFields?.operation &&
                fieldType === 'NUMBER' && {
                  operation: acao.customFields?.operation,
                }),
            },
          }
        }

        if (acao.actionType === 'NOTIFICATION') {
          return {
            actionType: acao.actionType,
            notification: {
              ...acao.notification,
              text: convertStringToDb(acao.notification?.text || ''),
            },
          }
        }
        if (acao.actionType === 'ADD_DEPARTMENTS' || acao.actionType === 'REMOVE_DEPARTMENTS') {
          return {
            actionType: acao.actionType,
            departments,
          }
        } else {
          return {
            actionType: acao.actionType,
            tags,
          }
        }
      }),
    }

    updateNodeData(id, updateNode)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen])

  useEffect(() => {
    if (data === undefined) return
    if (data.actions) {
      if (data.actions.length > 0) {
        if (data.actions[0]?.tags) {
          if (data.actions[0]?.tags[0]?.name) {
            setacaoList(data.actions)
            setOld(data.actions)
          }
        } else {
          setacaoList(data.actions)
          setOld(data.actions)
        }
      }
    }
  }, [data])

  return (
    <div onMouseEnter={() => setHover('flex')} onMouseLeave={() => setHover('hidden')}>
      <OptionsHover id={id} className={hover} setModalOpen={setModalOpen} />
      <ModalFile
        visible={modalOpen}
        nodeId={id}
        onHide={() => setModalOpen(false)}
        hasAcao
        tags={tags}
        acaoFromNode={acaoList}
        setAcao={setacaoList}
        text="Ação"
        color="yellow"
        icon={<AiOutlineThunderbolt color="white" />}
      />
      <div className="border-round-lg w-18rem" style={{ border: '1px solid #1FCEC3', backgroundColor: '#FFF' }}>
        <div className="flex pl-4 pr-3 py-3 relative border-bottom-1" style={{ borderColor: '#9E9E9E' }}>
          <div>
            <div className="w-2rem h-2rem flex justify-content-center align-items-center border-circle bg-yellow-300">
              <AiOutlineThunderbolt size={22} color="white" />
            </div>
            <div>
              <Handle
                type="target"
                position={Position.Left}
                id="a"
                style={{
                  background: '#FFF',
                  border: 'solid 1px #1FCEC3',
                  height: '16px',
                  width: '16px',
                  left: '-9px',
                }}
                isConnectable={isConnectable}
              />
            </div>
          </div>
          <div className="flex ml-3 flex-column align-items-start">
            <div className="">
              <input
                className="input-textNode-styled border-none w-8rem border-bottom-1 border-dashed font-bold h-1rem line-height-2 text-base vertical-align-middle"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Executar Ação"
                name=""
                id=""
              />
            </div>
            <div className="font-normal text-sm text-yellow-300">Ação</div>
          </div>
        </div>

        {acaoList ? (
          <div onClick={() => setModalOpen(true)} className="cursor-pointer">
            {acaoList === undefined
              ? null
              : acaoList.map((acao: IActionProps, i: number) => {
                  const fields = acao.actionType === 'UPDATE_FIELD' || acao.actionType === 'CLEAR_FIELD'

                  return (
                    <div
                      className="bg-gray-300 border-round border-1 border-gray-400 m-3 flex flex-column align-items-start p-2"
                      key={i}>
                      <div>
                        <b>Tipo da Ação</b>
                      </div>

                      <div className="mt-1">
                        {changeActionType(acao.actionType || '', acao?.attendantName)}
                        {!!acao.customFields?.operation && (
                          <div className="mt-1 text-center">
                            <b>{operationNamed(acao.customFields?.operation)}</b> o número{' '}
                            <b>{acao.customFields?.value}</b> em campo personalizado{' '}
                            <div
                              style={{
                                color: '#1769aa',
                                background: '#a0d2fa',
                                padding: '2px 5px',
                                borderRadius: '5px',
                                display: 'inline-flex',
                              }}>
                              {optionsField.find((item) => item.id === acao.customFields?.field)?.value}
                            </div>
                          </div>
                        )}
                        {fields && !acao.customFields?.operation && (
                          <>
                            <div
                              style={{
                                color: '#1769aa',
                                background: '#a0d2fa',
                                padding: '2px 5px',
                                borderRadius: '5px',
                                display: 'inline-flex',
                              }}>
                              {optionsField.find((item) => item.id === acao.customFields?.field)?.value}
                            </div>
                            {acao.actionType === 'UPDATE_FIELD' && !acao.customFields?.operation && (
                              <span>
                                {acao.customFields?.value && (
                                  <>
                                    para o valor <b>{acao.customFields?.value}</b>
                                  </>
                                )}
                              </span>
                            )}
                          </>
                        )}
                      </div>

                      <div className="mt-3">
                        {acao.actionType === 'ADD_DEPARTMENTS' ||
                          (acao.actionType === 'REMOVE_DEPARTMENTS' && <b>Departamento</b>)}
                        {acao.actionType === 'ADD_TAGS' || (acao.actionType === 'REMOVE_TAGS' && <b>Etiquetas</b>)}
                      </div>

                      <div className="flex mt-1 flex-wrap">
                        {acao.actionType === 'ADD_DEPARTMENTS' || acao.actionType === 'REMOVE_DEPARTMENTS' ? (
                          <>
                            {acao.departments?.map((dp: Departament, index: number) => {
                              return <TagDiv key={index}>{dp.name}</TagDiv>
                            })}
                          </>
                        ) : (
                          <>
                            {acao.tags?.map((tg: Tag, index: number) => {
                              return <TagDiv key={index}>{tg.name}</TagDiv>
                            })}
                          </>
                        )}
                      </div>
                      <div>{}</div>
                    </div>
                  )
                })}
            <div
              className="py-4 justify-content-end flex aling-items-center relative h-2rem border-top-1 border-round-bottom-lg "
              style={{ borderColor: '#E5E7EB', backgroundColor: '#FBFBFC' }}>
              <div className="flex justify-content-center align-items-center">
                <span className="text-xs line-height-1 cursor-pointer mr-3">Próximo passo</span>
              </div>
              <Handle
                type="source"
                position={Position.Right}
                id="a"
                style={{
                  background: '#FFF',
                  border: 'solid 1px #1FCEC3',
                  height: '18px',
                  width: '18px',
                  right: '-9px',
                }}
                isConnectable={isConnectable}
              />
            </div>
          </div>
        ) : (
          <>
            <div onClick={() => setModalOpen(true)} className="text-blue-400 my-3 cursor-pointer">
              Configurar etapa do fluxo
            </div>
            <Handle
              type="source"
              position={Position.Right}
              id="a"
              style={{
                background: '#FFF',
                border: 'solid 1px #1FCEC3',
                height: '18px',
                width: '18px',
                right: '-9px',
                visibility: 'hidden',
              }}
              isConnectable={isConnectable}
            />
          </>
        )}
      </div>
    </div>
  )
}

ActionNode.displayName = 'ActionNode'

export default memo(ActionNode)
