import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DropResult,
  Droppable,
  DroppableProvided,
} from 'react-beautiful-dnd'
import { Divider } from 'primereact/divider'
import styles from './styles.module.css'
import { Dialog } from 'primereact/dialog'
import { BsMenuButtonWide } from 'react-icons/bs'
import LabelText from '../../../../components/LabelText'
import { Checkbox } from 'primereact/checkbox'
import { useEffect, useState } from 'react'
import { InputNumber } from 'primereact/inputnumber'
import { Dropdown } from 'primereact/dropdown'
import EditorComponent from '../../../../components/QuillComponent'
import { Button as IButtonProps } from '../../../../data/types/nodeType'
import { convertStringFromDb, convertStringToDb } from '../../../../utils/convertStrings'
import useStore from '../../../../store/store'
import { z } from 'zod'
import { Button } from 'primereact/button'
import { InputTextarea } from 'primereact/inputtextarea'
import { InputText } from 'primereact/inputtext'
import { FaRegTrashAlt } from 'react-icons/fa'
import { IoReorderThreeOutline } from 'react-icons/io5'

type IFieldsProps = {
  text: string
  waitingDuration: number | null
  waitingDurationType: string
  buttons: IButtonProps[]
  incorrectAnswerMessage: string
  retryAnswerMessage: string
}

const schema = z.object({
  waitingDuration: z.coerce.number().min(1, {
    message: 'Campo obrigatório',
  }),
  waitingDurationType: z.string().nonempty({
    message: 'Campo obrigatório',
  }),
})

export function ModalButton({
  isOpen,
  onClose,
  id,
  data,
  setButtons,
}: {
  isOpen: boolean
  onClose: () => void
  id: string
  data: any
  setButtons: (buttons: IButtonProps[]) => void
}) {
  const { updateNodeData, deleteEdgeFromButton } = useStore((state) => ({
    updateNodeData: state.updateNodeData,
    deleteEdgeFromButton: state.deleteEdgeFromButton,
  }))

  const [fields, setFields] = useState<IFieldsProps>({
    buttons: [],
    text: '',
    waitingDuration: null,
    waitingDurationType: '',
    incorrectAnswerMessage: 'Opção inválida, tente novamente digitando uma das opções.',
    retryAnswerMessage: '3',
  })
  const [errorsFields, setErrorsFields] = useState({
    waitingDuration: '',
    waitingDurationType: '',
  })

  const [isTimeResponse, setIsTimeResponse] = useState(false)

  const ajustButton = (buttons: IButtonProps[]) => {
    if (buttons.length) {
      return buttons
        .filter((button) => button.label.length > 0)
        .map((button, index) => {
          if (button.id) {
            return { ...button, position: index + 1 }
          } else {
            return { label: button.label, position: index + 1 }
          }
        })
    }
  }

  const handleSubmit = async () => {
    try {
      if (isTimeResponse) {
        schema.parse({
          waitingDuration: fields.waitingDuration,
          waitingDurationType: fields.waitingDurationType,
        })
      }

      const nodeData = {
        ...(isTimeResponse
          ? {
              waitingDuration: fields.waitingDuration,
              waitingDurationType: fields.waitingDurationType,
            }
          : {
              waitingDuration: 0,
              waitingDurationType: 'SECONDS',
            }),
        text: convertStringToDb(fields.text),
        buttons: ajustButton(fields.buttons) || [],
        incorrectAnswerMessage: fields.incorrectAnswerMessage,
        retryAnswerMessage: fields.retryAnswerMessage,
      }
      updateNodeData(id, nodeData).then((response) => {
        const buttons = response.data.data.buttons
        setButtons(buttons)
        setFields((prevState) => ({
          ...prevState,
          buttons,
        }))
      })

      onClose()
    } catch (error: any) {
      if (error.formErrors?.fieldErrors) {
        setErrorsFields(error.formErrors.fieldErrors)
      }
    }
  }

  useEffect(() => {
    if (data.text || data.buttons) {
      setFields({
        text: convertStringFromDb(data.text),
        waitingDuration: data.waitingDuration,
        waitingDurationType: data.waitingDurationType,
        buttons: data.buttons || [],
        incorrectAnswerMessage: data.incorrectAnswerMessage || fields.incorrectAnswerMessage,
        retryAnswerMessage: String(data.retryAnswerMessage) || fields.retryAnswerMessage,
      })

      !!data.waitingDuration && setIsTimeResponse(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const header = () => {
    return (
      <>
        <div className="flex align-items-center">
          <div className={`w-2rem h-2rem flex justify-content-center align-items-center border-circle bg-bluegray-400`}>
            <BsMenuButtonWide size={20} color="white" />
          </div>
          <span className="text-600 text-base ml-2">Menu de opções</span>
        </div>
        <Divider />
      </>
    )
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const newItems = Array.from(fields.buttons)
    const [removed] = newItems.splice(result.source.index, 1)

    newItems.splice(result.destination.index, 0, removed)
    setFields((prevState) => ({ ...prevState, buttons: newItems }))
  }

  return (
    <Dialog
      className={styles.mypanel}
      header={header}
      onHide={onClose}
      visible={isOpen}
      style={{ width: '500px' }}
      draggable={false}>
      <div className="flex flex-column mb-6 ">
        <label className="font-bold mb-2">Mensagem:</label>
        <EditorComponent
          text={fields.text}
          setText={(value) => {
            setFields((prevState) => ({ ...prevState, text: value }))
          }}
        />
      </div>

      <div className="font-bold pb-4">Opções:</div>
      <div className="flex flex-column mb-4">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {(provided: DroppableProvided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {fields.buttons?.map((list, key) => (
                  <Draggable draggableId={`draggable-${key}`} key={key} index={key}>
                    {(provided: DraggableProvided) => (
                      <div
                        className="flex mb-2"
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}>
                        <div className="flex align-items-center text-gray-600 mr-2 font-bold">{key + 1}</div>
                        <div className="w-full">
                          <InputText
                            className="w-full"
                            value={list.label || ''}
                            onChange={(value) => {
                              const newButton = [...fields.buttons]
                              newButton[key].label = value.target.value

                              setFields((prevState) => ({ ...prevState, buttons: newButton }))
                            }}
                            placeholder="Texto da opção"
                          />
                        </div>
                        <div
                          className="ml-2 gap-2 flex align-items-center cursor-pointer"
                          onClick={() => {
                            const newButton = [...fields.buttons]
                            const deletedButton = newButton[key]
                            newButton.splice(key, 1)
                            setFields((prevState) => ({
                              ...prevState,
                              buttons: newButton,
                            }))
                            if (deletedButton.id) deleteEdgeFromButton(deletedButton.id)
                          }}>
                          <IoReorderThreeOutline
                            size={24}
                            style={{
                              cursor: 'move',
                            }}
                          />
                          <FaRegTrashAlt size={18} color={'red'} />
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div
        className="cursor-pointer text-blue-400"
        onClick={() => {
          setFields({
            ...fields,
            buttons: [...fields.buttons, {} as IButtonProps],
          })
        }}>
        + Adicionar nova opção
      </div>

      <div className="mb-4 mt-4">
        <LabelText>
          Configurar tempo de resposta?
          <Checkbox className="ml-3" checked={isTimeResponse} onChange={(e) => setIsTimeResponse(e.checked || false)} />
        </LabelText>

        {isTimeResponse && (
          <div className="mt-3">
            <label className="font-bold">Deve responder em:</label>
            <div className="flex gap-2 mt-2">
              <div className="w-full relative">
                <InputNumber
                  className={`w-full ${errorsFields.waitingDuration ? 'p-invalid' : ''}`}
                  placeholder="Tempo"
                  value={fields.waitingDuration}
                  onValueChange={(e) => setFields({ ...fields, waitingDuration: e.value || null })}
                />
                {errorsFields.waitingDuration && (
                  <small className="p-error absolute top-100 left-0">{errorsFields.waitingDuration}</small>
                )}
              </div>
              <div className="w-full relative">
                <Dropdown
                  className={`w-full ${errorsFields.waitingDurationType ? 'p-invalid' : ''}`}
                  placeholder="Medida de tempo"
                  options={[
                    { label: 'Segundo(s)', value: 'SECONDS' },
                    { label: 'Minuto(s)', value: 'MINUTES' },
                    { label: 'Hora(s)', value: 'HOURS' },
                    { label: 'Dia(s)', value: 'DAYS' },
                  ]}
                  value={fields.waitingDurationType}
                  onChange={(e) => setFields({ ...fields, waitingDurationType: e.value })}
                />
                {errorsFields.waitingDurationType && (
                  <small className="p-error absolute top-100 left-0">{errorsFields.waitingDurationType}</small>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="mb-2">
        <LabelText>Resposta incorreta:</LabelText>
      </div>
      <div className="flex align-items-center">
        <span className="white-space-nowrap">Tente novamente</span>
        <Dropdown
          options={['0', '1', '2', '3', '4', '5']}
          scrollHeight="300px"
          className="flex align-items-center h-2rem w-4rem ml-2 mr-2"
          value={fields.retryAnswerMessage}
          onChange={(e) => {
            setFields({ ...fields, retryAnswerMessage: e.value })
          }}
        />
        <span className="white-space-nowrap"> vezes se a resposta for inválida</span>
      </div>

      <InputTextarea
        value={fields.incorrectAnswerMessage}
        className="w-full mt-2"
        autoResize
        placeholder="Repita a seguinte mensagem se a resposta for incorreta"
        onChange={(e) => setFields({ ...fields, incorrectAnswerMessage: e.target.value })}
        rows={5}
      />

      <Button label="Salvar" className="p-button-help w-full mt-4" onClick={handleSubmit} />
    </Dialog>
  )
}
