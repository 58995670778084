import { create } from 'zustand'
import { ICreateFieldProps, IFieldsProps } from '../@types/services/fields-integration'
import { createField, getFields } from '../services/fields'

export type IOptionsCustomizeFieldsProps = {
  id: string
  value: string
  useIn?: string
  type?: string
}

export type ICustomizeFieldsStore = {
  isOpenModalCustomizeField: boolean
  onToggleModalCustomizeField: (value: boolean) => void
  optionsField: IOptionsCustomizeFieldsProps[]
  setOptionsField: (value: ICustomizeFieldsProps) => void
  handleGetFields: (params?: IFieldsProps) => void
  handleCreateField: (params: ICreateFieldProps) => void
  optionsFieldFormatted: ({
    otherGroupOptions,
    filterType,
  }: {
    otherGroupOptions?: IOptionsCustomizeFieldsProps[]
    filterType?: string
  }) => any
}

type ICustomizeFieldsProps = {
  type: string
  name: string
  key: string
  useIn?: string
  mask?: string
}

export const useCustomizeFieldsStore = create<ICustomizeFieldsStore>((set, get) => ({
  isOpenModalCustomizeField: false,
  onToggleModalCustomizeField: (value) =>
    set(() => ({
      isOpenModalCustomizeField: value,
    })),
  optionsField: [] as IOptionsCustomizeFieldsProps[],
  setOptionsField: (value) =>
    set(() => ({
      optionsField: [
        ...get().optionsField,
        {
          id: `{{${value.key}}}`,
          value: value.name,
          useIn: value.useIn,
          type: value.type,
        },
      ],
    })),
  handleGetFields: async () => {
    try {
      const data = await getFields()

      const mapper = data.map((field) => ({
        id: `{{${field.key}}}`,
        value: field.label,
        useIn: field.useIn,
        type: field.type,
      }))

      set(() => ({
        optionsField: mapper,
      }))
    } catch (error) {
      console.log(error)
    }
  },
  handleCreateField: async (params: ICreateFieldProps) => {
    await createField(params)

    set(() => ({
      optionsField: [
        ...get().optionsField,
        {
          id: `{{${params.key}}}`,
          value: params.label,
          useIn: params.useIn,
          type: params.type,
          ...(params.mask && {
            mask: params.mask,
          }),
        },
      ],
    }))
  },
  optionsFieldFormatted: ({
    otherGroupOptions,
    filterType,
  }: {
    otherGroupOptions?: IOptionsCustomizeFieldsProps[]
    filterType?: string
  }) => {
    let fields = get().optionsField

    if (Array.isArray(otherGroupOptions) && otherGroupOptions.length > 0) {
      fields = fields.concat(otherGroupOptions)
    }

    const reducer = fields.reduce((result: any, item) => {
      if (!filterType || item.type === filterType) {
        const useIn = item.useIn
        const existingGroup = result.find((group: any) => group.label === useIn)

        if (existingGroup) {
          existingGroup.items.push({
            id: item.id,
            value: item.useIn === 'OTHERS' ? item.value : item.value + ' - ' + item.id,
            type: item.type,
          })
        } else {
          result.push({
            label: useIn,
            code: useIn,
            items: [
              {
                id: item.id,
                value: item.useIn === 'OTHERS' ? item.value : item.value + ' - ' + item.id,
                type: item.type,
              },
            ],
          })
        }
      }

      return result
    }, [])

    return reducer
  },
}))
