import { BiTime } from 'react-icons/bi'
import { HiOutlineCalendar } from 'react-icons/hi2'
import { MdAlternateEmail } from 'react-icons/md'
import { RiHashtag } from 'react-icons/ri'
import { RxSwitch, RxText } from 'react-icons/rx'
import { TbCurrencyReal, TbNumber9, TbPhone } from 'react-icons/tb'
// import { VscJson } from 'react-icons/vsc'

export const itemTemplate = (option: any) => {
  const icon = (type: string) => {
    const upper = type?.toUpperCase()

    switch (upper) {
      case 'STRING':
        return <RxText size={16} />
      case 'DATE':
        return <HiOutlineCalendar size={16} />
      case 'NUMBER':
        return <TbNumber9 size={16} />
      case 'BOOLEAN':
        return <RxSwitch size={16} />
      case 'EMAIL':
        return <MdAlternateEmail size={16} />
      case 'PHONE':
        return <TbPhone size={16} />
      case 'MONETARY':
        return <TbCurrencyReal size={16} />
      case 'MASK':
        return <RiHashtag size={16} />
      case 'TIME':
        return <BiTime size={16} />
      // case 'JSON':
      //   return <VscJson size={16} />
    }
  }

  return (
    <span className="flex align-items-center gap-2">
      {icon(option.type)} {option.value}
    </span>
  )
}
