import { memo } from 'react'
import { BsFillCaretRightFill } from 'react-icons/bs'
import { Handle, NodeProps, Position } from 'reactflow'

const StartNode = ({ isConnectable }: NodeProps) => {
  return (
    <>
      <div
        className=""
        style={{
          width: '18rem',
          backgroundColor: '#54ED95',
          borderRadius: '0.75rem',
          color: 'white',
        }}>
        <div className="flex justify-content-center p-3 align-items-center" style={{}}>
          <div
            className="border-circle w-2rem h-2rem flex justify-content-center align-items-center align-content-center text-6xl"
            style={{ backgroundColor: 'white' }}>
            <BsFillCaretRightFill color="#54ED95" />
          </div>

          <div className="ml-4 font-semibold text-xl">Ínicio do fluxo</div>
        </div>
        <div className="font-medium text-lg pb-2 px-4 line-height-2 text-left">
          Esse é o início do fluxo, ele é inicializado através dos seus gatilhos configurados
        </div>
        <div className="py-3 justify-content-end flex aling-items-center relative h-1rem">
          <div className="flex justify-content-center align-items-center">
            <span className="text-xs line-height-1 cursor-pointer mr-3">Próximo passo</span>
          </div>
          <Handle
            type="source"
            position={Position.Right}
            id="a"
            style={{
              background: '#FFF',
              border: 'solid 1px #1FCEC3',
              height: '18px',
              width: '18px',
              right: '-9px',
            }}
            isConnectable={isConnectable}
          />
        </div>
      </div>
    </>
  )
}

StartNode.displayName = 'StartNode'

export default memo(StartNode)
