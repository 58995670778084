import { AiOutlineClose, AiOutlineDelete } from 'react-icons/ai'
import { Dropdown, DropdownChangeEvent, DropdownFilterEvent } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import { useEffect, useState } from 'react'
import useStore from './../../store/store'
import { Departament } from '../../data/types/tagType'
import EditorComponent from '../QuillComponent'
import { MultiSelect } from 'primereact/multiselect'
import { getUsers } from '../../services/users'
import { useCustomizeFieldsStore } from '../../store/customizeFields'
import { itemTemplate } from '../../utils/templates'
import { formatUseIn, handleGetMaskByType } from '../../utils/format'
import { InputText } from 'primereact/inputtext'
import { phoneMask } from '../../utils/mask'

interface PropsAcaoTag {
  tags?: Tag[]
  departments?: Departament[]
  acaoList: IActionProps[]
  setAcaoList: (acao: IActionProps[]) => void
}

export interface IActionProps {
  tags?: Tag[]
  departments?: Departament[]
  id: string
  notification?: {
    text?: string
    specificUsers?: string[]
  }
  customFields?: {
    field?: string
    value?: string
    operation?: string
  }
  attendant?: string
  attendantName?: string
  actionType:
    | null
    | 'ADD_TAGS'
    | 'REMOVE_TAGS'
    | 'UPDATE_FIELD'
    | 'ADD_DEPARTMENTS'
    | 'REMOVE_DEPARTMENTS'
    | 'NOTIFICATION'
    | 'CLEAR_FIELD'
    | 'MOVE_ATTENDANT'
    | 'END_SERVICE'
}

interface Tag {
  name: string
  color?: string
  companyId?: string
  createdAt?: string
  id: string
}

let id = 1
const getId = () => {
  return `${id++}`
}

export default function TagComponent({ acaoList, setAcaoList }: PropsAcaoTag) {
  const [dropOptins, setDropOptions] = useState<Tag[]>()
  const [DepartamentOptions, setDepartamentOptions] = useState<Departament[]>()

  const tagFilter = useStore((state) => state.tagFilter)
  const Tags = useStore.getState().tags

  const departamentFilter = useStore((state) => state.departamentFilter)
  const departments = useStore.getState().departments

  useEffect(() => {
    let FilterTags = Tags
    let FilterDepartments = departments

    acaoList.forEach((acao) => {
      acao.tags?.forEach((tag) => {
        FilterTags = FilterTags?.filter((filter) => filter.id !== tag.id)
      })
      acao.departments?.forEach((dpt) => {
        FilterDepartments = FilterDepartments?.filter((filter) => filter.id !== dpt.id)
      })
    })

    setDropOptions(FilterTags)
    setDepartamentOptions(FilterDepartments)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const options = [
    { id: 'ADD_TAGS', label: 'Adicionar Etiqueta' },
    { id: 'REMOVE_TAGS', label: 'Remover Etiqueta' },
    { id: 'ADD_DEPARTMENTS', label: 'Transferir ao departamento' },
    { id: 'REMOVE_DEPARTMENTS', label: 'Remover do departamento' },
    { id: 'NOTIFICATION', label: 'Notificar usuários' },
    { id: 'UPDATE_FIELD', label: 'Atualizar campo personalizado' },
    { id: 'CLEAR_FIELD', label: 'Limpar valor de campo personalizado' },
    { id: 'MOVE_ATTENDANT', label: 'Atribuir conversa a um atendente' },
    { id: 'END_SERVICE', label: 'Finalizar atendimento' },
  ]

  const handleDeleteAcao = (id: string) => {
    const update = acaoList.filter((acao: IActionProps) => acao.id !== id)
    setAcaoList(update)
  }

  function handleSetAcaoType(
    value:
      | null
      | 'ADD_TAGS'
      | 'REMOVE_TAGS'
      | 'UPDATE_FIELD'
      | 'ADD_DEPARTMENTS'
      | 'REMOVE_DEPARTMENTS'
      | 'NOTIFICATION'
      | 'MOVE_ATTENDANT'
      | 'CLEAR_FIELD'
      | 'END_SERVICE',
    id: string
  ): void {
    const update = acaoList.map((acao: IActionProps) => {
      if (acao.id === id) {
        acao.actionType = value
      }
      return acao
    })

    setAcaoList(update)
  }

  function AddAcao() {
    if (acaoList === undefined) {
      setAcaoList([{ id: getId(), actionType: null }])
    } else {
      setAcaoList([...acaoList, { id: getId(), actionType: null }])
    }
  }

  function RemoveTag(tagId: string) {
    const array = acaoList.map((acao) => {
      return Object.assign({}, acao, {
        tags: acao.tags?.filter((tag) => tag.id !== tagId),
      })
    })
    const updateOptions = Tags?.find((tag) => tag.id === tagId)
    const options = dropOptins

    setAcaoList(array)
    if (options === undefined) return
    if (updateOptions === undefined) return
    if (options.some((option) => option.id === updateOptions.id)) return
    setDropOptions([...options, updateOptions])
  }

  function RemoverDepartament(departamentId: string) {
    const array = acaoList.map((acao) => {
      return Object.assign({}, acao, {
        departments: acao.departments?.filter((departments) => departments.id !== departamentId),
      })
    })
    const updateOptions = departments?.find((departments) => departments.id === departamentId)
    const options = DepartamentOptions

    setAcaoList(array)
    if (options === undefined) return
    if (updateOptions === undefined) return
    if (options.some((option) => option.id === updateOptions.id)) return
    setDepartamentOptions([...options, updateOptions])
  }

  function handleFilter(e: DropdownFilterEvent) {
    if (e.filter.length > 2)
      tagFilter(e.filter).then((res) => {
        const filter = res.data.content
        if (filter === null) return
        const teste = filter.flatMap((tag: Tag) => {
          if (dropOptins?.some((tagSome) => tagSome.id === tag.id)) {
            return []
          }
          return tag
        })
        const update = dropOptins
        if (update === undefined) return
        setDropOptions([...update, ...teste])
      })
  }

  function handleFilterDepartament(e: DropdownFilterEvent) {
    if (e.filter.length > 2)
      departamentFilter(e.filter).then((res) => {
        const filter = res.data.content
        if (filter === null) return
        const teste = filter.flatMap((depantament: Departament) => {
          if (DepartamentOptions?.some((departmentsome) => departmentsome.id === depantament.id)) {
            return []
          }
          return depantament
        })
        const update = DepartamentOptions
        if (update === undefined) return
        setDepartamentOptions([...update, ...teste])
      })
  }

  function setTags(event: DropdownChangeEvent, id: string) {
    const addTag = dropOptins?.find((tag) => tag.id === event.value)
    if (addTag === undefined) return
    const updateOptions = dropOptins?.filter((tag) => tag.id !== event.value)
    const update = acaoList.map((acao) => {
      if (acao.id === id) {
        if (acao.tags === undefined) {
          return { ...acao, tags: [addTag] }
        } else {
          return { ...acao, tags: [...acao.tags, addTag] }
        }
      }
      return acao
    })
    setAcaoList(update)
    setDropOptions(updateOptions)
  }

  function setdepartments(event: DropdownChangeEvent, id: string) {
    const addDepartament = DepartamentOptions?.find((departament) => departament.id === event.value)
    if (addDepartament === undefined) return

    const update = acaoList.map((acao) => {
      if (acao.id === id) {
        if (acao.departments === undefined || acao.departments?.length < 1) {
          const updateOptions = DepartamentOptions?.filter((departament) => departament.id !== event.value)
          setDepartamentOptions(updateOptions)
          return { ...acao, departments: [addDepartament] }
        }
      }
      return acao
    })
    setAcaoList(update)
  }

  const tagList = (acao: IActionProps) => {
    return (
      <div className="my-2">
        <div className="bg-white border-1 border-dashed border-round border-gray-400">
          <div className="p-2">
            <div className="w-full mt-1">
              <Dropdown
                options={dropOptins}
                emptyFilterMessage="Não foi possivel achar a Etiqueta"
                onChange={(e) => setTags(e, acao.id)}
                filter
                optionLabel="name"
                optionValue="id"
                className="w-full"
                placeholder={`Selecione a etiqueta`}
                onFilter={handleFilter}
              />
            </div>
            <div className="mt-2">
              <div className="p-2 flex flex-wrap">
                {acao.tags === undefined ? (
                  <div className="bg-gray-100 border-gray-200 border-round w-full flex justify-content-center text-sm text-gray-600">
                    <div className="p-2">Nenhuma etiqueta foi adcionada</div>
                  </div>
                ) : (
                  <>
                    {acao.tags.map((tag: Tag, index: number) => {
                      return (
                        <div key={`${tag.id}${index}`}>
                          <div className="bg-gray-200 border-round mx-1 mb-2 flex" style={{ height: '30px' }}>
                            <div className="py-1 px-2 flex align-items-center justify-content-center">
                              {tag.name}
                              <AiOutlineClose className="ml-1 cursor-pointer" onClick={() => RemoveTag(tag.id)} />
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const [users, setUsers] = useState<{ label: string; value: string }[]>([])

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const users = await getUsers()

        const mapper = users.map((user) => ({
          label: user.fullName,
          value: user.id,
          phone: user.phone,
          hasNumber: !!user.phone,
        }))

        setUsers(mapper)
      } catch (error) {
        console.log(error)
      }
    }

    fetchUsers()
  }, [])

  const NotificationList = (action: IActionProps) => {
    return (
      <div className="mt-3">
        <div className="flex flex-column mb-7 relative">
          <div className="mb-1 text-sm text-gray-600 font-bold">Mensagem</div>
          <EditorComponent
            text={action.notification?.text || ''}
            setText={(value) => {
              const update = acaoList.map((acao) => {
                if (acao.id === action.id) {
                  return {
                    ...acao,
                    notification: {
                      ...acao.notification,
                      text: value,
                    },
                  }
                }

                return acao
              })
              setAcaoList(update)
            }}
          />
        </div>

        <div className="flex flex-column mb-4 relative">
          <div className="mb-1 text-sm text-gray-600 font-bold">Notificar os usuários</div>
          <MultiSelect
            emptyFilterMessage="Nenhum usuário encontrado"
            options={users}
            value={action.notification?.specificUsers || []}
            display="chip"
            filter
            filterPlaceholder="Pesquise um nome de usuário"
            optionDisabled={(option) => {
              return !option.hasNumber
            }}
            itemTemplate={(e) => (
              <span>
                {e.label} - {e.phone ? ` - ${phoneMask(e.phone)}` : '(Usuário sem número)'}
              </span>
            )}
            onChange={(e) => {
              const { value } = e.target

              const update = acaoList.map((acao) => {
                if (acao.id === action.id) {
                  return {
                    ...acao,
                    notification: {
                      ...acao.notification,
                      specificUsers: value,
                    },
                  }
                }

                return acao
              })
              setAcaoList(update)
            }}
            placeholder="Selecione os usuários para notificar"
          />
        </div>
      </div>
    )
  }

  const DepartamentList = (acao: IActionProps) => {
    return (
      <div className="my-2">
        <div className="bg-white border-1 border-dashed border-round border-gray-400">
          <div className="p-2">
            <div className="w-full mt-1">
              <Dropdown
                options={DepartamentOptions}
                emptyFilterMessage="Não foi possivel achar o departamento"
                onChange={(e) => setdepartments(e, acao.id)}
                filter
                optionLabel="name"
                optionValue="id"
                className="w-full"
                placeholder={`Selecione o departamento`}
                onFilter={handleFilterDepartament}
              />
            </div>
            <div className="mt-2">
              <div className="p-2 flex flex-wrap">
                {acao.departments === undefined ? (
                  <div className="bg-gray-100 border-gray-200 border-round w-full flex justify-content-center text-sm text-gray-600">
                    <div className="p-2">Nenhum departamento foi adcionado</div>
                  </div>
                ) : (
                  <>
                    {acao.departments.map((departments: Departament, index: number) => {
                      return (
                        <div key={`${departments.id}${index}`}>
                          <div className="bg-gray-200 border-round mx-1 mb-2 flex" style={{ height: '30px' }}>
                            <div className="py-1 px-2 flex align-items-center justify-content-center">
                              {departments.name}
                              <AiOutlineClose
                                className="ml-1 cursor-pointer"
                                onClick={() => RemoverDepartament(departments.id)}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const { optionsFieldFormatted, onToggleModalCustomizeField } = useCustomizeFieldsStore((state) => ({
    optionsFieldFormatted: state.optionsFieldFormatted,
    onToggleModalCustomizeField: state.onToggleModalCustomizeField,
  }))

  const groupedItemTemplate = (option: any) => {
    return (
      <>
        {option.label === 'add-new-field' ? (
          <div
            className="flex align-items-center cursor-pointer font-normal"
            onClick={() => onToggleModalCustomizeField(true)}>
            <span className="text-blue-500">+ Adicionar novo campo</span>
          </div>
        ) : (
          <div className="flex align-items-center">
            <div>{formatUseIn(option.label)}</div>
          </div>
        )}
      </>
    )
  }

  const ClearCustomField = (action: IActionProps) => {
    return (
      <div className="my-2">
        <div className="flex flex-column mb-4 relative mt-4">
          <div className="mb-1 text-sm text-gray-600 font-bold">
            Selecione um campo personalizado para limpar o valor
          </div>
          <Dropdown
            onChange={(e) => {
              const { value } = e.target

              const update = acaoList.map((acao) => {
                if (acao.id === action.id) {
                  return {
                    ...acao,
                    customFields: {
                      ...acao.customFields,
                      field: value,
                    },
                  }
                }

                return acao
              })
              setAcaoList(update)
            }}
            placeholder="Campos"
            className="w-full"
            options={[
              {
                label: 'add-new-field',
                code: 'add-new-field',
                items: [],
              },
              ...optionsFieldFormatted({}),
            ]}
            itemTemplate={itemTemplate}
            optionLabel="value"
            optionValue="id"
            optionGroupLabel="label"
            optionGroupChildren="items"
            optionGroupTemplate={groupedItemTemplate}
            filter
            value={action.customFields?.field}
          />
        </div>
      </div>
    )
  }

  const UpdateCustomField = (action: IActionProps) => {
    const optionsFields = useCustomizeFieldsStore.getState().optionsField
    const customFieldType = optionsFields.find((item) => item.id === action.customFields?.field)?.type?.toUpperCase()

    return (
      <div className="my-2">
        <div className="flex flex-column mb-4 relative mt-4">
          <div className="mb-1 text-sm text-gray-600 font-bold">
            Selecione um campo personalizado para atualizar o valor
          </div>
          <Dropdown
            onChange={(e) => {
              const { value } = e.target

              const update = acaoList.map((acao) => {
                if (acao.id === action.id) {
                  return {
                    ...acao,
                    customFields: {
                      ...acao.customFields,
                      field: value,
                    },
                  }
                }

                return acao
              })
              setAcaoList(update)
            }}
            placeholder="Campos"
            className="w-full"
            options={[
              {
                label: 'add-new-field',
                code: 'add-new-field',
                items: [],
              },
              ...optionsFieldFormatted({}),
            ]}
            itemTemplate={itemTemplate}
            optionLabel="value"
            optionValue="id"
            optionGroupLabel="label"
            optionGroupChildren="items"
            optionGroupTemplate={groupedItemTemplate}
            filter
            value={action.customFields?.field}
          />
        </div>
        <div className="flex flex-column mb-4 relative mt-4">
          {customFieldType === 'NUMBER' && (
            <div>
              <div className="flex flex-column mb-4 relative">
                <div className="mb-1 text-sm text-gray-600 font-bold">Selecione o tipo de operação</div>
                <Dropdown
                  onChange={(e) => {
                    const { value } = e.target

                    const update = acaoList.map((acao) => {
                      if (acao.id === action.id) {
                        return {
                          ...acao,
                          customFields: {
                            ...acao.customFields,
                            operation: value,
                          },
                        }
                      }

                      return acao
                    })
                    setAcaoList(update)
                  }}
                  placeholder="Operação"
                  className="w-full"
                  options={[
                    {
                      label: 'Definir número',
                      value: 'define',
                    },
                    {
                      label: 'Somar ao número',
                      value: 'sum',
                    },
                    {
                      label: 'Subtrair do número',
                      value: 'subtract',
                    },
                  ]}
                  value={action.customFields?.operation}
                />
              </div>

              <div className="flex flex-column mb-4 relative mt-4">
                <div className="mb-1 text-sm text-gray-600 font-bold">Digite o valor</div>
                <InputText
                  type="number"
                  placeholder="Valor"
                  onChange={(e) => {
                    const { value } = e.target
                    const update = acaoList.map((acao) => {
                      if (acao.id === action.id) {
                        return {
                          ...acao,
                          customFields: {
                            ...acao.customFields,
                            value,
                          },
                        }
                      }

                      return acao
                    })
                    setAcaoList(update)
                  }}
                  value={action.customFields?.value || ''}
                />
              </div>
            </div>
          )}

          {customFieldType !== 'NUMBER' && (
            <>
              <div className="mb-1 text-sm text-gray-600 font-bold">Digite o novo valor</div>
              {customFieldType !== 'BOOLEAN' && (
                <InputText
                  onChange={(e) => {
                    const { value } = e.target
                    const update = acaoList.map((acao) => {
                      if (acao.id === action.id) {
                        const maskedValue = handleGetMaskByType(value, action.customFields?.field || '', optionsFields)

                        return {
                          ...acao,
                          customFields: {
                            ...acao.customFields,
                            value: maskedValue,
                          },
                        }
                      }

                      return acao
                    })
                    setAcaoList(update)
                  }}
                  value={action.customFields?.value || ''}
                />
              )}
            </>
          )}

          {customFieldType === 'BOOLEAN' && (
            <Dropdown
              optionLabel="name"
              optionValue="id"
              placeholder="Selecione um valor"
              value={action.customFields?.value}
              options={[
                {
                  name: 'Verdadeiro',
                  id: 'true',
                },
                {
                  name: 'Falso',
                  id: 'false',
                },
              ]}
              onChange={(e) => {
                const { value } = e.target

                const update = acaoList.map((acao) => {
                  if (acao.id === action.id) {
                    return {
                      ...acao,
                      customFields: {
                        ...acao.customFields,
                        value: value,
                      },
                    }
                  }

                  return acao
                })
                setAcaoList(update)
              }}
            />
          )}
        </div>
      </div>
    )
  }

  const MoveAttendant = (action: IActionProps) => {
    return (
      <div className="my-2">
        <div className="flex flex-column mb-4 relative mt-4">
          <div className="mb-1 text-sm text-gray-600 font-bold">Selecione para qual atendente deseja atribuir</div>
          <Dropdown
            options={users}
            value={action.attendant}
            itemTemplate={(e) => <span>{e.label}</span>}
            onChange={(e) => {
              const { value } = e.target

              const update = acaoList.map((acao) => {
                if (acao.id === action.id) {
                  return {
                    ...acao,
                    attendant: value,
                    attendantName: users.find((user) => user.value === value)?.label,
                  }
                }

                return acao
              })
              setAcaoList(update)
            }}
            placeholder="Selecione um atendente"
          />
        </div>
      </div>
    )
  }

  const acaoType = (acao: IActionProps) => {
    switch (acao.actionType) {
      case 'ADD_TAGS':
        return tagList(acao)
      case 'REMOVE_TAGS':
        return tagList(acao)
      case 'ADD_DEPARTMENTS':
        return DepartamentList(acao)
      case 'REMOVE_DEPARTMENTS':
        return DepartamentList(acao)
      case 'NOTIFICATION':
        return NotificationList(acao)
      case 'CLEAR_FIELD':
        return ClearCustomField(acao)
      case 'UPDATE_FIELD':
        return UpdateCustomField(acao)
      case 'MOVE_ATTENDANT':
        return MoveAttendant(acao)
    }
  }

  return (
    <>
      <div>
        <div className="font-bold mb-4">Executar Ações:</div>
        {!acaoList
          ? null
          : acaoList.map((acao: IActionProps, i: number) => {
              return (
                <div className="my-3" key={i}>
                  <div className="flex justify-content-between">
                    <span className="text-lg text-800">Ação #{i + 1}</span>
                    <span className="cursor-pointer" onClick={() => handleDeleteAcao(`${acao.id}`)}>
                      <AiOutlineDelete size={22} color="red" />
                    </span>
                  </div>
                  <div className="bg-gray-200 border-round flex flex-column mt-2 pb-2 border-gray-300 w-full">
                    <div className="py-2 px-3">
                      <label className="">Tipo de Ação</label>
                      <div className="w-full">
                        <Dropdown
                          options={options}
                          optionValue="id"
                          optionLabel="label"
                          className="w-full"
                          placeholder="Selecione o tipo"
                          value={acao.actionType}
                          onChange={(e) => handleSetAcaoType(e.value, acao.id)}
                        />
                      </div>
                      {acao.actionType !== null ? <>{acaoType(acao)}</> : null}
                    </div>
                  </div>
                </div>
              )
            })}
        <div className="flex align-items-center justify-content-center mt-2">
          <Button className="h-3rem border-rounded p-button-outlined" onClick={AddAcao} label="Adicionar ação" />
        </div>
      </div>
    </>
  )
}
