import { memo, useEffect, useRef, useState } from 'react'
import { Handle, NodeProps, Position, useUpdateNodeInternals } from 'reactflow'
import { OptionsHover } from '../../components/OptionsHover'
import { FaRandom } from 'react-icons/fa'
import { ModalRandomizer, OptionRandomizer } from './components/ModalRandomizer'
import useStore from '../../store/store'

const RandomizerNode = ({ id, data, isConnectable }: NodeProps) => {
  const divRef = useRef(null)

  const [hover, setHover] = useState('hidden')
  const [isOpenModal, setIsOpenModal] = useState(false)

  const [randomizer, setRandomizer] = useState<any>(data.randomizer)

  const { updatedEdges } = useStore((state) => ({
    updatedEdges: state.updatedEdges,
  }))

  const handleClick = () => {
    setIsOpenModal(true)
  }

  const updateNodeInternals = useUpdateNodeInternals()

  useEffect(() => {
    if (divRef.current) {
      updatedEdges(randomizer.map((item: OptionRandomizer) => item.optionId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [randomizer])

  useEffect(() => {
    updateNodeInternals(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, id, randomizer])

  return (
    <div onMouseEnter={() => setHover('flex')} onMouseLeave={() => setHover('hidden')}>
      {isOpenModal && (
        <ModalRandomizer
          isOpen={isOpenModal}
          onClose={() => setIsOpenModal(!isOpenModal)}
          id={id}
          data={data}
          setRandomizer={setRandomizer}
        />
      )}
      <OptionsHover id={id} className={hover} setModalOpen={setIsOpenModal} />
      <div
        onClick={handleClick}
        className="border-round-lg w-18rem"
        style={{ border: '1px solid #1FCEC3', backgroundColor: '#FFF' }}>
        <div className="flex pl-4 pr-3 py-3 relative border-bottom-1" style={{ borderColor: '#9E9E9E' }}>
          <div>
            <div className="w-2rem h-2rem flex justify-content-center align-items-center border-circle bg-yellow-400">
              <FaRandom size={20} color="white" />
            </div>
            <Handle
              type="target"
              position={Position.Left}
              id="a"
              style={{
                background: '#FFF',
                border: 'solid 1px #1FCEC3',
                height: '16px',
                width: '16px',
                left: '-9px',
              }}
              isConnectable={isConnectable}
            />
          </div>
          <div className="flex ml-3 flex-column align-items-start">
            <div>
              <input
                style={{ width: '115px' }}
                className="input-ButtonNode-styled border-none border-bottom-1 border-dashed font-bold h-1rem line-height-2 text-base vertical-align-middle"
                type="text"
                placeholder="Randomizador"
                name=""
                id=""
              />
            </div>
            <div className="font-normal text-sm text-yellow-400">Randomizador</div>
          </div>
        </div>
        <div>
          {data.randomizer?.length ? (
            <div className="my-4">
              {data.randomizer.map((value: OptionRandomizer) => (
                <div
                  key={value.optionId}
                  className="relative flex justify-content-between align-items-center my-2 surface-100 p-3 border-round-left-xl ml-4">
                  <div className="font-bold text-700">{value.random}%</div>
                  <div className="text-700">{value.name}</div>
                  <Handle
                    ref={divRef}
                    key={value.optionId}
                    type="source"
                    position={Position.Right}
                    id={value.optionId}
                    style={{
                      background: '#FFF',
                      border: 'solid 1px #1FCEC3',
                      height: '18px',
                      width: '18px',
                      right: '-10px',
                    }}
                    isConnectable={isConnectable}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div
              onClick={() => setIsOpenModal(true)}
              className="px-4 py-3 cursor-pointer justify-content-center align-items-center flex"
              style={{ color: '#4182F6' }}>
              Configurar etapa do fluxo
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

RandomizerNode.displayName = 'TextNode'

export default memo(RandomizerNode)
