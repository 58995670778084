import { memo, useEffect, useRef, useState } from 'react'
import { BsMenuButtonWide } from 'react-icons/bs'
import { Handle, NodeProps, Position, useUpdateNodeInternals } from 'reactflow'
import styled from 'styled-components'
import { convertStringFromDb, reConvertTimeUnit } from '../../utils/convertStrings'
import { OptionsHover } from '../../components/OptionsHover'
import { ModalButton } from './components/ModalButton'
import useStore from '../../store/store'

const TextDiv = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: '-webkit-box';
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  max-height: 100px;
`

const ButtonNode = ({ id, data, isConnectable }: NodeProps) => {
  const divRef = useRef(null)

  const [hover, setHover] = useState('hidden')
  const [modalopen, setModalOpen] = useState(false)
  const [counter, setCounter] = useState(0)

  const [buttons, setButtons] = useState<any>(data.buttons)

  const { iHaveEdge } = useStore((state) => ({
    iHaveEdge: state.iHaveEdge,
  }))

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (divRef.current) {
      if (counter > buttons.length) return
      if (buttons.length === counter) {
        iHaveEdge(buttons as any)
      } else {
        setCounter(counter + 1)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })

  const updateNodeInternals = useUpdateNodeInternals()

  useEffect(() => {
    updateNodeInternals(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, id, buttons])

  return (
    <div onMouseEnter={() => setHover('flex')} onMouseLeave={() => setHover('hidden')}>
      <OptionsHover id={id} className={hover} setModalOpen={setModalOpen} />

      {modalopen && (
        <ModalButton
          data={{
            ...data,
            buttons,
          }}
          id={id}
          isOpen={modalopen}
          onClose={() => setModalOpen(!modalopen)}
          setButtons={setButtons}
        />
      )}

      <div className="border-round-lg w-18rem" style={{ border: '1px solid #1FCEC3', backgroundColor: '#FFF' }}>
        <div className="flex pl-4 pr-3 py-3 relative border-bottom-1" style={{ borderColor: '#9E9E9E' }}>
          <div>
            <div className="w-2rem h-2rem flex justify-content-center align-items-center border-circle bg-bluegray-400">
              <BsMenuButtonWide color="white" />
            </div>
            <div>
              <Handle
                type="target"
                position={Position.Left}
                id="a"
                style={{
                  background: '#FFF',
                  border: 'solid 1px #1FCEC3',
                  height: '16px',
                  width: '16px',
                  left: '-9px',
                }}
                isConnectable={isConnectable}
              />
            </div>
          </div>
          <div className="flex ml-3 flex-column align-items-start">
            <div className="">
              <input
                className="input-ButtonNode-styled border-none w-9rem border-bottom-1 border-dashed font-bold h-1rem line-height-2 text-base vertical-align-middle"
                type="text"
                placeholder="Enviar mensagem"
                name=""
                id=""
              />
            </div>
            <div className="font-normal text-sm text-bluegray-400">Menu de opções</div>
          </div>
        </div>
        {buttons ? (
          <>
            <div
              onClick={() => setModalOpen(true)}
              className="flex justify-content-center py-2 cursor-pointer flex-column align-items-center">
              <TextDiv
                className="w-15rem p-2 border-round-lg border-1 text-left bg-blue-100 border-blue-200 mb-3"
                style={{
                  wordBreak: 'break-all',
                  overflow: 'hidden',
                }}>
                <div dangerouslySetInnerHTML={{ __html: convertStringFromDb(data.text) }} />
              </TextDiv>
              <div>
                {buttons.map((button: { label: string; id: string; position: string }, i: number) => {
                  return (
                    <div key={i} className="relative flex">
                      <div className="flex align-items-center text-gray-600 mr-2 font-bold">{i + 1}</div>
                      <TextDiv className="w-14rem p-2 border-round-lg border-1 text-left bg-gray-100 border-gray-300 my-1 flex align-items-center justify-content-center">
                        {button.label}
                      </TextDiv>
                      <Handle
                        ref={divRef}
                        key={`${button.id}`}
                        type="source"
                        position={Position.Right}
                        id={`${button.id}`}
                        style={{
                          background: '#FFF',
                          border: 'solid 1px #1FCEC3',
                          height: '18px',
                          width: '18px',
                          right: '-33px',
                        }}
                        isConnectable={isConnectable}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
            <div
              className="flex pb-3 pt-3 flex-column border-top-1 border-round-bottom-lg "
              style={{ borderColor: '#E5E7EB', backgroundColor: '#FBFBFC' }}>
              {data.waitingDuration ? (
                <div className="relative">
                  <div className="flex justify-content-end">
                    <span className="text-xs line-height-1 cursor-pointer mr-3">
                      Se não responder em{' '}
                      <b>
                        {data.waitingDuration} {reConvertTimeUnit(data.waitingDurationType)}
                      </b>
                    </span>
                  </div>
                  <Handle
                    type="source"
                    position={Position.Right}
                    id="a"
                    style={{
                      background: '#FFF',
                      border: 'solid 1px red',
                      height: '18px',
                      width: '18px',
                      right: '-9px',
                    }}
                    isConnectable={isConnectable}
                  />
                </div>
              ) : null}
            </div>
          </>
        ) : (
          <>
            <div
              onClick={() => setModalOpen(true)}
              className="px-4 py-3 cursor-pointer justify-content-center align-items-center flex text-sm"
              style={{ color: '#4182F6' }}>
              configurar etapa do fluxo
            </div>
            <Handle
              type="source"
              position={Position.Right}
              id="a"
              style={{
                background: '#FFF',
                border: 'solid 1px #1FCEC3',
                height: '18px',
                width: '18px',
                right: '-9px',
                visibility: 'hidden',
              }}
              isConnectable={isConnectable}
            />
          </>
        )}
      </div>
    </div>
  )
}

ButtonNode.displayName = 'ButtonNode'

export default memo(ButtonNode)
