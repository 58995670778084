import { ReactElement, memo, useEffect, useState } from 'react'
import { Handle, NodeProps, Position } from 'reactflow'
import { AiFillFile, AiFillFilePdf, AiFillFileText, AiOutlineFileText } from 'react-icons/ai'
import useStore from './../../store/store'
import styled from 'styled-components'
import { ProgressBar } from 'primereact/progressbar'
import { convertStringFromDb, convertStringToDb } from '../../utils/convertStrings'
import { OptionsHover } from '../../components/OptionsHover'
import ModalFile from '../../components/ModalFile'

const TextDiv = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: '-webkit-box';
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  max-height: 100px;
  margin-top: 0.5rem;
`

const DocNode = ({ id, data, isConnectable }: NodeProps) => {
  const [hover, setHover] = useState('hidden')
  const [title, setTitle] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [document, setDocument] = useState<string>()
  const [hiddenModal, setHiddenModal] = useState(false)
  const [fileType, setFileType] = useState<ReactElement>()
  const [mensagem, setMensagem] = useState<string>()
  const [file, setFile] = useState<File>()
  const [load, setLoad] = useState(false)
  const [count, setCount] = useState<number>(0)

  const upload = (event: any) => {
    const progress: number = Math.round((event.loaded * 100) / event.total)
    setCount(progress)
  }

  const uploadFile = useStore((state) => state.uploadFile)
  const updateNodeData = useStore((state) => state.updateNodeData)

  useEffect(() => {
    if (!hiddenModal) return
    if (document === undefined) return
    if (document === data.fileName && mensagem === convertStringFromDb(data.text || '')) return
    if (file) {
      if (document !== data.fileName) {
        const formData = new FormData()
        formData.append('file', file)
        if (mensagem === convertStringFromDb(data.text || '')) {
          setLoad(true)
          uploadFile(id, formData, upload).then(() => {
            setLoad(false)
            setCount(0)
          })
        } else {
          setLoad(true)
          uploadFile(id, formData, upload).then((res) => {
            const nodeData = {
              mediaUrl: res.data.data.mediaUrl,
              mimetype: res.data.data.mimetype,
              fileName: res.data.data.fileName,
              text: convertStringToDb(mensagem || ''),
            }
            updateNodeData(id, nodeData)
            setLoad(false)
            setCount(0)
          })
        }
      } else {
        const nodeData = {
          mediaUrl: data.mediaUrl,
          mimetype: data.mimetype,
          fileName: data.fileName,
          text: convertStringToDb(mensagem || ''),
        }

        updateNodeData(id, nodeData)
      }
    } else {
      const nodeData = {
        mediaUrl: data.mediaUrl,
        mimetype: data.mimetype,
        fileName: data.fileName,
        text: convertStringToDb(mensagem || ''),
      }

      updateNodeData(id, nodeData)
    }
    setHiddenModal(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hiddenModal])

  useEffect(() => {
    if (data === undefined) return
    if (data.fileName) {
      setDocument(data.fileName)
      switch (data.mimetype) {
        case 'text/plain':
          setFileType(<AiFillFileText color="white" size={35} />)
          break

        case 'application/pdf':
          setFileType(<AiFillFilePdf color="white" size={35} />)
          break

        default:
          setFileType(<AiFillFile color="white" size={35} />)
          break
      }
    }
    if (data.text) setMensagem(convertStringFromDb(data.text || ''))
  }, [data])

  useEffect(() => {
    const handler = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      event.returnValue = ''
    }

    if (load) {
      window.addEventListener('beforeunload', handler)

      return () => {
        window.removeEventListener('beforeunload', handler)
      }
    }
  }, [load])

  return (
    <div onMouseEnter={() => setHover('flex')} onMouseLeave={() => setHover('hidden')}>
      <OptionsHover id={id} className={hover} setModalOpen={setModalOpen} />
      <ModalFile
        type="document"
        hasFileUpload={true}
        fileType={fileType}
        fileFromNode={document}
        hasText={true}
        nodeId={id}
        textFromNode={mensagem}
        setShowMesage={setMensagem}
        setHidden={setHiddenModal}
        setTypeDocument={setFileType}
        visible={modalOpen}
        setShowFile={setDocument}
        setShowDoc={setFile}
        onHide={() => setModalOpen(false)}
        text="Documento"
        icon={<AiOutlineFileText color="white" />}
      />
      <div
        className="border-round-lg w-18rem"
        style={{ border: '1px solid #1FCEC3', backgroundColor: '#FFF' }}
        onClick={() => setModalOpen(true)}>
        <div className="flex pl-4 pr-3 py-3 relative border-bottom-1" style={{ borderColor: '#9E9E9E' }}>
          <div>
            <div className="w-2rem h-2rem flex justify-content-center align-items-center border-circle bg-purple-300">
              <AiOutlineFileText size={26} color="white" />
            </div>
            <div>
              <Handle
                type="target"
                position={Position.Left}
                id="a"
                style={{
                  background: '#FFF',
                  border: 'solid 1px #1FCEC3',
                  height: '16px',
                  width: '16px',
                  left: '-9px',
                }}
                isConnectable={isConnectable}
              />
            </div>
          </div>
          <div className="flex ml-3 flex-column align-items-start">
            <div className="">
              <input
                className="input-textNode-styled border-none w-9rem border-bottom-1 border-dashed font-bold h-1rem line-height-2 text-base vertical-align-middle"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Enviar documento"
                name=""
                id=""
              />
            </div>
            <div className="font-normal text-sm text-purple-300">Documento</div>
          </div>
        </div>
        {document ? (
          <>
            <div className="flex justify-content-center py-2 cursor-pointer">
              <div
                className="bg-purple-300 flex flex-column py-2 cursor-pointer w-15rem p-2 white-space-normal  border-round-lg border-1 text-left border-purple-300"
                onClick={() => setModalOpen(true)}>
                <div className="flex align-items-center">
                  {load ? (
                    <div className="flex flex-column align-items-center">
                      <label>Fazendo Upload</label>
                      <ProgressBar style={{ width: '220px' }} value={count}></ProgressBar>
                    </div>
                  ) : (
                    <>
                      <div>{fileType}</div>
                      <p>{document}</p>
                    </>
                  )}
                </div>

                <TextDiv
                  className=" text-left"
                  style={{
                    wordBreak: 'break-all',
                    overflow: 'hidden',
                  }}>
                  <div dangerouslySetInnerHTML={{ __html: mensagem || '' }} />
                </TextDiv>
              </div>
            </div>
            <div
              className="py-2 justify-content-end flex aling-items-center relative h-2rem border-top-1 border-round-bottom-lg "
              style={{ borderColor: '#E5E7EB', backgroundColor: '#FBFBFC' }}>
              <div className="flex justify-content-center align-items-center">
                <span className="text-xs line-height-1 cursor-pointer mr-3">Próximo passo</span>
              </div>
              <Handle
                type="source"
                position={Position.Right}
                id="a"
                style={{
                  background: '#FFF',
                  border: 'solid 1px #1FCEC3',
                  height: '18px',
                  width: '18px',
                  right: '-9px',
                }}
                isConnectable={isConnectable}
              />
            </div>
          </>
        ) : (
          <>
            <div onClick={() => setModalOpen(true)} className="text-blue-400 my-3 cursor-pointer">
              Configurar etapa do fluxo
            </div>
            <Handle
              type="source"
              position={Position.Right}
              id="a"
              style={{
                background: '#FFF',
                border: 'solid 1px #1FCEC3',
                height: '18px',
                width: '18px',
                right: '-9px',
                visibility: 'hidden',
              }}
              isConnectable={isConnectable}
            />
          </>
        )}
      </div>
    </div>
  )
}

DocNode.displayName = 'DocNode'

export default memo(DocNode)
