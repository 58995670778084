import { memo, useState } from 'react'
import {
  AiFillVideoCamera,
  AiOutlineApi,
  AiOutlineFileText,
  AiOutlineSound,
  AiOutlineThunderbolt,
} from 'react-icons/ai'
import { BsFillChatLeftTextFill, BsImage, BsMenuButtonWide } from 'react-icons/bs'
import { FaHourglassEnd, FaRandom, FaRegHandPointDown } from 'react-icons/fa'
import useStore from '../../store/store'

import { BiTrash } from 'react-icons/bi'
import { RiContactsBookFill, RiQuestionnaireLine } from 'react-icons/ri'
import { TbExchange } from 'react-icons/tb'
import { Handle, NodeProps, Position } from 'reactflow'
import styled from 'styled-components'
import { RxSwitch } from 'react-icons/rx'

const RoundIcon = styled.span`
  width: 1.5rem;
  height: 1.5rem;
`

const EdgeDrop = ({ id, isConnectable }: NodeProps) => {
  const [hover, setHover] = useState('hidden')
  const Update = useStore((state) => state.onEdgeDrop)
  const removeOnEdgeDrop = useStore((state) => state.removeOnEdgeDrop)

  const handleCreateNode = async (id: string, type: string) => {
    Update(id, type)
  }

  return (
    <div onMouseEnter={() => setHover('flex')} onMouseLeave={() => setHover('hidden')}>
      {id !== 'step' ? (
        <div className="relative w-full h-2rem mb-1">
          <div
            className={`${hover} absolute h-2rem bg-white w-5rem align-items-center justify-content-center cursor-pointer border-round`}
            style={{ left: '35%' }}
            onClick={() => removeOnEdgeDrop(id)}>
            <BiTrash color="red" size={25} />
          </div>
        </div>
      ) : null}

      <Handle
        type="target"
        position={Position.Left}
        id="a"
        style={{
          background: '#FFF',
          border: 'solid 1px #1FCEC3',
          height: '16px',
          width: '16px',
          left: '-9px',
          top: '15%',
        }}
        isConnectable={isConnectable}
      />
      <div className="border-1 border-round border-teal-400 bg-white flex flex-column align-items-start py-2 px-4">
        <div className="font-bold text-2xl text-800 flex align-items-center mt-3">
          <FaRegHandPointDown className="mr-2" fill="#ffbc00" />
          <span>Qual o próximo passo</span>
        </div>
        <div className="mt-2 flex flex-column align-items-start mb-4 text-600 mt-4">
          <div className="flex align-items-center cursor-pointer" onClick={() => handleCreateNode(id, 'TEXT_MESSAGE')}>
            <RoundIcon className="bg-teal-400 border-circle flex align-items-center align-content-center justify-content-center">
              <BsFillChatLeftTextFill color="white" size={14} />
            </RoundIcon>
            <span className="ml-1">Texto</span>
          </div>
          <div
            className="flex align-items-center cursor-pointer mt-3"
            onClick={() => handleCreateNode(id, 'USER_INPUT')}>
            <RoundIcon className="bg-teal-400 border-circle flex align-items-center align-content-center justify-content-center">
              <RiQuestionnaireLine color="white" size={14} />
            </RoundIcon>
            <span className="ml-1">Pergunta</span>
          </div>
          <div
            className="flex align-items-center cursor-pointer mt-3"
            onClick={() => handleCreateNode(id, 'CONDITION')}>
            <RoundIcon className="bg-teal-400 border-circle flex align-items-center align-content-center justify-content-center">
              <RxSwitch color="white" size={14} />
            </RoundIcon>
            <span className="ml-1">Condição</span>
          </div>
          <div className="flex align-items-center mt-3 cursor-pointer" onClick={() => handleCreateNode(id, 'BUTTONS')}>
            <RoundIcon className="bg-bluegray-400 border-circle flex align-items-center align-content-center justify-content-center">
              <BsMenuButtonWide color="white" size={14} />
            </RoundIcon>
            <span className="ml-1">Menu de opções</span>
          </div>
          <div className="flex align-items-center mt-3 cursor-pointer" onClick={() => handleCreateNode(id, 'VIDEO')}>
            <RoundIcon className="bg-purple-400 border-circle flex align-items-center align-content-center justify-content-center">
              <AiFillVideoCamera color="white" size={14} />
            </RoundIcon>
            <span className="ml-1">Video</span>
          </div>
          <div className="flex align-items-center mt-3 cursor-pointer" onClick={() => handleCreateNode(id, 'IMAGE')}>
            <RoundIcon className="bg-purple-400 border-circle flex align-items-center align-content-center justify-content-center">
              <BsImage color="white" size={14} />
            </RoundIcon>
            <span className="ml-1">Imagem</span>
          </div>
          <div className="flex align-items-center mt-3 cursor-pointer" onClick={() => handleCreateNode(id, 'AUDIO')}>
            <RoundIcon className="bg-purple-400 border-circle flex align-items-center align-content-center justify-content-center">
              <AiOutlineSound color="white" size={14} />
            </RoundIcon>
            <span className="ml-1">Áudio</span>
          </div>
          <div className="flex align-items-center mt-3 cursor-pointer" onClick={() => handleCreateNode(id, 'FILE')}>
            <RoundIcon className="bg-purple-400 border-circle flex align-items-center align-content-center justify-content-center">
              <AiOutlineFileText color="white" size={14} />
            </RoundIcon>
            <span className="ml-1">Documento</span>
          </div>
          <div className="flex align-items-center mt-3 cursor-pointer" onClick={() => handleCreateNode(id, 'ACTION')}>
            <RoundIcon className="bg-yellow-400 border-circle flex align-items-center align-content-center justify-content-center">
              <AiOutlineThunderbolt color="white" size={14} />
            </RoundIcon>
            <span className="ml-1">Ação</span>
          </div>
          <div
            className="flex align-items-center mt-3 cursor-pointer"
            onClick={() => handleCreateNode(id, 'MOVE_FLOW')}>
            <RoundIcon className="bg-yellow-400 border-circle flex align-items-center align-content-center justify-content-center">
              <TbExchange color="white" size={14} />
            </RoundIcon>
            <span className="ml-1">Mover Fluxo</span>
          </div>
          <div className="flex align-items-center mt-3 cursor-pointer" onClick={() => handleCreateNode(id, 'DELAY')}>
            <RoundIcon className="bg-yellow-400 border-circle flex align-items-center align-content-center justify-content-center">
              <FaHourglassEnd color="white" size={14} />
            </RoundIcon>
            <span className="ml-1">Atraso inteligente</span>
          </div>
          <div
            className="flex align-items-center mt-3 cursor-pointer"
            onClick={() => handleCreateNode(id, 'INTEGRATION')}>
            <RoundIcon className="bg-yellow-400 border-circle flex align-items-center align-content-center justify-content-center">
              <AiOutlineApi color="white" size={14} />
            </RoundIcon>
            <span className="ml-1">Integração</span>
          </div>
          <div
            className="flex align-items-center mt-3 cursor-pointer"
            onClick={() => handleCreateNode(id, 'SEND_CONTACTS')}>
            <RoundIcon className="bg-yellow-400 border-circle flex align-items-center align-content-center justify-content-center">
              <RiContactsBookFill color="white" size={14} />
            </RoundIcon>
            <span className="ml-1">Enviar contato(s)</span>
          </div>
          <div
            className="flex align-items-center mt-3 cursor-pointer"
            onClick={() => handleCreateNode(id, 'RANDOMIZER')}>
            <RoundIcon className="bg-yellow-400 border-circle flex align-items-center align-content-center justify-content-center">
              <FaRandom color="white" size={14} />
            </RoundIcon>
            <span className="ml-1">Randomizador</span>
          </div>
        </div>
      </div>
    </div>
  )
}

EdgeDrop.displayName = 'EdgeDrop'

export default memo(EdgeDrop)
