import { axiosInstance } from '../Https/axios-instance'
import { CreateReturnTag, GetReturnTag } from './../types/tagType'

class TagServices {
  async get(flowId: string) {
    return await axiosInstance.get<GetReturnTag>(`/tags`, { headers: { flowid: flowId } })
  }

  async create(flowId: string, name: string) {
    return await axiosInstance.post<CreateReturnTag>(`/tags`, { name }, { headers: { flowid: flowId } })
  }

  async put(flowId: string, tagId: string, name: string) {
    return await axiosInstance.put<CreateReturnTag>(`/tags/${tagId}`, { name }, { headers: { flowid: flowId } })
  }

  async delete(flowId: string, tagId: string) {
    return await axiosInstance.delete(`/tags/${tagId}`, { headers: { flowid: flowId } })
  }

  async filter(flowId: string, query: string) {
    return await axiosInstance.get(`/tags`, { params: { query }, headers: { flowid: flowId } })
  }
}

export const tagService = new TagServices()
