import { memo, useEffect, useState } from 'react'
import { Handle, NodeProps, Position, useUpdateNodeInternals } from 'reactflow'
import { ModalSendContacts } from './components/ModalSendContacts'
import { OptionsHover } from '../../components/OptionsHover'
import { RiContactsBookFill } from 'react-icons/ri'

const SendContactsNode = ({ id, data, isConnectable }: NodeProps) => {
  const [hover, setHover] = useState('hidden')
  const [isOpenModal, setIsOpenModal] = useState(false)

  const updateNodeInternals = useUpdateNodeInternals()

  useEffect(() => {
    updateNodeInternals(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <div onMouseEnter={() => setHover('flex')} onMouseLeave={() => setHover('hidden')}>
      {isOpenModal && (
        <ModalSendContacts isOpen={isOpenModal} onClose={() => setIsOpenModal(!isOpenModal)} id={id} data={data} />
      )}
      <OptionsHover id={id} className={hover} setModalOpen={setIsOpenModal} />

      <div
        onClick={() => setIsOpenModal(true)}
        className="border-round-lg w-18rem border-1"
        style={{
          border: '1px solid #1FCEC3',
          backgroundColor: '#FFF',
        }}>
        <div className="flex pl-4 pr-3 py-3 relative border-bottom-1" style={{ borderColor: '#9E9E9E' }}>
          <div>
            <div className="w-2rem h-2rem flex justify-content-center align-items-center border-circle bg-yellow-400">
              <RiContactsBookFill size={20} color="white" />
            </div>
            <Handle
              type="target"
              position={Position.Left}
              id="a"
              style={{
                background: '#FFF',
                border: 'solid 1px #1FCEC3',
                height: '16px',
                width: '16px',
                left: '-9px',
              }}
              isConnectable={isConnectable}
            />
          </div>
          <div className="flex ml-3 flex-column align-items-start">
            <div>
              <input
                className="input-ButtonNode-styled border-none w-9rem border-bottom-1 border-dashed font-bold h-1rem line-height-2 text-base vertical-align-middle"
                type="text"
                placeholder="Enviar contato(s)"
                name=""
                id=""
              />
            </div>
            <div className="font-normal text-sm text-yellow-400">Enviar contato(s)</div>
          </div>
        </div>

        {data?.sendContacts?.length ? (
          <div>
            <div className="pt-2 pl-4 pr-4">
              {data?.sendContacts.map(
                (
                  contact: {
                    name: string
                    phone: {
                      ddi: string
                      countryCode: string
                      number: string
                    }
                  },
                  index: number
                ) => (
                  <div
                    key={index}
                    className="mt-1 mb-2"
                    style={{
                      padding: '14px',
                      backgroundColor: '#E8FAF9',
                      border: 'solid 1px #1FCEC3',
                      borderRadius: 8,
                    }}>
                    <div className="font-bold text-lg mb-2">Contato {index + 1}</div>
                    <div className="flex flex-column align-items-start gap-1">
                      <div>
                        <span className="font-bold">Nome: </span>
                        <span>{contact.name}</span>
                      </div>
                      <div>
                        <span className="font-bold">Número: </span>
                        <span>{contact.phone.number}</span>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
            <div
              className="py-4 justify-content-end flex aling-items-center relative h-2rem border-top-1 border-round-bottom-lg"
              style={{ borderColor: '#E5E7EB', backgroundColor: '#FBFBFC' }}>
              <div className="flex justify-content-center align-items-center">
                <span className="text-xs line-height-1 cursor-pointer mr-3">Próximo passo</span>
              </div>
              <Handle
                type="source"
                position={Position.Right}
                id="a"
                style={{
                  background: '#FFF',
                  border: 'solid 1px #1FCEC3',
                  height: '18px',
                  width: '18px',
                  right: '-9px',
                }}
                isConnectable={isConnectable}
              />
            </div>
          </div>
        ) : (
          <>
            <div
              onClick={() => setIsOpenModal(true)}
              className="px-4 py-3 cursor-pointer justify-content-center align-items-center flex"
              style={{ color: '#4182F6' }}>
              Configurar etapa do fluxo
            </div>
            <Handle
              type="source"
              position={Position.Right}
              id="a"
              style={{
                background: '#FFF',
                border: 'solid 1px #1FCEC3',
                height: '18px',
                width: '18px',
                right: '-9px',
                visibility: 'hidden',
              }}
              isConnectable={isConnectable}
            />
          </>
        )}
      </div>
    </div>
  )
}

SendContactsNode.displayName = 'SendContactsNode'

export default memo(SendContactsNode)
