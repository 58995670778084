import { memo, useEffect, useState } from 'react'
import { AiOutlineApi } from 'react-icons/ai'
import { Handle, NodeProps, Position, useUpdateNodeInternals } from 'reactflow'
import ModalIntegration from './components/ModalIntegration'
import { OptionsHover } from '../../components/OptionsHover'

const IntegrationNode = ({ id, data, isConnectable }: NodeProps) => {
  const [hover, setHover] = useState('hidden')
  const [isOpenModal, setIsOpenModal] = useState(false)

  const updateNodeInternals = useUpdateNodeInternals()

  useEffect(() => {
    updateNodeInternals(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <div onMouseEnter={() => setHover('flex')} onMouseLeave={() => setHover('hidden')}>
      {isOpenModal && (
        <ModalIntegration isOpen={isOpenModal} onClose={() => setIsOpenModal(!isOpenModal)} id={id} data={data} />
      )}
      <OptionsHover id={id} className={hover} setModalOpen={setIsOpenModal} />

      <div
        onClick={() => setIsOpenModal(true)}
        className="border-round-lg w-18rem border-1"
        style={{
          border: '1px solid #1FCEC3',
          backgroundColor: '#FFF',
        }}>
        <div className="flex pl-4 pr-3 py-3 relative border-bottom-1" style={{ borderColor: '#9E9E9E' }}>
          <div>
            <div className="w-2rem h-2rem flex justify-content-center align-items-center border-circle bg-yellow-400">
              <AiOutlineApi size={20} color="white" />
            </div>
            <Handle
              type="target"
              position={Position.Left}
              id="a"
              style={{
                background: '#FFF',
                border: 'solid 1px #1FCEC3',
                height: '16px',
                width: '16px',
                left: '-9px',
              }}
              isConnectable={isConnectable}
            />
          </div>
          <div className="flex ml-3 flex-column align-items-start">
            <div className="">
              <input
                className="input-ButtonNode-styled border-none w-6rem border-bottom-1 border-dashed font-bold h-1rem line-height-2 text-base vertical-align-middle"
                type="text"
                placeholder="Integração"
                name=""
                id=""
              />
            </div>
            <div className="font-normal text-sm text-yellow-400">Integração</div>
          </div>
        </div>

        {data.method ? (
          <div>
            <div className="pt-2 pb-2 pl-3 overflow-hidden text-overflow-ellipsis" title={data.url}>
              {data.method} - {data.url}
            </div>
            <div
              className="py-4 justify-content-end flex aling-items-center relative h-2rem border-top-1 border-round-bottom-lg"
              style={{ borderColor: '#E5E7EB', backgroundColor: '#FBFBFC' }}>
              <div className="flex justify-content-center align-items-center">
                <span className="text-xs line-height-1 cursor-pointer mr-3">Próximo passo</span>
              </div>
              <Handle
                type="source"
                position={Position.Right}
                id="a"
                style={{
                  background: '#FFF',
                  border: 'solid 1px #1FCEC3',
                  height: '18px',
                  width: '18px',
                  right: '-9px',
                }}
                isConnectable={isConnectable}
              />
            </div>
          </div>
        ) : (
          <>
            <div
              onClick={() => setIsOpenModal(true)}
              className="px-4 py-3 cursor-pointer justify-content-center align-items-center flex"
              style={{ color: '#4182F6' }}>
              Configurar etapa do fluxo
            </div>
            <Handle
              type="source"
              position={Position.Right}
              id="a"
              style={{
                background: '#FFF',
                border: 'solid 1px #1FCEC3',
                height: '18px',
                width: '18px',
                right: '-9px',
                visibility: 'hidden',
              }}
              isConnectable={isConnectable}
            />
          </>
        )}
      </div>
    </div>
  )
}

IntegrationNode.displayName = 'IntegrationNode'

export default memo(IntegrationNode)
