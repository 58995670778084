import { memo } from 'react'
import { AiFillVideoCamera, AiOutlineFileText, AiOutlineSound, AiOutlineThunderbolt } from 'react-icons/ai'
import { BsFillChatLeftTextFill, BsImage, BsMenuButtonWide } from 'react-icons/bs'
import { FaHourglassEnd, FaRegHandPointDown } from 'react-icons/fa'

import { RiQuestionnaireLine } from 'react-icons/ri'
import { TbExchange } from 'react-icons/tb'
import styled from 'styled-components'
import useStore from '../../store/store'

const RoundIcon = styled.span`
  width: 1.5rem;
  height: 1.5rem;
`

const StartBlock = ({ id }: any) => {
  const Update = useStore((state) => state.updateNodeType)

  return (
    <>
      <div className="border-2 border-dashed border-round border-400 bg-white flex flex-column align-items-start py-2 px-4">
        <div className="font-bold text-2xl text-800 flex align-items-center mt-3">
          <FaRegHandPointDown className="mr-2" fill="#ffbc00" />
          <span>Qual o primeiro passo?</span>
        </div>
        <span className="font-medium text-600 mt-4 text-base">Mensagens</span>
        <div className="mt-2 flex flex-column align-items-start mb-4 text-600 mt-4">
          <div className="flex align-items-center cursor-pointer" onClick={() => Update(id, 'TEXT_MESSAGE')}>
            <RoundIcon className="bg-teal-400 border-circle flex align-items-center align-content-center justify-content-center">
              <BsFillChatLeftTextFill color="white" size={14} />
            </RoundIcon>
            <span className="ml-1">Texto</span>
          </div>
          <div className="flex align-items-center cursor-pointer mt-3" onClick={() => Update(id, 'USER_INPUT')}>
            <RoundIcon className="bg-teal-400 border-circle flex align-items-center align-content-center justify-content-center">
              <RiQuestionnaireLine color="white" size={14} />
            </RoundIcon>
            <span className="ml-1">Pergunta</span>
          </div>
          <div className="flex align-items-center mt-3 cursor-pointer" onClick={() => Update(id, 'VIDEO')}>
            <RoundIcon className="bg-purple-400 border-circle flex align-items-center align-content-center justify-content-center">
              <AiFillVideoCamera color="white" size={14} />
            </RoundIcon>
            <span className="ml-1">Video</span>
          </div>
          <div className="flex align-items-center mt-3 cursor-pointer" onClick={() => Update(id, 'IMAGE')}>
            <RoundIcon className="bg-purple-400 border-circle flex align-items-center align-content-center justify-content-center">
              <BsImage color="white" size={14} />
            </RoundIcon>
            <span className="ml-1">Imagem</span>
          </div>
          {/* <div className="flex align-items-center mt-3 cursor-pointer" onClick={()=>Update(id,'StickNode')}>
                        <RoundIcon className="bg-purple-400 border-circle flex align-items-center align-content-center justify-content-center">
                            <BsStickies color="white" size={14} />
                        </RoundIcon>
                        <span className="ml-1">
                            Sticker
                        </span>
                    </div> */}
          <div className="flex align-items-center mt-3 cursor-pointer" onClick={() => Update(id, 'AUDIO')}>
            <RoundIcon className="bg-purple-400 border-circle flex align-items-center align-content-center justify-content-center">
              <AiOutlineSound color="white" size={14} />
            </RoundIcon>
            <span className="ml-1">Áudio</span>
          </div>
          <div className="flex align-items-center mt-3 cursor-pointer" onClick={() => Update(id, 'FILE')}>
            <RoundIcon className="bg-purple-400 border-circle flex align-items-center align-content-center justify-content-center">
              <AiOutlineFileText color="white" size={14} />
            </RoundIcon>
            <span className="ml-1">Documento</span>
          </div>
          <div className="flex align-items-center mt-3 cursor-pointer" onClick={() => Update(id, 'BUTTONS')}>
            <RoundIcon className="bg-bluegray-400 border-circle flex align-items-center align-content-center justify-content-center">
              <BsMenuButtonWide color="white" size={14} />
            </RoundIcon>
            <span className="ml-1">Menu de opções</span>
          </div>
          <div className="flex align-items-center mt-3 cursor-pointer" onClick={() => Update(id, 'ACTION')}>
            <RoundIcon className="bg-yellow-400 border-circle flex align-items-center align-content-center justify-content-center">
              <AiOutlineThunderbolt color="white" size={14} />
            </RoundIcon>
            <span className="ml-1">Ação</span>
          </div>
          <div className="flex align-items-center mt-3 cursor-pointer" onClick={() => Update(id, 'MOVE_FLOW')}>
            <RoundIcon className="bg-yellow-400 border-circle flex align-items-center align-content-center justify-content-center">
              <TbExchange color="white" size={14} />
            </RoundIcon>
            <span className="ml-1">Mover Fluxo</span>
          </div>
          <div className="flex align-items-center mt-3 cursor-pointer" onClick={() => Update(id, 'DELAY')}>
            <RoundIcon className="bg-yellow-400 border-circle flex align-items-center align-content-center justify-content-center">
              <FaHourglassEnd color="white" size={14} />
            </RoundIcon>
            <span className="ml-1">Atraso inteligente</span>
          </div>
        </div>
      </div>
    </>
  )
}

StartBlock.displayName = 'StartBlock'

export default memo(StartBlock)
