import { Dialog } from 'primereact/dialog'
import styles from './styles.module.css'
import { FaRandom, FaRegTrashAlt } from 'react-icons/fa'
import { Divider } from 'primereact/divider'
import { Button } from 'primereact/button'
import { useEffect, useRef, useState } from 'react'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import { v4 } from 'uuid'
import useStore from '../../../../store/store'

export interface OptionRandomizer {
  name: string
  random: number
  optionId: string
  totalPerson: number
}

export function ModalRandomizer({
  isOpen,
  onClose,
  id,
  data,
  setRandomizer,
}: {
  isOpen: boolean
  onClose: () => void
  id: string
  data: any
  setRandomizer: (randomizer: OptionRandomizer[]) => void
}) {
  const toast = useRef<Toast>(null)

  const { deleteNoNescessariEdge } = useStore((state) => ({
    deleteNoNescessariEdge: state.deleteNoNescessariEdge,
  }))

  const [values, setValues] = useState<OptionRandomizer[]>([
    {
      name: 'Opção 1',
      random: 50,
      optionId: v4(),
      totalPerson: 0,
    },
    {
      name: 'Opção 2',
      random: 50,
      optionId: v4(),
      totalPerson: 0,
    },
  ])

  const updateNodeData = useStore((state) => state.updateNodeData)

  const addNewOption = () => {
    const newOption: OptionRandomizer = {
      name: `Opção ${values.length + 1}`,
      random: 0,
      optionId: v4(),
      totalPerson: 0,
    }

    if (values.length === 10) {
      return toast?.current?.show({
        severity: 'error',
        summary: 'Ops! Número máximo de opções.',
        detail: 'Você só pode configurar até 10 opções.',
        life: 3000,
      })
    }

    setValues((prevState) => [...prevState, newOption])
  }

  const handleInputChange = (index: number, newValue: number) => {
    if (newValue >= 0 && newValue <= 100) {
      const updatedValues = [...values]
      updatedValues[index].random = newValue
      setValues(updatedValues)
    }
  }

  const removeOption = async (index: number, optionId: string) => {
    const updatedValues = [...values]
    updatedValues.splice(index, 1)
    setValues(updatedValues)

    await deleteNoNescessariEdge(id, optionId)
  }

  const checkButtonIsDisabled = () => {
    if (values.length < 2) return true

    const totalRandom = values.reduce((acc, option) => acc + option.random, 0)
    if (totalRandom !== 100) return true

    return false
  }

  const handleSubmit = async () => {
    try {
      const totalRandom = values.reduce((acc, option) => acc + option.random, 0)
      if (totalRandom !== 100) {
        toast?.current?.show({
          severity: 'error',
          summary: 'Ops! Valor incorreto.',
          detail: 'A soma das porcentagens não dá 100%.',
          life: 3000,
        })
      }

      await updateNodeData(id, {
        randomizer: values,
      })

      setRandomizer(values)

      onClose()
    } catch (error) {
      toast?.current?.show({
        severity: 'error',
        summary: 'Ops! Algo deu errado.',
        detail: 'Verifique as informações e tente novamente.',
        life: 3000,
      })
    }
  }

  const header = () => {
    return (
      <>
        <div className="flex align-items-center">
          <div className={`w-2rem h-2rem flex justify-content-center align-items-center border-circle bg-yellow-400 `}>
            <FaRandom size={20} color="white" />
          </div>
          <span className="text-600 text-base ml-2">Randomizador</span>
        </div>
        <Divider />
      </>
    )
  }

  useEffect(() => {
    if (data?.randomizer?.length) {
      setValues(data.randomizer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Dialog
      className={styles.mypanel}
      header={header}
      onHide={onClose}
      visible={isOpen}
      style={{ width: '500px' }}
      draggable={false}>
      <Toast ref={toast} />

      <div>Adicione até 10 opções, e digite a porcentagem de cada uma.</div>

      <div className="mt-4">
        {values.map((value, index) => (
          <div
            key={value.optionId}
            className="flex justify-content-between align-items-center my-2 surface-100 p-3 border-round-xl">
            <div>{value.name}</div>
            <div className="flex justify-content-end">
              <InputText
                className="w-5"
                value={String(value.random)}
                placeholder="Digite a porcentagem"
                onChange={(e) => handleInputChange(index, +e.target.value || 0)} // Chama a função handleInputChange
              />
              <div
                className="ml-2 gap-2 flex align-items-center cursor-pointer"
                onClick={() => removeOption(index, value.optionId)}>
                <FaRegTrashAlt size={18} color={'red'} />
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="text-blue-500 mt-4 cursor-pointer" onClick={addNewOption}>
        + Adicionar nova opção
      </div>

      <Button
        label="Salvar"
        className="p-button-help w-full mt-4"
        onClick={handleSubmit}
        disabled={checkButtonIsDisabled()}
      />
    </Dialog>
  )
}
