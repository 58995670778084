import { ICreateFieldProps, IGetFieldsResponseProps } from '../../@types/services/fields-integration'

import * as integration from './integration'

const selectedModule = integration

export const getFields: IGetFields = selectedModule.getFields
export const createField: ICreateField = selectedModule.createField

export type IGetFields = () => Promise<IGetFieldsResponseProps>

export type ICreateField = (params: ICreateFieldProps) => Promise<{ status: number }>
