import 'primeicons/primeicons.css'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { Dialog } from 'primereact/dialog'
import { Divider } from 'primereact/divider'
import { Dropdown } from 'primereact/dropdown'
import { Image } from 'primereact/image'
import { InputNumber } from 'primereact/inputnumber'
import { InputText } from 'primereact/inputtext'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { AiFillFile, AiFillFilePdf, AiFillFileText, AiOutlineCloudUpload, AiOutlineDelete } from 'react-icons/ai'
import styled from 'styled-components'
import { useCustomizeFieldsStore } from '../../store/customizeFields'
import { itemTemplate } from '../../utils/templates'
import useStore from './../../store/store'
import styles from './styles.module.css'
import LabelText from '../LabelText'
import TagComponent, { IActionProps } from '../Tag'
import EditorComponent from '../QuillComponent'
import { formatUseIn } from '../../utils/format'
import { Toast } from 'primereact/toast'

interface Tag {
  name: string
  color?: string
  companyId?: string
  createdAt?: string
  id: string
}

interface ModalFileProps {
  hasFileUpload?: boolean
  hasText?: boolean
  hasDropdown?: boolean
  hasButton?: boolean
  hasDuracao?: boolean
  hasTimeToAnswer?: boolean
  hasChageFluxo?: boolean
  hasAcao?: boolean
  hasRespostaCampo?: boolean
  icon: ReactElement
  text: string
  nodeId?: string
  tags?: Tag[]

  visible: boolean
  accept?: string
  fileFromNode?: string
  textFromNode?: string
  dropdownFromNode?: string
  acaoFromNode?: any
  buttonsFromNode?: { id: string; label: string }[]
  timeFromNode?: number
  timeAnswerFromNode?: boolean
  dropDownNumberFromNode?: string
  dropDownFluxoFromNode?: string
  salvarCampoFromNode?: string
  changeFluxoOptions?: any

  setInputNumber?: (value: number | null | undefined) => void
  setAnswer?: (value: boolean) => void
  setTypeDocument?: (type: ReactElement) => void
  setOptionSelect?: (option: number) => void
  setNumberOption?: (option: string) => void
  setShowFile?: (file: string) => void
  setShowMesage?: (text: string) => void
  setShowDropdown?: (dropdown: string) => void
  setShowDoc?: (file: File | undefined) => void
  setButtons?: (any: any) => void
  setAcao?: (any: any) => void
  onHide: (close?: boolean) => void
  setDropDownFluxo?: (value: string) => void
  setSalvarCampo?: (value: string) => void
  setHidden?: (hidden: boolean) => void
  setDeleteButtons?: (buttons: any) => void
  fileType?: ReactElement
  type?: 'image' | 'video' | 'audio' | 'document'
  color?: string
}

const Upload = styled.div`
  width: 100%;
  height: 9rem;
  margin-bottom: 0.5rem;
  border: 2px dashed #9ca3af;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  &:hover {
    background-color: #d1d5db;
  }
`

const DocumentDiv = styled.div`
  word-break: break-all;
  -webkit-line-clamp: 4;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
`

export default function ModalFile(props: ModalFileProps) {
  const toast = useRef<Toast>(null)

  const { onToggleModalCustomizeField, optionsFieldFormatted } = useCustomizeFieldsStore((state) => ({
    onToggleModalCustomizeField: state.onToggleModalCustomizeField,
    optionsFieldFormatted: state.optionsFieldFormatted,
  }))

  const [mensagem, setMensagem] = useState('')
  const [fileShow, setFileShow] = useState('')
  const [hasUpload, setHasUpload] = useState(false)
  const [dropdownSelector, setDropdownSelector] = useState('Não envia')
  const [fileType, setFileType] = useState<ReactElement>()
  const [file, setFile] = useState<File | undefined>(undefined)
  const [dropdownNumberSelector, setDropdownNumberSelector] = useState('')
  const [numberInput, setNumberInput] = useState<number | null>()
  const [inputList, setInputList] = useState<any>(null)
  const [checkBoxChange, setCheckBoxChange] = useState(false)
  const inputFile = useRef<HTMLInputElement>(null)
  const [dropdownFluxo, setDropdownFluxo] = useState('')
  const [acaoList, setAcaoList] = useState<IActionProps[]>([{ id: '0', actionType: null }])
  const [salvarCampo, setSalvarCampo] = useState<string>('')
  const timeLabel = useRef<any>()
  const dropDownLabel = useRef<any>()
  const uploadLabel = useRef<any>()
  const timeLabel2 = useRef<any>()
  const dropDownLabel2 = useRef<any>()
  const [deleteButtons, setDeleteButtons] = useState<any>()

  const deleteMedia = useStore((state) => state.deleteMedia)

  useEffect(() => {
    setSalvarCampo(props.salvarCampoFromNode || '')
    if (props.fileFromNode) setFileShow(props.fileFromNode)
    if (props.textFromNode) setMensagem(props.textFromNode)
    if (props.dropdownFromNode) setDropdownSelector(optionSelectReturn(props.dropdownFromNode))
    if (props.fileType) setFileType(props.fileType)
    if (props.buttonsFromNode) setInputList(props.buttonsFromNode)
    if (props.dropDownNumberFromNode) setDropdownNumberSelector(props.dropDownNumberFromNode)
    if (props.timeAnswerFromNode) setCheckBoxChange(props.timeAnswerFromNode)
    if (props.timeFromNode) setNumberInput(props.timeFromNode)
    if (props.dropDownFluxoFromNode) setDropdownFluxo(props.dropDownFluxoFromNode)
    if (props.acaoFromNode) setAcaoList(props.acaoFromNode)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible])

  const optionSelect = () => {
    if (!props.setOptionSelect) return

    switch (dropdownSelector) {
      case 'Por 3 segundos':
        props.setOptionSelect(3)
        break

      case 'Por 5 segundos':
        props.setOptionSelect(5)
        break

      case 'Por 10 segundos':
        props.setOptionSelect(10)
        break

      case 'Por 15 segundos':
        props.setOptionSelect(15)
        break
      default:
        props.setOptionSelect(0)
        break
    }
  }

  const optionSelectReturn = (option: string) => {
    switch (option) {
      case '3':
        return 'Por 3 segundos'

      case '5':
        return 'Por 5 segundos'

      case '10':
        return 'Por 10 segundos'

      case '15':
        return 'Por 15 segundos'
      default:
        return 'Não envia'
    }
  }
  const header = () => {
    return (
      <>
        <div className="flex align-items-center">
          <div
            className={`w-2rem h-2rem flex justify-content-center align-items-center border-circle bg-${
              props.color || 'purple'
            }-300 `}>
            {props.icon}
          </div>
          <span className="text-600 text-base ml-2">Configuração de {props.text}</span>
        </div>
        <Divider />
      </>
    )
  }

  const salvar = () => {
    if (validateValues()) {
      props.onHide(false)
      if (props.setShowFile) props.setShowFile(fileShow)
      if (props.setShowMesage) props.setShowMesage(mensagem)
      if (props.setShowDropdown) props.setShowDropdown(dropdownSelector)
      if (props.setOptionSelect) optionSelect()
      if (props.setButtons) props.setButtons(inputList)
      if (props.setNumberOption) props.setNumberOption(dropdownNumberSelector)
      if (props.setAnswer) props.setAnswer(checkBoxChange)
      if (props.setInputNumber) props.setInputNumber(numberInput)
      if (props.setDropDownFluxo) props.setDropDownFluxo(dropdownFluxo)
      if (props.setAcao) props.setAcao(acaoList)
      if (props.setSalvarCampo) props.setSalvarCampo(salvarCampo)
      if (props.setShowDoc) props.setShowDoc(file)
      if (props.setTypeDocument && fileType) props.setTypeDocument(fileType)
      if (props.setDeleteButtons) props.setDeleteButtons(deleteButtons)
      if (props.setHidden) props.setHidden(true)
    }
  }

  const validateValues = () => {
    let valid = true

    if (checkBoxChange) {
      if (!numberInput) {
        valid = false
        if (timeLabel.current !== undefined)
          timeLabel.current.className = `${timeLabel.current.className} ${styles.validade}`
        if (timeLabel2.current !== undefined)
          timeLabel2.current.className = `${timeLabel2.current.className} ${styles.validade}`
      }
      if (!dropdownNumberSelector) {
        valid = false
        if (dropDownLabel.current !== undefined)
          dropDownLabel.current.className = `${dropDownLabel.current.className} ${styles.validade}`
        if (dropDownLabel2.current !== undefined)
          dropDownLabel2.current.className = `${dropDownLabel2.current.className} ${styles.validade}`
      }
    }
    return valid
  }

  const closeModal = () => {
    if (props.fileFromNode) setFileShow(props.fileFromNode)
    if (props.textFromNode) setMensagem(props.textFromNode)
    if (props.dropdownFromNode) setDropdownSelector(props.dropdownFromNode)
    if (props.fileType) setFileType(props.fileType)
    if (props.buttonsFromNode) setInputList(props.buttonsFromNode)
    if (props.dropDownNumberFromNode) setDropdownNumberSelector(props.dropDownNumberFromNode)
    if (props.timeAnswerFromNode) setCheckBoxChange(props.timeAnswerFromNode)
    if (props.timeFromNode) setNumberInput(props.timeFromNode)
    if (props.dropDownFluxoFromNode) setDropdownFluxo(props.dropDownFluxoFromNode)
    if (props.acaoFromNode) setAcaoList(props.acaoFromNode)

    props.onHide()
  }

  const openModalFiles = () => {
    if (hasUpload) {
      return true
    } else {
      if (props.fileFromNode === undefined) return false
      if (props.fileFromNode.length > 1 && fileShow.length > 1) {
        return true
      } else {
        return false
      }
    }
  }

  const deleteImage = () => {
    setHasUpload(false)
    setFileShow('')
    setFile(undefined)
    if (inputFile.current === null) return
    inputFile.current.value = ''
    if (props.nodeId) deleteMedia(props.nodeId)
    if (props.setShowFile) props.setShowFile('')
    if (props.setShowDoc) props.setShowDoc(undefined)
  }

  const handleAddButton = () => {
    if (inputList === null) {
      setInputList([{ label: '', id: `${0}` }])
    } else {
      if (inputList.length >= 10) return
      setInputList([...inputList, { label: '', id: `${inputList.length}` }])
    }
  }

  const handleDeleteButton = (index: number, ButtonId: string) => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
    if (deleteButtons) {
      setDeleteButtons([...deleteButtons, ButtonId])
    } else {
      setDeleteButtons([ButtonId])
    }
  }

  const handleInputButtonChange = (e: any, index: number) => {
    if (e.target === null) return
    const { value } = e.target
    const list = [...inputList]
    list[index].label = value
    setInputList(list)
  }

  const handleCheckBox = (e: any) => {
    if (e === null) return
    setCheckBoxChange(e.checked)
  }

  const handleNumberInput = (e: any) => {
    if (e === null) return
    setNumberInput(e.value)
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return
    const fileInput = event.target.files[0]

    if (props.type === 'audio') {
      if (fileInput.type !== 'audio/mpeg')
        return toast?.current?.show({
          severity: 'error',
          summary: 'Ops! Tipo de arquivo inválido.',
          detail: 'Verifique se o arquivo é do tipo MP3',
          life: 3000,
        })
    }

    if (fileInput.size / 1024 > 67000) {
      if (uploadLabel.current.className.search(`${styles.validade}`) === -1) {
        uploadLabel.current.className = `${uploadLabel.current.className} ${styles.validade}`
      }
      return
    }
    uploadLabel.current.className = uploadLabel.current.className.replace(`${styles.validade}`, '')
    setFile(fileInput)
    setFileShow(URL.createObjectURL(fileInput))
    setHasUpload(true)
    if (props.type === 'document') {
      setFileShow(fileInput.name)
    }
    switch (fileInput.type) {
      case 'text/plain':
        setFileType(<AiFillFileText color="white" size={50} />)
        break

      case 'application/pdf':
        setFileType(<AiFillFilePdf color="white" size={50} />)
        break

      default:
        setFileType(<AiFillFile color="white" size={50} />)
        break
    }
  }

  const typeFile = () => {
    switch (props.type) {
      case 'image':
        return <Image className="ml-4" width="100" height="120" src={fileShow || props.fileFromNode} />
      case 'video':
        return (
          <video className="ml-2 border-round-lg" height="80%" width="50%" controls>
            <source src={fileShow || props.fileFromNode} />
          </video>
        )
      case 'audio':
        return (
          <audio controls>
            <source src={fileShow || props.fileFromNode} />
          </audio>
        )
      case 'document':
        return (
          <DocumentDiv className="flex align-items-center white-space-normal" style={{}}>
            {fileType || props.fileType} {fileShow || props.fileFromNode}
          </DocumentDiv>
        )
      default:
        break
    }
  }

  const groupedItemTemplate = (option: any) => {
    return (
      <>
        {option.label === 'add-new-field' ? (
          <div
            className="flex align-items-center cursor-pointer font-normal"
            onClick={() => onToggleModalCustomizeField(true)}>
            <span className="text-blue-500">+ Adicionar novo campo</span>
          </div>
        ) : (
          <div className="flex align-items-center">
            <div>{formatUseIn(option.label)}</div>
          </div>
        )}
      </>
    )
  }

  const optionsField = optionsFieldFormatted({})

  return (
    <>
      <Dialog
        className={styles.mypanel}
        header={header}
        onHide={closeModal}
        visible={props.visible}
        style={{ width: '500px' }}
        draggable={false}>
        <Toast ref={toast} />
        <div>
          {props.hasFileUpload ? (
            <>
              <div className="flex flex-column">
                <label className="font-bold">{props.text}:</label>
                <label ref={uploadLabel} style={{ display: 'none' }} className="text-red-400 mt- text-sm">
                  *Tamanho máximo excede 65mb
                </label>
              </div>
              {openModalFiles() ? (
                <div className="my-1 flex border-2 border-dashed border-round-lg border-400 h-9rem align-items-center justify-content-between">
                  {typeFile()}
                  <span className="mr-4 cursor-pointer">
                    <span>
                      <AiOutlineDelete onClick={deleteImage} size={25} />
                    </span>
                  </span>
                </div>
              ) : (
                <>
                  <Upload onClick={() => inputFile?.current?.click()}>
                    <AiOutlineCloudUpload size={25} />
                    Upload de arquivo
                  </Upload>
                </>
              )}
              <input type="file" onChange={handleFileChange} ref={inputFile} hidden accept={props.accept} />
            </>
          ) : null}
          {props.hasChageFluxo ? (
            <>
              <div className="mb-2">
                <span className="font-bold">Mover para o fluxo</span>
              </div>
              <div className="w-full">
                <Dropdown
                  value={dropdownFluxo}
                  options={props.changeFluxoOptions}
                  optionValue="value"
                  optionLabel="name"
                  onChange={(e) => setDropdownFluxo(e.value)}
                  className="w-full"
                  filter
                />
              </div>
            </>
          ) : null}
          {props.hasDropdown ? (
            <div className="mt-2">
              {props.type === 'audio' ? (
                <LabelText>Simular &quot;gravando aúdio...&quot;?</LabelText>
              ) : (
                <LabelText>Simular &quot;digitando...&quot;?</LabelText>
              )}
              <Checkbox className="ml-5" checked={checkBoxChange} onChange={(e) => handleCheckBox(e)} />
              {checkBoxChange ? (
                <div className="mt-2">
                  <div className="flex">
                    <div className={`flex flex-column`}>
                      <InputNumber
                        useGrouping={false}
                        style={{ width: '45%' }}
                        value={numberInput}
                        onValueChange={(e) => handleNumberInput(e)}
                      />
                      <label ref={timeLabel2} style={{ display: 'none' }} className="text-red-400 mt-1 text-sm">
                        *Campo obrigatório
                      </label>
                    </div>
                    <div className="flex flex-column w-full">
                      <Dropdown
                        options={['Segundo(s)', 'Minuto(s)']}
                        className="ml-3"
                        style={{ width: '95%' }}
                        value={dropdownNumberSelector}
                        onChange={(e) => setDropdownNumberSelector(e.value)}
                      />
                      <label
                        ref={dropDownLabel2}
                        className={`text-red-400 mt-1 text-sm ml-3`}
                        style={{ display: 'none' }}>
                        *Campo obrigatório
                      </label>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
          {props.hasText ? (
            <div className="my-2">
              <div className="mb-2 mt-4 flex justify-content-between">
                <label className="font-bold">Mensagem:</label>
              </div>
              {/* <div className='border-round border-1 h-10rem'>
                        
                      </div>     */}
              <div className="pb-5">
                <EditorComponent text={mensagem} setText={setMensagem} />
              </div>
              {/* <InputTextarea className="mt-1 w-full" autoResize value={mensagem} onChange={(e)=>setMensagem(e.target.value)} rows={5}  />  */}
            </div>
          ) : null}
          {props.hasButton ? (
            <>
              <div className="flex flex-column my-2">
                <span className="font-bold mb-2">Botões:</span>
                {inputList?.map((list: any, i: number) => {
                  return (
                    <div key={i} className="flex mb-2">
                      <div className="w-full">
                        <InputText
                          className="w-full"
                          value={list.label}
                          onChange={(e) => handleInputButtonChange(e, i)}
                        />
                      </div>
                      <div className="ml-4 flex align-items-center cursor-pointer">
                        <AiOutlineDelete color="red" size={22} onClick={() => handleDeleteButton(i, list.id)} />
                      </div>
                    </div>
                  )
                })}
                <div className="flex align-items-center justify-content-center mt-2">
                  <Button
                    className="h-3rem border-rounded p-button-outlined"
                    onClick={handleAddButton}
                    label="Adicionar botão"
                  />
                </div>
              </div>
            </>
          ) : null}
          {props.hasRespostaCampo ? (
            <div className="mt-4">
              <div className="mb-2">
                <LabelText>Salvar resposta no campo:</LabelText>
              </div>
              <div className="w-full">
                <Dropdown
                  value={salvarCampo}
                  onChange={(e) => setSalvarCampo(e.value)}
                  options={[
                    {
                      label: 'add-new-field',
                      code: 'add-new-field',
                      items: [],
                    },
                    ...optionsField,
                  ]}
                  optionLabel="value"
                  optionValue="id"
                  optionGroupLabel="label"
                  optionGroupChildren="items"
                  itemTemplate={itemTemplate}
                  optionGroupTemplate={groupedItemTemplate}
                  className="w-full"
                  placeholder="Selecione um campo para salvar a resposta"
                />
              </div>
            </div>
          ) : null}
          {props.hasTimeToAnswer ? (
            <div className="mt-4">
              <LabelText>Configurar Tempo de Resposta?</LabelText>
              <Checkbox className="ml-5" checked={checkBoxChange} onChange={(e) => handleCheckBox(e)} />
              {checkBoxChange ? (
                <div className="mt-4">
                  <div className="mb-2">
                    <LabelText>Deve responder em:</LabelText>
                  </div>
                  <div className="flex">
                    <div className={`flex flex-column`}>
                      <InputNumber
                        useGrouping={false}
                        style={{ width: '45%' }}
                        value={numberInput}
                        onValueChange={(e) => handleNumberInput(e)}
                      />
                      <label ref={timeLabel} style={{ display: 'none' }} className="text-red-400 mt-1 text-sm">
                        *Campo obrigatório
                      </label>
                    </div>
                    <div className="flex flex-column w-full">
                      <Dropdown
                        options={['Segundo(s)', 'Minuto(s)', 'Hora(s)', 'Dia(s)']}
                        className="ml-3"
                        style={{ width: '95%' }}
                        value={dropdownNumberSelector}
                        onChange={(e) => setDropdownNumberSelector(e.value)}
                      />
                      <label
                        ref={dropDownLabel}
                        className={`text-red-400 mt-1 text-sm ml-3`}
                        style={{ display: 'none' }}>
                        *Campo obrigatório
                      </label>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}

          {props.hasAcao ? <TagComponent tags={props.tags} acaoList={acaoList} setAcaoList={setAcaoList} /> : null}
          <Button label="Salvar" className="p-button-help w-full mt-4" onClick={salvar} />
        </div>
      </Dialog>
    </>
  )
}
