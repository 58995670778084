import initialNodes from './nodes'
import initialEdges from './edges'
import { nodeService } from './../data/services/NodeServices'
import {
  Connection,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  OnConnect,
  OnEdgesChange,
  OnNodesChange,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
} from 'reactflow'
import { edgeService } from './../data/services/EdgeServices'
import { tagService } from './../data/services/TagServices'
import { create } from 'zustand'
import { Departament, Tag } from '../data/types/tagType'
import { departamentsService } from '../data/services/DepartamentServices'

export type RFState = {
  nodes: Node[]
  edges: Edge[]
  oldEdgs: Edge[]
  load: boolean
  token: string
  flowId: string
  tags: Tag[] | undefined
  departments: Departament[] | undefined
  viewPort: { x: number; y: number; zoom: number }
  setFlowId: (flowId: string) => void
  setDepartament: (departament: Departament[]) => void
  setToken: (token: string) => void
  onNodesChange: OnNodesChange
  onEdgesChange: OnEdgesChange
  onConnect: OnConnect
  updateNodeType: (nodeId: string, nodeType: string) => void
  setNodes: (node: Node) => void
  deleteNode: (nodeId: string) => void
  duplicateNode: (nodeId: string) => void
  deleteEdge: (edgeId: string) => void
  createNode: (node: Node) => void
  updateAllNodes: () => void
  updateNodeData: (nodeId: string, data: any) => Promise<any>
  getTag: () => Promise<any>
  createTag: (name: string) => Promise<any>
  deleteTag: (tagId: string) => void
  setEdges: (edges: Edge[]) => void
  setViewPort: (x: number, y: number, zoom: number) => void
  updateViewPortDB: (x: string, y: string, zoom: string) => void
  getFlows: () => Promise<any>
  getDepartament: () => Promise<any>
  uploadFile: (nodeId: string, fomData: FormData, uploadProgress?: (event: any) => void) => Promise<any>
  tagFilter: (query: string) => Promise<any>
  ConnectionEnd: (node: Node, nodeId: string) => void
  onEdgeDrop: (nodeId: string, nodeType: string) => void
  removeOnEdgeDrop: (nodeId: string) => void
  setTag: (tags: Tag[]) => void
  deleteNoNescessariEdge: (nodeId: string, sourceHandle: string) => void
  iHaveEdge: (edgeId: string) => void
  deleteEdgeFromButton: (sourceHandle: string) => void
  deleteMedia: (nodeId: string) => void
  departamentFilter: (query: string) => Promise<any>
  updatedEdges: (id: string[]) => void
}

export const useStore = create<RFState>((set, get) => ({
  nodes: initialNodes,
  edges: initialEdges,
  oldEdgs: [],
  flowId: '',
  token: '',
  load: true,
  tags: undefined,
  departments: undefined,
  viewPort: { x: 454, y: 270, zoom: 1.0838102545547001 },
  setEdges: (edges: Edge[]) => {
    set({
      edges: edges,
    })
  },
  setTag: (tags: Tag[]) => {
    set({
      tags: tags,
    })
  },
  setDepartament: (departament: Departament[]) => {
    set({
      departments: departament,
    })
  },
  getDepartament: async () => {
    return await departamentsService.get(get().flowId)
  },
  setViewPort: (x: number, y: number, zoom: number) => {
    set({
      viewPort: { x, y, zoom },
    })
  },
  setToken: (token: string) => {
    set({
      token: token,
    })
  },
  updateViewPortDB: (x: string, y: string, zoom: string) => {
    nodeService.updateViewPort(get().flowId, { x, y, zoom })
  },
  setFlowId: (flowId: string) => {
    set({
      flowId: flowId,
    })
  },
  createTag: (name: string) => {
    return tagService.create(get().flowId, name)
  },
  deleteTag: (tagId: string) => {
    tagService.delete(get().flowId, tagId)
  },
  getFlows: async () => {
    return await nodeService.getFlows()
  },
  uploadFile: (nodeId: string, fomData: FormData, uploadProgress?: (event: any) => void) => {
    const response = nodeService.uploadFile(nodeId, get().flowId, fomData, uploadProgress)
    response.then((res) => {
      set({
        nodes: get().nodes.map((node) => {
          if (node.id === nodeId) {
            node.data.mediaUrl = res.data.data.mediaUrl
          }
          return node
        }),
      })
    })
    return response
  },
  deleteNoNescessariEdge: (nodeId: string, sourceHandle: string) => {
    const edge = get()
      .edges.filter((edge) => edge.source === nodeId)
      .find((edge) => edge.sourceHandle === sourceHandle)
    if (edge) {
      get().deleteEdge(edge.id)
    }
  },
  ConnectionEnd: (node: Node, nodeSplit: string) => {
    const [nodeId, sourceHandle] = nodeSplit.split(',')

    if (get().edges.some((edge: Edge) => edge.source === nodeId)) {
      if (sourceHandle === 'a') {
        if (
          get()
            .edges.filter((edg) => edg.source === nodeId)
            .find((edg) => edg.sourceHandle === 'a')
        ) {
          return
        }
      } else {
        if (get().edges.some((edge) => edge.sourceHandle === sourceHandle && edge.sourceHandle === nodeId)) return
      }
    }
    get().setNodes(node)

    const connection: Connection = {
      source: nodeId,
      target: node.id,
      sourceHandle: sourceHandle,
      targetHandle: 'a',
    }

    set({
      edges: addEdge(connection, get().edges),
    })
  },
  updateAllNodes: () => {
    nodeService.getFlow(get().flowId).then((res) => {
      if (res.data.nodes) {
        res.data.nodes.forEach(async (node: any) => {
          const myNode: Node = {
            position: {
              x: parseFloat(node?.position?.x || '0'),
              y: parseFloat(node?.position?.y || '0'),
            },
            type: node.type,
            data: node.data,
            id: node.id,
          }

          await set({
            nodes: [...get().nodes, myNode],
          })
        })

        set({
          viewPort: {
            x: parseFloat(res.data.viewport?.x!),
            y: parseFloat(res.data.viewport?.y!),
            zoom: parseFloat(res.data.viewport?.zoom!),
          } || { x: 454, y: 270, zoom: 1.0838102545547001 },
          load: false,
        })
      }
      res.data.edges.forEach((edge) => {
        const newEdge: any = {
          type: 'buttonedge',
          source: edge.source,
          target: edge.target,
          animated: true,
          id: `${edge.id}`,
          sourceHandle: `${edge.sourceHandle || 'a'}`,
        }
        if (newEdge.sourceHandle !== 'a') {
          if (newEdge.sourceHandle !== 'error') {
            set({
              oldEdgs: [...get().oldEdgs, newEdge],
            })
          } else {
            set({
              edges: addEdge(newEdge, get().edges),
            })
          }
        } else {
          set({
            edges: addEdge(newEdge, get().edges),
          })
        }
      })

      if (get().nodes.length < 2) {
        const node = get().nodes.find((node) => node.type === 'START_FLOW')
        if (node === undefined) return

        get().setNodes({
          id: 'step',
          type: 'EdgeDrop',
          data: {},
          position: { x: node.position.x + 450, y: node.position.y - 100 },
        })
        const connection: Connection = {
          source: node.id,
          target: 'step',
          sourceHandle: 'a',
          targetHandle: 'a',
        }
        set({
          edges: addEdge(connection, get().edges),
        })
      }
    })
  },
  onNodesChange: (changes: NodeChange[]) => {
    if (get().nodes.find((node) => node.id === 'step')?.type === 'EdgeDrop' && get().nodes.length <= 2) return
    set({
      nodes: applyNodeChanges(changes, get().nodes),
    })
  },
  onEdgesChange: (changes: EdgeChange[]) => {
    set({
      edges: applyEdgeChanges(changes, get().edges),
    })
  },
  getTag: () => {
    return tagService.get(get().flowId)
  },
  onConnect: (connection: Connection) => {
    if (connection.target === null) return
    if (connection.source === null) return

    // Verifica se um node está sendo ligado nele mesmo
    if (connection.target === connection.source) return

    // Verifica se a saída(source) da conexão já possui um nó com o mesmo sourceHanle
    if (
      get().edges.some(
        (edge: Edge) => connection.source === edge.source && connection.sourceHandle === edge.sourceHandle
      )
    )
      return

    const sourceHandle = connection.sourceHandle === 'a' ? undefined : connection.sourceHandle

    edgeService
      .create(connection.target, connection.source, get().flowId, sourceHandle)
      .then((res) => {
        set({
          edges: addEdge({ ...connection, type: 'buttonedge', animated: true, id: res.data.id }, get().edges),
        })
      })

      .catch((err) => console.log(err))
  },
  updateNodeType: (nodeId: string, nodeType: string) => {
    set({
      nodes: get().nodes.filter((node: Node) => node.type !== 'StartBlock'),
    })
    const nodeStartType = get().nodes.find((node) => node.type === 'START_FLOW')
    if (nodeStartType === undefined) return

    const newNode: Node = {
      id: 'step',
      type: nodeType,
      data: {},
      position: { x: nodeStartType.position.x + 450, y: nodeStartType.position.y - 100 },
    }
    nodeService
      .create(
        {
          x: `${newNode.position.x}`,
          y: `${newNode.position.y}`,
        },
        `${newNode.type}`,
        500,
        500,
        get().flowId
      )
      .then((response) => {
        const responseNode = response.data
        const node = {
          id: responseNode.id,
          position: {
            x: nodeStartType.position.x + 450,
            y: nodeStartType.position.y - 100,
          },
          data: {},
          type: responseNode.type,
        }
        get().setNodes(node)
        const nodeStart = get().nodes.find((node: Node) => node.type === 'START_FLOW')
        const connection: Connection = {
          source: nodeStart?.id || null,
          sourceHandle: 'a',
          target: node?.id || null,
          targetHandle: 'a',
        }
        get().onConnect(connection)
      })
      .catch((err) => console.log(err))
  },
  removeOnEdgeDrop: (nodeId: string) => {
    set({
      nodes: get().nodes.filter((node) => node.id !== nodeId),
      edges: get().edges.filter((edge) => edge.target !== nodeId),
    })
  },
  onEdgeDrop: (nodeId: string, nodeType: string) => {
    const oldNode = get().nodes.find((node: Node) => node.id === nodeId)
    set({
      nodes: get().nodes.filter((node: Node) => node.id !== nodeId),
    })

    if (oldNode === undefined) return
    nodeService
      .create(
        {
          x: `${oldNode.position.x}`,
          y: `${oldNode.position.y}`,
        },
        `${nodeType}`,
        500,
        500,
        get().flowId
      )
      .then((response) => {
        if (oldNode) {
          const responseNode = response.data
          const node = {
            id: responseNode.id,
            position: oldNode.position,
            data: {},
            type: responseNode.type,
          }
          get().setNodes(node)
          const edge = get().edges.find((edge) => edge.target === nodeId)
          set({
            edges: get().edges.filter((edge) => edge.target !== nodeId),
          })
          if (edge) {
            const connection: Connection = {
              source: edge.source,
              target: node.id,
              sourceHandle: edge.sourceHandle || null,
              targetHandle: edge.targetHandle || null,
            }

            get().onConnect(connection)
          }
        }
      })
  },
  createNode: (node: Node) => {
    const position = {
      x: `${node.position.x}`,
      y: `${node.position.y}`,
    }
    nodeService
      .create(
        position,
        `${node.type}`,

        500,
        500,
        get().flowId
      )
      .then((response) => {
        set({
          nodes: get().nodes.map((nodeMap) => {
            if (nodeMap.id === node.id) {
              nodeMap.id = response.data.id
            }
            return nodeMap
          }),
        })
      })
  },
  duplicateNode: (nodeId: string) => {
    const duplicateNode = get().nodes.find((node) => node.id === nodeId)

    if (duplicateNode === undefined) return
    if (duplicateNode.type === 'BUTTONS') {
      const buttons = duplicateNode.data.buttons.map((button: { id: string; label: string }) => {
        return { label: button.label }
      })
      const create = {
        id: 'duplicateNode',
        type: duplicateNode.type,
        position: {
          x: duplicateNode.position.x,
          y: duplicateNode.position.y + 300,
        },
        data: {
          ...duplicateNode.data,
          buttons: buttons,
        },
      }

      nodeService
        .create(
          {
            x: `${create.position.x}`,
            y: `${create.position.y}`,
          },
          `${create.type}`,
          500,
          500,
          get().flowId
        )
        .then((response) => {
          nodeService.updateData(response.data.id, get().flowId, create.data).then((res) => {
            const newNod = {
              position: create.position,
              id: response.data.id,
              data: res.data.data,
              type: create.type,
            }
            get().setNodes(newNod)
          })
        })
    } else {
      let create = {
        type: duplicateNode.type,
        position: {
          x: duplicateNode.position.x,
          y: duplicateNode.position.y + 300,
        },
        data: duplicateNode.data,
      }

      let newActions = []
      if (duplicateNode.type === 'ACTION') {
        newActions = duplicateNode.data.actions.map((action: any) => {
          if (action.actionType === 'ADD_TAGS' || action.actionType === 'REMOVE_TAGS') {
            if (!action.tags[0].name) return action

            return {
              ...action,
              tags: action.tags.map((tag: any) => tag.id),
            }
          }

          return action
        })
      }

      const newData = newActions.length > 0 ? { ...duplicateNode.data, actions: newActions } : duplicateNode.data

      nodeService
        .create(
          {
            x: `${create.position.x}`,
            y: `${create.position.y}`,
          },
          `${create.type}`,
          500,
          500,
          get().flowId,
          newData
        )
        .then((response) => {
          nodeService.updateData(response.data.id, get().flowId, newData).then((res) => {
            console.log(res)
            const newNod = {
              position: create.position,
              id: response.data.id,
              data: res.data.data,
              type: create.type,
            }
            get().setNodes(newNod)
          })
        })
    }
  },
  deleteNode: (nodeId: string) => {
    const deletEdge = get().edges.filter((oldEdge) => oldEdge.target === nodeId)

    const hasSteponEdge = get()
      .edges.filter((edge) => edge.source === nodeId)
      .filter((edge) => !edge.type)
    if (hasSteponEdge) {
      hasSteponEdge.forEach((has) => {
        set({
          nodes: get().nodes.filter((node) => node.id !== has.target),
          edges: get().edges.filter((edg) => edg.id !== has.id),
        })
      })
    }

    set({
      nodes: get().nodes.filter((node) => node.id !== nodeId),
      edges: get().edges.filter((edge) => edge.target !== nodeId),
    })
    nodeService.delete(nodeId, get().flowId)
    if (deletEdge.length > 0) edgeService.delete(deletEdge[0].id, get().flowId)
    if (get().nodes.length <= 1) {
      const nodeStartType = get().nodes.find((node) => node.type === 'START_FLOW')
      if (nodeStartType === undefined) return

      get().setNodes({
        id: 'step',
        type: 'EdgeDrop',
        data: {},
        position: {
          x: nodeStartType.position.x + 450,
          y: nodeStartType.position.y - 200,
        },
      })

      const connection: Connection = {
        source: nodeStartType.id,
        target: 'step',
        sourceHandle: 'a',
        targetHandle: 'a',
      }
      set({
        edges: addEdge(connection, get().edges),
      })
    }
  },
  deleteEdge: (edgeId: string) => {
    if (get().edges.some((edge) => edge.id === edgeId)) {
      edgeService.delete(edgeId, get().flowId).then(() => {
        set({
          edges: get().edges.filter((edge) => edge.id !== edgeId),
        })
      })
    }
  },
  deleteEdgeFromButton: (sourceHandle: string) => {
    if (get().edges.some((edge) => edge.sourceHandle === sourceHandle)) {
      const deleteEdge = get().edges.find((edge) => edge.sourceHandle === sourceHandle)
      if (!deleteEdge) return
      set({
        edges: get().edges.filter((edge) => edge.id !== deleteEdge.id),
      })
      edgeService.delete(deleteEdge.id, get().flowId)
    }
  },
  updateNodeData: (nodeId: string, data: any) => {
    return new Promise((resolve, reject) => {
      nodeService
        .updateData(nodeId, get().flowId, data)
        .then((response) => {
          set({
            nodes: get().nodes.map((node) => {
              if (node.id === nodeId) {
                node.data = data
              }
              return node
            }),
          })
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setNodes: (node: Node) => {
    if (get().nodes.length <= 2) {
      set({
        nodes: get().nodes.filter((node) => node.type !== 'StartBlock'),
      })
    }
    set({
      nodes: get().nodes.concat(node),
    })
  },
  tagFilter: async (query: string) => {
    return await tagService.filter(get().flowId, query)
  },
  departamentFilter: async (query: string) => {
    return await departamentsService.filter(get().flowId, query)
  },
  iHaveEdge: (buttons: any) => {
    if (buttons === null) return
    buttons.forEach((button: { id?: string }) => {
      if (button.id !== undefined) {
        if (!get().oldEdgs.some((edge) => edge.sourceHandle === button.id)) return
        const iHave = get().oldEdgs.find((edge) => edge.sourceHandle === button.id)
        if (iHave) {
          if (!get().edges.find((edge) => edge.id === iHave.id)) {
            set({
              edges: addEdge(iHave, get().edges),
              oldEdgs: get().oldEdgs.filter((edge) => edge.sourceHandle !== button.id),
            })
          }
        }
      }
    })
  },
  deleteMedia: (nodeId: string) => {
    nodeService.deleteFile(nodeId, get().flowId)
  },
  updatedEdges: (sourceHandles: string[]) => {
    if (!sourceHandles.length || sourceHandles.length === null) return

    sourceHandles.forEach((item) => {
      if (item !== undefined) {
        if (!get().oldEdgs.some((edge) => edge.sourceHandle === item)) return

        const iHave = get().oldEdgs.find((edge) => edge.sourceHandle === item)
        if (iHave) {
          if (!get().edges.find((edge) => edge.id === iHave.id)) {
            set({
              edges: addEdge(iHave, get().edges),
              oldEdgs: get().oldEdgs.filter((edge) => edge.sourceHandle !== item),
            })
          }
        }
      }
    })
  },
}))

export default useStore
