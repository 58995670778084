import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown'
import { Editor } from 'primereact/editor'
import { Tooltip } from 'primereact/tooltip'
import 'quill-mention'
import { useRef, useState } from 'react'
import { useCustomizeFieldsStore } from '../../store/customizeFields'
import { itemTemplate } from '../../utils/templates'
import './styles.css'
import { formatUseIn } from '../../utils/format'

import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import useOnClickOutside from '../../utils/useOnClickOutside'
import { MdOutlineEmojiEmotions } from 'react-icons/md'

const modules = {
  mention: {
    allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
    mentionDenotationChars: [],
  },
}

export default function EditorComponent({ text, setText }: { text: string; setText: (value: string) => void }) {
  const { optionsField, optionsFieldFormatted, onToggleModalCustomizeField } = useCustomizeFieldsStore((state) => ({
    optionsField: state.optionsField,
    optionsFieldFormatted: state.optionsFieldFormatted,
    onToggleModalCustomizeField: state.onToggleModalCustomizeField,
  }))
  const quillRef = useRef<Editor>(null)
  const quill = quillRef.current?.getQuill()

  const handleSelectedEmoji = (emoji: any) => {
    quill.insertText(quill.getSelection(true), emoji)

    setIsModalEmoji(false)
  }

  const handleChangeOption = (e: DropdownChangeEvent) => {
    const selectedOption = optionsField.find((option) => option.id === e.value)

    if (quill) {
      const moduleMention = quill.getModule('mention')
      const mention = {
        value: selectedOption?.value,
        id: selectedOption?.id,
        denotationChar: '',
      }
      moduleMention.insertItem(mention, true)
    }
  }

  const groupedItemTemplate = (option: any) => {
    return (
      <>
        {option.label === 'add-new-field' ? (
          <div
            className="flex align-items-center cursor-pointer font-normal"
            onClick={() => onToggleModalCustomizeField(true)}>
            <span className="text-blue-500">+ Adicionar novo campo</span>
          </div>
        ) : (
          <div className="flex align-items-center">
            <div>{formatUseIn(option.label)}</div>
          </div>
        )}
      </>
    )
  }

  const [isModalEmoji, setIsModalEmoji] = useState(false)

  const refModalEmoji = useRef<HTMLDivElement>(null)
  useOnClickOutside(refModalEmoji, () => setIsModalEmoji(false))

  const renderHeader = () => {
    return (
      <div className="flex flex-row justify-content-between align-items-center relative">
        <span className="ql-formats">
          <Tooltip target=".target-tooltip" />
          <button className="ql-bold target-tooltip" aria-label="Bold" data-pr-tooltip="Negrito"></button>
          <button className="ql-strike target-tooltip" aria-label="strike" data-pr-tooltip="Taxado"></button>
          <button className="ql-italic target-tooltip" aria-label="Italic" data-pr-tooltip="Itálico"></button>
          <button className="" onClick={() => setIsModalEmoji(true)}>
            <MdOutlineEmojiEmotions size={18} color={'#6c757d'} />
          </button>
          {isModalEmoji && (
            <div className="absolute z-1 emoji-container" ref={refModalEmoji} style={{ height: '300px' }}>
              <Picker
                emojiSize={20}
                data={data}
                onEmojiSelect={(e: any) => handleSelectedEmoji(e.native)}
                locale="pt"
                perLine={11}
                theme="light"
                previewPosition="none"
              />
            </div>
          )}
        </span>
        <Dropdown
          onChange={(e) => {
            if (e.value === 'add-new-field') return onToggleModalCustomizeField(true)
            handleChangeOption(e)
          }}
          placeholder="Campos"
          options={[
            {
              label: 'add-new-field',
              code: 'add-new-field',
              items: [],
            },
            ...optionsFieldFormatted({}),
          ]}
          itemTemplate={itemTemplate}
          optionLabel="value"
          optionValue="id"
          optionGroupLabel="label"
          optionGroupChildren="items"
          optionGroupTemplate={groupedItemTemplate}
          filter
          className=" __dropdown"
          panelClassName="panel-dropdown-quill"
        />
      </div>
    )
  }

  const header = renderHeader()

  return (
    <div>
      <Editor
        value={text}
        onTextChange={(e) => setText(e.htmlValue || '')}
        headerTemplate={header}
        className="my-editor-class h-10rem mb-5"
        ref={quillRef}
        formats={['bold', 'strike', 'italic', 'mention']}
        modules={{ ...modules }}
      />
    </div>
  )
}
