/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-unresolved */
import useStore from '../store/store'

import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactFlow, {
  Connection,
  MiniMap,
  Node,
  OnConnectStartParams,
  ReactFlowInstance,
  useOnViewportChange,
} from 'reactflow'

import 'reactflow/dist/style.css'
import '/node_modules/primeflex/primeflex.css'

import { ProgressSpinner } from 'primereact/progressspinner'
import { useParams, useSearchParams } from 'react-router-dom'

import { ControlsCustom } from '../components/menu/ControlsCustom'
import Menu from '../components/menu/Menu'
import { useCustomizeFieldsStore } from '../store/customizeFields'
import { nodeService } from './../data/services/NodeServices'

import ActionNode from '../containers/Action'
import AudioNode from '../containers/Audio'
import ButtonNode from '../containers/Button'
import ConditionNode from '../containers/Condition'
import DocNode from '../containers/Document'
import EdgeDrop from '../containers/EdgeDrop'
import ImageNode from '../containers/Image'
import IntegrationNode from '../containers/Integration'
import MoveFlow from '../containers/MoveFlow'
import QuestNode from '../containers/Quest'
import SendContactsNode from '../containers/SendContacts'
import SmartDelayNode from '../containers/SmartDelay'
import StartBlock from '../containers/Start'
import StartNode from '../containers/Start/node'
import StickNode from '../containers/Stick'
import TextNode from '../containers/Text'
import VideoNode from '../containers/Video'

import { AxiosResponse } from 'axios'
import { debounce } from 'lodash'
import { Toast } from 'primereact/toast'
import { RiCheckLine } from 'react-icons/ri'
import ButtonEdge from '../components/ButtonEdge'
import { ModalCustomizeField } from '../containers/ModalCustomizeField'
import RandomizerNode from '../containers/Randomizer'
import { axiosInstance } from '../data/Https/axios-instance'

const proOptions = { hideAttribution: true }

const nodeTypes = {
  START_FLOW: StartNode,
  TEXT_MESSAGE: TextNode,
  VIDEO: VideoNode,
  IMAGE: ImageNode,
  StickNode: StickNode,
  AUDIO: AudioNode,
  FILE: DocNode,
  StartBlock: StartBlock,
  BUTTONS: ButtonNode,
  DELAY: SmartDelayNode,
  MOVE_FLOW: MoveFlow,
  USER_INPUT: QuestNode,
  ACTION: ActionNode,
  EdgeDrop: EdgeDrop,
  INTEGRATION: IntegrationNode,
  CONDITION: ConditionNode,
  SEND_CONTACTS: SendContactsNode,
  RANDOMIZER: RandomizerNode,
}

const edgeType = {
  buttonedge: ButtonEdge,
}

const selector = (state: any) => ({
  nodes: state.nodes,
  edges: state.edges,
  onNodesChange: state.onNodesChange,
  onEdgesChange: state.onEdgesChange,
  onConnect: state.onConnect,
  ConnectionEnd: state.ConnectionEnd,
  getDepartament: state.getDepartament,
  setDepartament: state.setDepartament,
})
let id = 0
const getId = () => {
  return `${id++}`
}

const Flow = () => {
  const toastRef = useRef<Toast>(null)
  const { handleGetFields, isOpenModalCustomizeField } = useCustomizeFieldsStore((state) => ({
    handleGetFields: state.handleGetFields,
    isOpenModalCustomizeField: state.isOpenModalCustomizeField,
  }))

  const [searchParams] = useSearchParams()
  const [dragable, setDragable] = useState(true)
  const [connection, setConnection] = useState(true)
  const getTag = useStore((state) => state.getTag)
  const token = searchParams.get('token')

  const params = useParams()

  const flowId = params.flowId

  const { nodes, edges, onNodesChange, onConnect, onEdgesChange, ConnectionEnd, getDepartament, setDepartament } =
    useStore(selector)
  const warp = useRef<HTMLDivElement>(null)
  const connectingNodeId = useRef<string | null>(null)
  const reactFlowWrapper = useRef<any>(null)
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null)
  const [xyStart, setXyStary] = useState<any>()

  const setFlowId = useStore((state) => state.setFlowId)
  const setTags = useStore((state) => state.setTag)
  const setToken = useStore((state) => state.setToken)

  const addNode = useStore((state) => state.setNodes)

  const load = useStore((state) => state.load)
  const viewPort = useStore.getState().viewPort

  const updateViewPortDB = useStore((state) => state.updateViewPortDB)

  const createNode = useStore((state) => state.createNode)

  const updateAllNodes = useStore((state) => state.updateAllNodes)

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()

    event.dataTransfer.dropEffect = 'move'
  }

  const onDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    const type = event.dataTransfer.getData('application/reactflow')
    if (type === 'undefined' || !type) return
    const position = reactFlowInstance?.project({
      x: event.clientX,
      y: event.clientY,
    })
    if (!position) return
    const newNode: Node = {
      id: getId(),
      type,
      position,
      data: {},
    }
    addNode(newNode)

    if (flowId) {
      createNode(newNode)
    }
  }

  const debouncedUpdateViewport = useCallback(
    debounce((viewport) => {
      updateViewPortDB(viewport.x.toString(), viewport.y.toString(), viewport.zoom.toString())
    }, 600),
    [updateViewPortDB]
  )

  useOnViewportChange({
    onEnd: debouncedUpdateViewport,
  })

  const onNodeDragStop = (event: any, nodeMove: Node) => {
    if (nodeMove?.type === 'StartBlock' || nodeMove?.type === 'EdgeDrop') return
    if (nodes.find((node: Node) => node.type === 'StartBlock')) return
    if (xyStart !== nodeMove.position) {
      event.preventDefault()
      const position = {
        x: nodeMove.position.x.toString(),
        y: nodeMove.position.y.toString(),
      }
      nodeService.updatePosition(nodeMove.id, `${flowId}`, position)
    }
  }

  const onConnectionStart = useCallback((event: any, params: OnConnectStartParams) => {
    connectingNodeId.current = `${params.nodeId},${params.handleId},${params.handleType}`
  }, [])

  const onConnectionEnd = (event: any) => {
    if (connectingNodeId.current?.search('target') !== -1) return

    const targetIsPane = event.target.classList.contains('react-flow__pane')
    if (!targetIsPane) return
    const position = reactFlowInstance?.project({
      x: event.clientX,
      y: event.clientY,
    })
    if (!position) return

    const hasConnectionInProgress = warp.current?.getElementsByClassName('react-flow__handle-valid')

    if (hasConnectionInProgress?.length) return

    const newNode: Node = {
      id: getId(),
      type: 'EdgeDrop',
      position,
      data: {},
    }

    ConnectionEnd(newNode, connectingNodeId.current)
  }

  useEffect(() => {
    if (flowId === null) return
    if (flowId === undefined) return
    if (flowId.length > 1) {
      setFlowId(flowId)

      updateAllNodes()
      getTag().then((res) => {
        setTags(res.data.content)
      })
      getDepartament().then((res: any) => {
        setDepartament(res.data.content)
      })
    }
  }, [flowId, getDepartament, getTag, setDepartament, setFlowId, setTags, updateAllNodes])

  useEffect(() => {
    if (token === null) return
    if (token === undefined) return
    if (token.length > 1) {
      setToken(token)
    }
  }, [setToken, token])

  useEffect(() => {
    handleGetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnConnect = async (connection: Connection) => {
    await onConnect(connection)
  }

  const isToastVisible = () => {
    const toastElements = Array.from(
      document.getElementsByClassName('interceptors-toast') as HTMLCollectionOf<HTMLElement>
    )

    return toastElements.some((toastElement) => toastElement.classList.contains('interceptors-toast'))
  }

  const setupInterceptors = () => {
    const interceptor = axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        if (['OK', 'Created'].includes(response.statusText) && toastRef.current && !isToastVisible()) {
          toastRef.current?.show({
            severity: 'success',
            life: 2000,
            className: 'interceptors-toast',
            content: (
              <div className="flex align-items-center gap-2" style={{ flex: '1' }}>
                <RiCheckLine size={30} />
                Suas alterações foram salvas!
              </div>
            ),
          })
        }

        return response
      },
      (error) => {
        return Promise.reject(error)
      }
    )

    return () => {
      axiosInstance.interceptors.response.eject(interceptor)
    }
  }

  useEffect(() => {
    const cleanup = setupInterceptors()

    return () => {
      cleanup()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Toast ref={toastRef} />
      {isOpenModalCustomizeField && <ModalCustomizeField />}

      {load ? (
        <ProgressSpinner className="absolute z-2 top-50 rigth-50" />
      ) : (
        <div className="h-full relative">
          <div
            className="absolute z-1 right-0 mr-2 flex flex-column align-items-center gap-3 translate-y-50"
            style={{
              top: '32%',
            }}>
            <Menu />

            <ControlsCustom setConnection={setConnection} setDrag={setDragable} />
          </div>
          <div className="h-full" ref={warp}>
            <ReactFlow
              minZoom={0.1}
              defaultViewport={viewPort}
              onConnectStart={onConnectionStart}
              onConnectEnd={onConnectionEnd}
              style={{ backgroundColor: '#F4F6FA' }}
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={handleOnConnect}
              proOptions={proOptions}
              onDragOver={onDragOver}
              edgeTypes={edgeType}
              onDrop={onDrop}
              onInit={setReactFlowInstance}
              onNodeDragStop={onNodeDragStop}
              onNodeDragStart={(e, node) => setXyStary(node.position)}
              fitView={false}
              nodesConnectable={connection}
              multiSelectionKeyCode={''}
              nodesDraggable={dragable}
              nodeTypes={nodeTypes}
              ref={reactFlowWrapper}>
              <MiniMap maskColor="rgb(193, 193, 193, 0.3)" zoomable pannable ariaLabel="Mapa" />
            </ReactFlow>
          </div>
        </div>
      )}
    </>
  )
}

export default Flow
