import Editor from '@monaco-editor/react';
import { AxiosError, HttpStatusCode } from 'axios';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { OverlayPanel } from 'primereact/overlaypanel';
import { TabPanel, TabView } from 'primereact/tabview';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import { AiOutlineApi } from 'react-icons/ai';
import { FaRegTrashAlt } from 'react-icons/fa';
import { RiArrowRightLine, RiEye2Line } from 'react-icons/ri';
import { z } from 'zod';
import { IGetIntegrationLogsResponseProps } from '../../../../@types/services/integration-logs';
import { axiosInstance } from '../../../../data/Https/axios-instance';
import { getIntegrationLogs } from '../../../../services/integration-logs';
import { useCustomizeFieldsStore } from '../../../../store/customizeFields';
import useStore from '../../../../store/store';
import { formatUseIn, handleFormatField } from '../../../../utils/format';
import { itemTemplate } from '../../../../utils/templates';
import styles from './styles.module.css';

type IHeadersOptionsProps = {
  key: string
  value: string
}

type IFieldsProps = {
  headers: IHeadersOptionsProps[]
  method: string
  url: string
  body: string
  mapperResponse: IHeadersOptionsProps[]
}

const schema = z.object({
  method: z.string().nonempty({
    message: 'Campo obrigatório',
  }),
  url: z
    .string()
    .refine((value) => {
      const pattern = new RegExp(
        '^(https?:\\/\\/)' + // protocol
        '((' +
        '([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3})|' + // OR ip (v4) address
        '(\\{\\{[a-zA-Z\\d_]+\\}\\})' + // OR {{campodinamico}}
        ')' +
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+\\{\\}\\[\\]]*)*' + // port and path with {{campodinamico}}
        '(\\?[:;&a-z\\d%_.~+=-\\{\\}\\[\\]]*)?' + // query string with {{campodinamico}}
        '(\\#[-a-z\\d_\\{\\}\\[\\]]*)?$', // fragment with {{campodinamico}}
        'i'
      );
      return pattern.test(value)
    }, 'Formato de URL inválido')
    .refine((url) => {
      return url.startsWith('https://')
    }, 'A URL deve ser HTTPS'),
  headers: z.array(
    z.object({
      key: z
        .string({
          required_error: 'Campo obrigatório',
        })
        .nonempty({
          message: 'Campo obrigatório',
        }),
      value: z
        .string({
          required_error: 'Campo obrigatório',
        })
        .nonempty({
          message: 'Campo obrigatório',
        }),
    })
  ),
  mapperResponse: z.array(
    z.object({
      key: z
        .string({
          required_error: 'Campo obrigatório',
        })
        .nonempty({
          message: 'Campo obrigatório',
        }),
      value: z
        .string({
          required_error: 'Campo obrigatório',
        })
        .nonempty({
          message: 'Campo obrigatório',
        }),
    })
  ),
})

export default function ModalIntegration({
  isOpen,
  onClose,
  id,
  data,
}: {
  isOpen: boolean
  onClose: () => void
  id: string
  data: any
}) {
  const toast = useRef<Toast>(null)
  const op = useRef<OverlayPanel>(null)
  const errorResponse = useRef<OverlayPanel>(null)

  const { optionsField, optionsFieldFormatted, onToggleModalCustomizeField } = useCustomizeFieldsStore((state) => ({
    optionsField: state.optionsField,
    optionsFieldFormatted: state.optionsFieldFormatted,
    onToggleModalCustomizeField: state.onToggleModalCustomizeField,
  }))

  const OPTIONS_METHODS = [
    {
      value: 'GET',
      id: 'GET',
    },
    {
      value: 'POST',
      id: 'POST',
    },
    {
      value: 'PUT',
      id: 'PUT',
    },
    {
      value: 'DELETE',
      id: 'DELETE',
    },
    {
      value: 'PATCH',
      id: 'PATCH',
    },
  ]

  const updateNodeData = useStore((state) => state.updateNodeData)
  const flowId = useStore((state) => state.flowId)
  const [loading, setLoading] = useState(false);

  const [integrationLogs, setIntegrationLogs] = useState<IGetIntegrationLogsResponseProps[]>([])
  const [fields, setFields] = useState<IFieldsProps>({
    headers: [] as IHeadersOptionsProps[],
    mapperResponse: [] as IHeadersOptionsProps[],
    method: '',
    url: '',
    body: '',
  })

  const [errorsFields, setErrorsFields] = useState({
    headers: [] as any,
    method: '',
    url: '',
  })

  const [errorsHeaders, setErrorsHeaders] = useState<any>([])
  const [errorsMapperResponse, setErrorsMapperResponse] = useState<any>([])

  const [response, setResponse] = useState('')
  const [responseStatus, setResponseStatus] = useState<number | null>(null)

  const [logError, setLogError] = useState<IGetIntegrationLogsResponseProps>()

  const [activeIndexTabView, setActiveIndexTabView] = useState(0)
  const [errorsBody, setErrorsBody] = useState<any>([])

  const [filteredOptions, setFilteredOptions] = useState(optionsField)

  useEffect(() => {
    if (data) {
      const customHeaders = data.headers
        ? data?.headers?.map((obj: any) => {
          const key = Object.keys(obj)[0]
          const value = Object.values(obj)[0]
          return { key, value }
        })
        : []

      setFields({
        headers: customHeaders,
        method: data.method,
        url: data.url,
        body: data.body,
        mapperResponse: data.mapperResponse ?? [],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchIntegrationLogs = async () => {
    try {
      const data = await getIntegrationLogs({
        flowId: flowId,
        nodeId: id,
      })

      data.forEach(d => {
        let dataLocal = converterParaHorarioLocal(d.createdAt);
        d.createdAt = dataLocal
      })
      data.sort((a, b) => {
        return b.createdAt.localeCompare(a.createdAt);
      });
      setIntegrationLogs(data)
    } catch (error) {
      console.log(error)
    }
  }

  const headerErrorsLog = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold">Logs</span>
      <Button icon="pi pi-refresh" onClick={fetchIntegrationLogs}
        tooltip="Atualizar logs" tooltipOptions={{ position: 'left' }}
      />
    </div>
  );

  function converterParaHorarioLocal(dataISO: string) {
    let data = new Date(dataISO);

    let dia = ('0' + data.getDate()).slice(-2);
    let mes = ('0' + (data.getMonth() + 1)).slice(-2); // getMonth() retorna 0-11
    let ano = data.getFullYear();
    let horas = ('0' + data.getHours()).slice(-2);
    let minutos = ('0' + data.getMinutes()).slice(-2);
    let segundos = ('0' + data.getSeconds()).slice(-2);

    return `${dia}/${mes}/${ano} ${horas}:${minutos}:${segundos}`;
  }

  useEffect(() => {
    fetchIntegrationLogs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTestClick = () => {
    setLoading(true);
    try {
      schema.parse(fields)
      const customHeaders = fields.headers.map((obj) => {
        const { key, value } = obj
        return { [key]: value }
      })

      const headers = customHeaders.reduce((acc, cur) => {
        const [key, value] = Object.entries(cur)[0]
        acc[key] = value
        return acc
      }, {})

      const requestBody = ['POST', 'PUT', 'PATCH', 'GET'].includes(fields.method) ? fields.body : undefined

      axiosInstance.post(`/nodes/${id}/integration-test`, {
        method: fields.method,
        url: fields.url,
        headers: headers,
        body: requestBody
      }).then((response) => {
        setResponseStatus(response.status);
        setResponse(response.data)
        return response
      }).catch((error: AxiosError) => {
        if (error?.response) {
          setResponseStatus(error.response.status);
          setResponse(error.response.data as string)
        } else {
          setResponse(error.message)
          if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
            setResponseStatus(HttpStatusCode.RequestTimeout);
          }
        }
      }).finally(() => {
        setLoading(false);
        setActiveIndexTabView(2)
      })
    } catch (error: any) {
      setLoading(false);
      if (error.formErrors.fieldErrors) {
        setErrorsFields(error.formErrors.fieldErrors)
        setErrorsHeaders(error.issues.filter((err: any) => err.path[0] === 'headers'))
      }
    }
  }

  const handleSubmit = async () => {
    try {
      if (errorsBody.length > 0) {
        toast?.current?.show({
          severity: 'error',
          summary: 'Ops! Algo deu errado.',
          detail: 'Verifique a sintaxe do seu body',
          life: 3000,
        })
        return
      }

      schema.parse(fields)
      const customHeaders = fields.headers.map((obj) => {
        const { key, value } = obj
        return { [key]: value }
      })

      const nodeData = {
        url: fields.url,
        method: fields.method,
        headers: customHeaders,
        body: fields.body,
        mapperResponse: fields.mapperResponse,
      }
      updateNodeData(id, nodeData)

      onClose()
    } catch (error: any) {
      if (error.formErrors.fieldErrors) {
        setErrorsFields(error.formErrors.fieldErrors)
        setErrorsHeaders(error.issues.filter((err: any) => err.path[0] === 'headers'))
        setErrorsMapperResponse(error.issues.filter((err: any) => err.path[0] === 'mapperResponse'))
      }
    }
  }

  const header = () => {
    return (
      <>
        <div className="flex align-items-center">
          <div className={`w-2rem h-2rem flex justify-content-center align-items-center border-circle bg-yellow-400 `}>
            <AiOutlineApi size={20} color="white" />
          </div>
          <span className="text-600 text-base ml-2">Requisição</span>
        </div>
        <Divider />
      </>
    )
  }

  const handleFilter = (e: string) => {
    const value = e.toLocaleLowerCase()

    const filtered = optionsField.filter(
      (filter) => filter.value.toLocaleLowerCase().includes(value) || filter.id.toLocaleLowerCase().includes(value)
    )

    setFilteredOptions(filtered)
  }

  const groupedItemTemplate = (option: any) => {
    return (
      <>
        {option.label === 'add-new-field' ? (
          <div
            className="flex align-items-center cursor-pointer font-normal"
            onClick={() => onToggleModalCustomizeField(true)}>
            <span className="text-blue-500">+ Adicionar novo campo</span>
          </div>
        ) : (
          <div className="flex align-items-center">
            <div>{formatUseIn(option.label)}</div>
          </div>
        )}
      </>
    )
  }

  const bodyLogError = (log: IGetIntegrationLogsResponseProps) => {
    return <>
      <div
        onClick={(e) => {
          errorResponse.current?.toggle(e)
          setLogError(log);
        }}
        className="inline-flex align-items-center gap-2 text-blue-500 cursor-pointer">
        <RiEye2Line />
      </div>
    </>;
  };

  return (
    <Dialog
      className={styles.mypanel}
      header={header}
      onHide={onClose}
      visible={isOpen}
      style={{ width: '900px' }}
      draggable={false}>
      <OverlayPanel ref={op}>
        <p className="font-bold">Utilize campos personalizados</p>

        <p className="mt-2 text-gray-600">Para utilizar os campos personalizados adicione a chave:</p>
        <p className="mt-1 mb-3 text-gray-600">Ex: {'{{contact.name}}'}</p>

        <span className="p-input-icon-left mb-3 w-full">
          <i className="pi pi-search" />
          <InputText
            className="w-full"
            placeholder="Pesquise um campo"
            onChange={(e) => handleFilter(e.target.value)}
          />
        </span>

        <DataTable value={filteredOptions} scrollable scrollHeight="230px" emptyMessage="Nenhum resultado encontrado">
          <Column field="value" header="Nome"></Column>
          <Column field="id" header="Chave"></Column>
        </DataTable>
      </OverlayPanel>
      <OverlayPanel ref={errorResponse}>
        <div>
          <b>Headers</b>
          <ul>
            {logError?.requestOptions?.headers &&
              logError?.requestOptions?.headers?.map((header: { [key: string]: string }, index: number) => (
                <li key={index}>
                  <strong>{Object.keys(header)[0]}:</strong> {header[Object.keys(header)[0]]}
                </li>
              ))}
          </ul>
        </div>

        <b>Resposta</b>
        <div className="border-1 border-300">
          <Editor
            height="350px"
            width="500px"
            language="json"
            value={logError?.responseError}
            onChange={() => null}
            options={{
              selectOnLineNumbers: true,
              minimap: {
                enabled: false,
              },
              autoIndent: 'full',
              readOnly: true,
              scrollbar: {
                horizontalSliderSize: 4,
                verticalSliderSize: 6,
              },
            }}
          />
        </div>
      </OverlayPanel>
      <Toast ref={toast} />
      <div className="flex gap-4 mb-4">
        <div className="w-3 relative">
          <div className="mb-1">
            <span className="text-small">Tipo</span>
          </div>
          <Dropdown
            options={OPTIONS_METHODS}
            className={`w-10rem ${errorsFields.method ? 'p-invalid' : ''}`}
            optionLabel="value"
            optionValue="id"
            value={fields.method}
            onChange={(e) => setFields({ ...fields, method: e.value })}
          />
          {errorsFields.method && <small className="p-error absolute top-100 left-0">{errorsFields.method}</small>}
        </div>
        <div className="w-full relative">
          <div className="mb-1">
            <span className="text-small">URL (Somente HTTPS)</span>
          </div>
          <InputText
            className={`w-full ${errorsFields.url ? 'p-invalid' : ''}`}
            onChange={(e) => setFields({ ...fields, url: e.target.value })}
            value={fields.url}
          />
          {errorsFields.url && <small className="p-error absolute top-100 left-0">{errorsFields.url}</small>}
        </div>
        <div className="w-3 flex align-items-end">
          <Button label="Testar" className="h-3rem" loading={loading} onClick={handleTestClick} />
        </div>
      </div>
      <TabView
        className="custom-tabview"
        activeIndex={activeIndexTabView}
        onTabChange={(e) => setActiveIndexTabView(e.index)}>
        <TabPanel header="Headers">
          {fields.headers.map((header, index) => {
            const keyError =
              errorsHeaders && errorsHeaders.find((err: any) => err.path[1] === index && err.path[2] === 'key')
            const valueError =
              errorsHeaders && errorsHeaders.find((err: any) => err.path[1] === index && err.path[2] === 'value')

            return (
              <div className="mb-4 flex gap-4" key={index}>
                <div className="w-full relative">
                  <div className="mb-1">
                    <span className="text-small">Chave</span>
                  </div>
                  <InputText
                    className={`w-full ${keyError ? 'p-invalid' : ''}`}
                    value={header.key}
                    onChange={(value) => {
                      const newHeaders = [...fields.headers]
                      newHeaders[index].key = value.target.value
                      setFields({ ...fields, headers: newHeaders })
                    }}
                  />
                  {keyError && <small className="text-red-500 absolute top-100 left-0">{keyError.message}</small>}
                </div>
                <div className="w-full relative">
                  <div className="mb-1">
                    <span className="text-small">Valor</span>
                  </div>
                  <InputText
                    className={`w-full ${valueError ? 'p-invalid' : ''}`}
                    value={header.value}
                    onChange={(value) => {
                      const newHeaders = [...fields.headers]
                      newHeaders[index].value = value.target.value
                      setFields({ ...fields, headers: newHeaders })
                    }}
                  />
                  {valueError && <small className="text-red-500 absolute top-100 left-0">{valueError.message}</small>}
                </div>
                <div
                  className="w-1 mb-3 flex align-items-end cursor-pointer"
                  onClick={() => {
                    const newHeaders = [...fields.headers]
                    newHeaders.splice(index, 1)
                    setFields({
                      ...fields,
                      headers: newHeaders,
                    })
                  }}>
                  <FaRegTrashAlt size={18} color={'red'} />
                </div>
              </div>
            )
          })}
          <div
            className="cursor-pointer text-blue-400"
            onClick={() => {
              setFields({
                ...fields,
                headers: [...fields.headers, {} as IHeadersOptionsProps],
              })
            }}>
            + Adicionar campo
          </div>
        </TabPanel>
        <TabPanel header="Body" disabled={fields.method === 'DELETE'}>
          <div
            onClick={(e) => op.current?.toggle(e)}
            className="mb-3 inline-flex align-items-center gap-2 text-blue-500 cursor-pointer">
            <RiEye2Line />
            Como utilizar campos personalizados
          </div>
          <div className={`border-1 border-300`}>
            <Editor
              height="300px"
              language="json"
              options={{
                selectOnLineNumbers: true,
                minimap: {
                  enabled: false,
                },
                scrollbar: {
                  horizontalSliderSize: 4,
                  verticalSliderSize: 6,
                },
              }}
              value={fields.body}
              onValidate={(e) => setErrorsBody(e)}
              onChange={(value) => setFields({ ...fields, body: value || '' })}
            />
          </div>
        </TabPanel>
        <TabPanel header="Resposta">
          <div className="border-1 border-300">
            <Editor
              height="300px"
              language="json"
              value={JSON.stringify(response, null, 2)}
              onChange={() => null}
              options={{
                selectOnLineNumbers: true,
                minimap: {
                  enabled: false,
                },
                autoIndent: 'full',
                readOnly: true,
                scrollbar: {
                  horizontalSliderSize: 4,
                  verticalSliderSize: 6,
                },
              }}
            />
          </div>
          {responseStatus && <div className="mt-2">Status da resposta: {responseStatus}</div>}
        </TabPanel>
        <TabPanel header="Mapeamento da resposta">
          {fields.mapperResponse.map((mapper, index) => {
            const keyError =
              errorsMapperResponse &&
              errorsMapperResponse.find((err: any) => err.path[1] === index && err.path[2] === 'key')
            const valueError =
              errorsMapperResponse &&
              errorsMapperResponse.find((err: any) => err.path[1] === index && err.path[2] === 'value')

            return (
              <div className="mb-4 flex gap-4" key={index}>
                <div className="w-full relative">
                  <div className="mb-1">
                    <span className="text-small">JSONPath</span>
                  </div>
                  <InputText
                    className={`w-full ${keyError ? 'p-invalid' : ''}`}
                    value={mapper.key}
                    onChange={(value) => {
                      const response = [...fields.mapperResponse]
                      response[index].key = value.target.value
                      setFields({ ...fields, mapperResponse: response || '' })
                    }}
                  />
                  {keyError && <small className="text-red-500 absolute top-100 left-0">{keyError.message}</small>}
                </div>
                <div className="w-1 mb-3 flex align-items-end">
                  <RiArrowRightLine size={26} />
                </div>
                <div className="w-full relative">
                  <div className="mb-1">
                    <span className="text-small">Campo personalizado</span>
                  </div>
                  <Dropdown
                    onChange={(value) => {
                      const response = [...fields.mapperResponse]
                      response[index].value = value.target.value
                      setFields({ ...fields, mapperResponse: response })
                    }}
                    placeholder="Campos"
                    options={[
                      {
                        label: 'add-new-field',
                        code: 'add-new-field',
                        items: [],
                      },
                      ...optionsFieldFormatted({}),
                    ]}
                    itemTemplate={itemTemplate}
                    optionLabel="value"
                    optionValue="id"
                    optionGroupLabel="label"
                    optionGroupChildren="items"
                    optionGroupTemplate={groupedItemTemplate}
                    filter
                    className={`w-full ${valueError ? 'p-invalid' : ''}`}
                    value={mapper.value}
                  />
                  {valueError && <small className="text-red-500 absolute top-100 left-0">{valueError.message}</small>}
                  {!valueError && mapper.value && (
                    <small className="absolute top-100 left-0">
                      A resposta deverá estar no formato: {handleFormatField(mapper.value)}
                    </small>
                  )}
                </div>
                <div
                  className="w-1 mb-3 flex align-items-end cursor-pointer"
                  onClick={() => {
                    const response = [...fields.mapperResponse]
                    response.splice(index, 1)
                    setFields({
                      ...fields,
                      mapperResponse: response,
                    })
                  }}>
                  <FaRegTrashAlt size={18} color={'red'} />
                </div>
              </div>
            )
          })}
          <div
            className="cursor-pointer text-blue-400"
            onClick={() => {
              setFields({
                ...fields,
                mapperResponse: [...fields.mapperResponse, {} as IHeadersOptionsProps],
              })
            }}>
            + Adicionar campo
          </div>
        </TabPanel>
        <TabPanel header="Logs de erro">
          <DataTable value={integrationLogs} header={headerErrorsLog} scrollable stripedRows scrollHeight="300px" emptyMessage="Nenhum resultado encontrado">
            <Column field="createdAt" header="Data" style={{ minWidth: '12rem' }} />
            <Column field="statusCode" header="Status" style={{ minWidth: '5rem' }} />
            <Column field="requestOptions.method" header="Método" style={{ minWidth: '5rem' }} />
            <Column field="requestOptions.url" header="URL" style={{ minWidth: '30rem' }} />
            <Column field="" style={{ minWidth: '5rem' }}
              header='Resposta'
              align={'center'}
              body={bodyLogError}
            />
          </DataTable>
        </TabPanel>
      </TabView>

      <Button label="Salvar" className="p-button-help w-full mt-5" onClick={handleSubmit} />
    </Dialog>
  )
}
