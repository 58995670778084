import { axiosInstance } from '../Https/axios-instance'

class EdgeService {
  async get(flowId: string) {
    return await axiosInstance.get('/edges', { headers: { flowid: flowId } })
  }

  async create(target: string, source: string, flowId: string, sourceHandle: string | null = null) {
    return await axiosInstance.post(
      '/edges',
      {
        target,
        source,
        sourceHandle,
      },
      {
        headers: {
          flowid: `${flowId}`,
        },
      }
    )
  }
  async delete(edgeId: string, flowId: string) {
    return await axiosInstance.delete(`/edges/${edgeId}`, { headers: { flowid: `${flowId}` } })
  }
}

export const edgeService = new EdgeService()
