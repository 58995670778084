import { IOptionsCustomizeFieldsProps, useCustomizeFieldsStore } from '../store/customizeFields'
import { dateMask, timeMask } from './mask'

export function formatUseIn(value: string) {
  switch (value) {
    case 'CONTACT':
      return 'Campos do contato'
    case 'FLOW':
      return 'Campos da automação'
    case 'OTHERS':
      return 'Outros'
  }
}

export function formatDayAbbreviations(value: string) {
  switch (value) {
    case '0':
      return 'Dom'
    case '1':
      return 'Seg'
    case '2':
      return 'Ter'
    case '3':
      return 'Qua'
    case '4':
      return 'Qui'
    case '5':
      return 'Sex'
    case '6':
      return 'Sáb'
    default:
      return value
  }
}

export const handleFormatField = (field: string) => {
  const format = useCustomizeFieldsStore
    .getState()
    .optionsField.find((option) => option.id?.toUpperCase() === field?.toUpperCase())
  switch (format?.type?.toUpperCase()) {
    case 'STRING':
      return 'Texto'
    case 'DATE':
      return 'DD/MM/YYYY (dia/mês/ano)'
    case 'NUMBER':
      return 'numérico'
    case 'BOOLEAN':
      return 'sim/não'
    case 'EMAIL':
      return 'e-mail'
    case 'PHONE':
      return '11999999999'
    case 'MONETARY':
      return 'monetário (1.000,00)'
    case 'MASK':
      return 'da máscara (##-## -> 12-12)'
    case 'TIME':
      return 'HH:MM (hora e minuto)'
  }
}

export const handleFormatConditionType = (value: string) => {
  switch (value) {
    case 'or':
      return 'ou'
    case 'and':
      return 'e'
  }
}

export const handleGetMaskByType = (value: string, field: string, optionsField: IOptionsCustomizeFieldsProps[]) => {
  const customFieldType = optionsField.find((item) => item.id === field)

  switch (customFieldType?.type?.toUpperCase()) {
    case 'DATE':
      return dateMask(value)
    case 'TIME':
      return timeMask(value)
    default:
      return value
  }
}
