import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { Dialog } from 'primereact/dialog'
import { Divider } from 'primereact/divider'
import { Dropdown } from 'primereact/dropdown'
import { InputNumber } from 'primereact/inputnumber'
import { MultiSelect } from 'primereact/multiselect'
import { useEffect, useState } from 'react'
import { FaHourglassEnd } from 'react-icons/fa'
import { z } from 'zod'
import useStore from '../../../../store/store'
import { OPTIONS_DAYS, OPTIONS_HOURS } from '../../../../utils/constants'
import { convertTimeUnit, reConvertTimeUnit } from '../../../../utils/convertStrings'

const schema = z
  .object({
    hourInitial: z.string().regex(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/),
    hourFinal: z.string().regex(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/),
  })
  .refine(
    (data) => {
      // Validar se a hora final é maior que a hora inicial
      const hourInitial = data.hourInitial.split(':')
      const hourFinal = data.hourFinal.split(':')
      const initialMinutes = parseInt(hourInitial[0]) * 60 + parseInt(hourInitial[1])
      const finalMinutes = parseInt(hourFinal[0]) * 60 + parseInt(hourFinal[1])
      return finalMinutes > initialMinutes
    },
    {
      message: 'A hora final deve ser maior que a hora inicial.',
    }
  )

const schemaOthers = z.object({
  waitingDuration: z.coerce.number().min(1, { message: 'Campo obrigatório' }),
  waitingDurationType: z.string().nonempty({ message: 'Campo obrigatório' }),
})

export function ModalSmartDelay({
  isOpen,
  onClose,
  id,
  data,
}: {
  isOpen: boolean
  onClose: () => void
  id: string
  data: any
}) {
  const updateNodeData = useStore((state) => state.updateNodeData)

  const [checkedLimit, setCheckedLimit] = useState(false)

  const [fields, setFields] = useState<{
    waitingDuration: number | null
    waitingDurationType: string
    hourInitial: string
    hourFinal: string
    daysRepeat: string[]
  }>({
    waitingDuration: null,
    waitingDurationType: '',
    hourInitial: '00:00',
    hourFinal: '23:00',
    daysRepeat: OPTIONS_DAYS.map((option) => option.id),
  })

  const [errorsFields, setErrorsFields] = useState({
    hour: '',
  })

  const [errorsFieldsDurations, setErrorsFieldsDurations] = useState({
    waitingDuration: '',
    waitingDurationType: '',
  })

  useEffect(() => {
    if (data.waitingDuration) {
      setFields({
        daysRepeat: data.daysRepeat,
        hourFinal: data.hourFinal,
        hourInitial: data.hourInitial,
        waitingDuration: data.waitingDuration,
        waitingDurationType: reConvertTimeUnit(data.waitingDurationType),
      })

      if (!!data.hourInitial && !!data.hourFinal) {
        setCheckedLimit(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const handleSubmit = () => {
    try {
      if (checkedLimit) {
        schema.parse({
          hourInitial: fields.hourInitial,
          hourFinal: fields.hourFinal,
        })
      }

      schemaOthers.parse({
        waitingDuration: fields.waitingDuration,
        waitingDurationType: fields.waitingDurationType,
      })

      const nodeData = {
        ...(checkedLimit
          ? {
              hourInitial: fields.hourInitial,
              hourFinal: fields.hourFinal,
              daysRepeat: fields.daysRepeat,
            }
          : {
              hourInitial: null,
              hourFinal: null,
              daysRepeat: null,
            }),
        waitingDuration: fields.waitingDuration,
        waitingDurationType: convertTimeUnit(fields.waitingDurationType),
      }

      updateNodeData(id, nodeData)

      setErrorsFields({
        hour: '',
      })
      onClose()
    } catch (error: any) {
      if (error.formErrors.fieldErrors) {
        setErrorsFields({
          hour: error.formErrors.formErrors[0],
        })

        setErrorsFieldsDurations(error.formErrors.fieldErrors)
      }
    }
  }

  const header = () => {
    return (
      <>
        <div className="flex align-items-center">
          <div className={`w-2rem h-2rem flex justify-content-center align-items-center border-circle bg-yellow-400 `}>
            <FaHourglassEnd color="white" />
          </div>
          <span className="text-600 text-base ml-2">Atraso inteligente</span>
        </div>
        <Divider />
      </>
    )
  }

  return (
    <Dialog header={header} onHide={onClose} visible={isOpen} style={{ width: '500px' }} draggable={false}>
      <div>
        <div className="font-bold mb-2">Duração:</div>
        <div className="flex gap-2">
          <div className="relative">
            <InputNumber
              useGrouping={false}
              className={`w-full ${errorsFieldsDurations.waitingDuration ? 'p-invalid' : ''}`}
              value={fields.waitingDuration}
              onValueChange={(e) => setFields({ ...fields, waitingDuration: e.value || null })}
            />
            {errorsFieldsDurations.waitingDuration && (
              <small className="p-error absolute top-100 left-0">{errorsFieldsDurations.waitingDuration}</small>
            )}
          </div>

          <div className="relative w-full">
            <Dropdown
              className={`w-full ${errorsFieldsDurations.waitingDurationType ? 'p-invalid' : ''}`}
              options={['Segundo(s)', 'Minuto(s)', 'Hora(s)', 'Dia(s)']}
              value={fields.waitingDurationType}
              onChange={(e) => setFields({ ...fields, waitingDurationType: e.value || null })}
            />
            {errorsFieldsDurations.waitingDurationType && (
              <small className="p-error absolute top-100 left-0">{errorsFieldsDurations.waitingDurationType}</small>
            )}
          </div>
        </div>
        <div className="mb-2 mt-4 flex align-items-center">
          <Checkbox checked={checkedLimit} onChange={(e) => setCheckedLimit(e.checked || false)} />
          <div className="font-bold ml-2">Definir tempo limite de continuação</div>
        </div>

        {checkedLimit && (
          <>
            <div className="flex gap-2 align-items-center mt-2 relative">
              <Dropdown
                className={`w-full ${errorsFields.hour ? 'p-invalid' : ''}`}
                placeholder="Inicia em"
                options={OPTIONS_HOURS}
                value={fields.hourInitial}
                onChange={(e) => setFields({ ...fields, hourInitial: e.value })}
              />
              -
              <Dropdown
                className={`w-full ${errorsFields.hour ? 'p-invalid' : ''}`}
                placeholder="Termina em"
                options={OPTIONS_HOURS}
                value={fields.hourFinal}
                onChange={(e) => setFields({ ...fields, hourFinal: e.value })}
              />
              {errorsFields.hour && <small className="p-error absolute top-100 left-0">{errorsFields.hour}</small>}
            </div>

            <MultiSelect
              className="w-full mt-4"
              options={OPTIONS_DAYS}
              placeholder="Selecione um ou mais dias"
              optionLabel="value"
              optionValue="id"
              lang="pt-BR"
              maxSelectedLabels={6}
              value={fields.daysRepeat}
              onChange={(e) => {
                const newValue = e.value.sort((a: number, b: number) => a - b)

                setFields({ ...fields, daysRepeat: newValue })
              }}
              selectedItemsLabel="Qualquer dia"
            />
          </>
        )}
        <Button label="Salvar" className="p-button-help w-full mt-4" onClick={handleSubmit} />
      </div>
    </Dialog>
  )
}
