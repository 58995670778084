import { memo, useEffect, useState } from 'react'
import { Handle, NodeProps, Position } from 'reactflow'
import { AiOutlineSound } from 'react-icons/ai'
import { BsKeyboard } from 'react-icons/bs'
import useStore from './../../store/store'
import { ProgressBar } from 'primereact/progressbar'
import { convertTimeUnit, reConvertTimeUnit } from '../../utils/convertStrings'
import { OptionsHover } from '../../components/OptionsHover'
import ModalFile from '../../components/ModalFile'

const AudioNode = ({ id, data, isConnectable }: NodeProps) => {
  const [hover, setHover] = useState('hidden')
  const [title, setTitle] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [audio, setAudio] = useState<string>()
  const [hiddenModal, setHiddenModal] = useState(false)
  const [optionSelect, setOptionsSelect] = useState<any>(0)
  const [file, setFile] = useState<File>()
  const [oldBollean, setOldBollean] = useState(false)
  const [bollean, setBollean] = useState(false)
  const [textTime, setTextTime] = useState('')
  const [load, setLoad] = useState(false)
  const [count, setCount] = useState<number>(0)

  const updateNodeData = useStore((state) => state.updateNodeData)
  const uploadFile = useStore((state) => state.uploadFile)

  const upload = (event: any) => {
    const progress: number = Math.round((event.loaded * 100) / event.total)
    setCount(progress)
  }

  useEffect(() => {
    if (!hiddenModal) return
    setHiddenModal(false)
    if (audio === undefined) return
    if (
      audio === data.mediaUrl &&
      optionSelect === data.typingDuration &&
      textTime === reConvertTimeUnit(data.typingDurationType) &&
      oldBollean === bollean
    )
      return
    if (file) {
      if (audio === data.mediaUrl) {
        if (bollean) {
          const nodeData = {
            mediaUrl: audio,
            typingDuration: optionSelect,
            typingDurationType: convertTimeUnit(textTime),
          }
          updateNodeData(id, nodeData)
        } else {
          if (data.typingDuration === 0 && data.typingDurationType === 'SECONDS') return
          const nodeData = {
            mediaUrl: audio,
            typingDuration: 0,
            typingDurationType: 'SECONDS',
          }
          updateNodeData(id, nodeData)
        }
      } else {
        const formData = new FormData()
        formData.append('file', file)
        setLoad(true)
        uploadFile(id, formData, upload).then((res) => {
          if (optionSelect !== data.typingDurationType) {
            if (bollean) {
              const nodeData = {
                mediaUrl: res.data.data.mediaUrl,
                typingDuration: optionSelect,
                typingDurationType: convertTimeUnit(textTime),
              }
              updateNodeData(id, nodeData)
            } else {
              if (data.typingDuration === 0 && data.typingDurationType === 'SECONDS') return
              const nodeData = {
                mediaUrl: res.data.data.mediaUrl,
                typingDuration: 0,
                typingDurationType: 'SECONDS',
              }
              updateNodeData(id, nodeData)
              setOptionsSelect(0)
              setTextTime(reConvertTimeUnit('SECONDS'))
            }
          }
          setLoad(false)
          setCount(0)
        })
      }
    } else {
      if (bollean) {
        const nodeData = {
          mediaUrl: audio,
          typingDuration: optionSelect,
          typingDurationType: convertTimeUnit(textTime),
        }
        updateNodeData(id, nodeData)
      } else {
        const nodeData = {
          mediaUrl: audio,
          typingDuration: 0,
          typingDurationType: 'SECONDS',
        }
        updateNodeData(id, nodeData)
        setOptionsSelect(0)
        setTextTime(reConvertTimeUnit('SECONDS'))
      }
      setOldBollean(bollean)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hiddenModal])

  useEffect(() => {
    if (data === undefined) return
    if (data.mediaUrl) setAudio(data.mediaUrl)
    if (data.typingDuration) {
      setBollean(true)
      setOldBollean(true)
      setOptionsSelect(data.typingDuration)
      setTextTime(reConvertTimeUnit(data.typingDurationType))
    }
  }, [data])

  useEffect(() => {
    const handler = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      event.returnValue = ''
    }

    if (load) {
      window.addEventListener('beforeunload', handler)

      return () => {
        window.removeEventListener('beforeunload', handler)
      }
    }
  }, [load])
  return (
    <div onMouseEnter={() => setHover('flex')} onMouseLeave={() => setHover('hidden')}>
      <OptionsHover id={id} className={hover} setModalOpen={setModalOpen} />
      <ModalFile
        hasDropdown={true}
        type="audio"
        fileFromNode={audio}
        setShowFile={setAudio}
        hasFileUpload={true}
        nodeId={id}
        visible={modalOpen}
        onHide={() => setModalOpen(false)}
        timeAnswerFromNode={bollean}
        setHidden={setHiddenModal}
        dropDownNumberFromNode={textTime}
        setNumberOption={setTextTime}
        timeFromNode={optionSelect}
        setInputNumber={setOptionsSelect}
        dropdownFromNode={optionSelect}
        setShowDoc={setFile}
        setAnswer={setBollean}
        text="Áudio"
        icon={<AiOutlineSound color="white" />}
        accept="audio/*"
      />
      <div
        className="border-round-lg w-18rem"
        style={{ border: '1px solid #1FCEC3', backgroundColor: '#FFF' }}
        onClick={() => setModalOpen(true)}>
        <div className="flex pl-4 pr-3 py-3 relative border-bottom-1" style={{ borderColor: '#9E9E9E' }}>
          <div>
            <div className="w-2rem h-2rem flex justify-content-center align-items-center border-circle bg-purple-300">
              <AiOutlineSound size={26} color="white" />
            </div>
            <div>
              <Handle
                type="target"
                position={Position.Left}
                id="a"
                style={{
                  background: '#FFF',
                  border: 'solid 1px #1FCEC3',
                  height: '16px',
                  width: '16px',
                  left: '-9px',
                }}
                isConnectable={isConnectable}
              />
            </div>
          </div>
          <div className="flex ml-3 flex-column align-items-start">
            <div className="">
              <input
                className="input-textNode-styled border-none w-9rem border-bottom-1 border-dashed font-bold h-1rem line-height-2 text-base vertical-align-middle"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Enviar áudio"
                name=""
                id=""
              />
            </div>
            <div className="font-normal text-sm text-purple-300">Áudio</div>
          </div>
        </div>
        {audio ? (
          <>
            <div className="flex justify-content-center py-2 cursor-pointer">
              <div
                className="bg-purple-300 flex flex-column align-items-start py-2 cursor-pointer w-15rem p-2 border-round-lg border-1 text-left border-purple-400"
                onClick={() => setModalOpen(true)}>
                <span className="w-full">
                  {load ? (
                    <div className="flex flex-column align-items-center">
                      <label>Fazendo Upload</label>
                      <ProgressBar style={{ width: '220px' }} value={count}></ProgressBar>
                    </div>
                  ) : (
                    <audio className="w-full" key={audio} controls>
                      <source src={audio}></source>
                    </audio>
                  )}
                </span>
              </div>
            </div>
            <div
              className="py-4 justify-content-between flex aling-items-center relative h-2rem border-top-1 border-round-bottom-lg "
              style={{ borderColor: '#E5E7EB', backgroundColor: '#FBFBFC' }}>
              {!bollean ? (
                <div></div>
              ) : (
                <div className="flex  justify-content-center align-items-center ml-1">
                  <BsKeyboard size={25} className="mb-3 mr-1" color="#C996FA" />
                  <div className="flex flex-column text-sm text-500">
                    <span>Gravando por</span>
                    <span>
                      <strong>{optionSelect}</strong> {textTime}
                    </span>
                  </div>
                </div>
              )}
              <div className="flex justify-content-center align-items-center">
                <span className="text-xs line-height-1 cursor-pointer mr-3">Próximo passo</span>
              </div>
              <Handle
                type="source"
                position={Position.Right}
                id="a"
                style={{
                  background: '#FFF',
                  border: 'solid 1px #1FCEC3',
                  height: '18px',
                  width: '18px',
                  right: '-9px',
                }}
                isConnectable={isConnectable}
              />
            </div>
          </>
        ) : (
          <>
            <div onClick={() => setModalOpen(true)} className="text-blue-400 my-3 cursor-pointer">
              Configurar etapa do fluxo
            </div>
            <Handle
              type="source"
              position={Position.Right}
              id="a"
              style={{
                background: '#FFF',
                border: 'solid 1px #1FCEC3',
                height: '18px',
                width: '18px',
                right: '-9px',
                visibility: 'hidden',
              }}
              isConnectable={isConnectable}
            />
          </>
        )}
      </div>
    </div>
  )
}

AudioNode.displayName = 'AudioNode'

export default memo(AudioNode)
