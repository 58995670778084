import { ICreateField, IGetFields } from '.'
import { axiosInstance } from '../../data/Https/axios-instance'

export const getFields: IGetFields = async () => {
  const { data } = await axiosInstance.get('/fields')

  return data
}

export const createField: ICreateField = async (params) => {
  const { status } = await axiosInstance.post('/fields', params)

  return {
    status,
  }
}
