import axios from 'axios';

export const axiosInstance = axios.create({
    withCredentials: true,
    // timeout: 10000,
    baseURL: `${process.env.REACT_APP_BASEURL}`,
    headers: {
        'Content-Type': 'application/json',
    }
})

axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error?.response?.status === 401) {
            if (getCookie('redirectLogin') === 'true' && window.top) {
                document.cookie = "redirectLogin=false; path=/;";
                parent.location.href = `${process.env.REACT_APP_MANYBOT_BASEURL}`;
            }
        }
        return Promise.reject(error);
    }
);

function getCookie(name: string) {
    let cookieArray = document.cookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
        let cookiePair = cookieArray[i].split('=');
        if (name == cookiePair[0].trim()) {
            return decodeURIComponent(cookiePair[1]);
        }
    }
    return null;
}

