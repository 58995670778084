import { RiContactsBookFill } from 'react-icons/ri'
import { Divider } from 'primereact/divider'
import { Dialog } from 'primereact/dialog'

import styles from './styles.module.css'
import { useEffect, useRef, useState } from 'react'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { COUNTRIES_DDI } from '../../../../utils/countriesDDI'
import { Toast } from 'primereact/toast'

import './flags.css'
import { justNumbers } from '../../../../utils/mask'
import { FaRegTrashAlt } from 'react-icons/fa'
import { Dropdown } from 'primereact/dropdown'
import useStore from '../../../../store/store'

export function ModalSendContacts({
  isOpen,
  onClose,
  id,
  data,
}: {
  isOpen: boolean
  onClose: () => void
  id: string
  data: any
}) {
  const defaultContact = {
    name: '',
    phone: {
      countryCode: 'BR',
      ddi: '+55',
      number: '',
    },
  }

  const toast = useRef<Toast>(null)
  const updateNodeData = useStore((state) => state.updateNodeData)

  const [contacts, setContacts] = useState<
    {
      name: string
      phone: {
        ddi: string
        countryCode: string
        number: string
      }
    }[]
  >([defaultContact])

  const header = () => {
    return (
      <>
        <div className="flex align-items-center">
          <div className={`w-2rem h-2rem flex justify-content-center align-items-center border-circle bg-yellow-400 `}>
            <RiContactsBookFill size={20} color="white" />
          </div>
          <span className="text-600 text-base ml-2">Enviar contato(s)</span>
        </div>
        <Divider />
      </>
    )
  }

  const handleSubmit = async () => {
    try {
      const isEmpty = contacts.some((contact) => {
        return !contact.name || !contact.phone.ddi || !contact.phone.countryCode || !contact.phone.number
      })

      if (isEmpty) {
        toast?.current?.show({
          severity: 'error',
          summary: 'Ops! Algo deu errado.',
          detail: 'Preencha todos os campos antes de enviar.',
          life: 3000,
        })
        return
      }

      await updateNodeData(id, {
        sendContacts: contacts,
      })

      onClose()
    } catch (error) {
      toast?.current?.show({
        severity: 'error',
        summary: 'Ops! Algo deu errado.',
        detail: 'Verifique as informações e tente novamente.',
        life: 3000,
      })
      console.log('error', error)
    }
  }

  useEffect(() => {
    if (data?.sendContacts?.length) {
      setContacts(data.sendContacts)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Dialog
      className={styles.mypanel}
      header={header}
      onHide={onClose}
      visible={isOpen}
      style={{ width: '500px' }}
      draggable={false}>
      <Toast ref={toast} />

      <label className="font-bold">Adicione vários contatos</label>

      <div className="">
        {contacts.map((contact, index) => (
          <div className="flex flex-column" key={index}>
            <div className="flex justify-content-between mt-4 mb-2">
              <label className="font-medium">Contato {index + 1}</label>
              <div
                className="ml-2 gap-2 flex align-items-center cursor-pointer"
                onClick={() => {
                  const newContacts = [...contacts]
                  newContacts.splice(index, 1)
                  setContacts(newContacts)
                }}>
                <FaRegTrashAlt size={18} color={'red'} />
              </div>
            </div>

            <InputText
              className="mb-2"
              placeholder="Nome do contato"
              value={contact.name}
              onChange={(e) => {
                const updatedContacts = [...contacts]
                updatedContacts[index].name = e.target.value

                setContacts(updatedContacts)
              }}
            />
            <div className="p-inputgroup w-full">
              <Dropdown
                style={{
                  width: 'auto',
                  background: '#ececec',
                }}
                options={COUNTRIES_DDI}
                filter
                optionLabel="name"
                optionValue="ddi"
                value={contact.phone.ddi}
                onChange={(e) => {
                  const filteredValue = COUNTRIES_DDI.find((item) => item.ddi === e.value)

                  const updatedContacts = [...contacts]
                  updatedContacts[index].phone.countryCode = filteredValue?.code || ''
                  updatedContacts[index].phone.ddi = filteredValue?.ddi || ''

                  setContacts(updatedContacts)
                }}
                valueTemplate={(e) => {
                  return (
                    <div>
                      <img
                        src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
                        className={`flag flag-${e.code.toLowerCase()}`}
                        style={{ width: '24px' }}
                      />
                    </div>
                  )
                }}
                itemTemplate={(e) => {
                  return (
                    <div className="flex align-items-center gap-2">
                      <img
                        src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
                        className={`flag flag-${e.code.toLowerCase()}`}
                        style={{ width: '24px' }}
                      />
                      <div>{`${e.ddi} ${e.name}`}</div>
                    </div>
                  )
                }}
              />

              <InputText
                type="text"
                className="w-full"
                placeholder="ex: 11912345678"
                value={contact.phone?.number || ''}
                onChange={(e) => {
                  const updatedContacts = [...contacts]
                  updatedContacts[index].phone.number = justNumbers(e.target.value)

                  setContacts(updatedContacts)
                }}
              />
            </div>
          </div>
        ))}

        <div
          className="text-blue-500 mt-4 cursor-pointer"
          onClick={() => {
            setContacts([...contacts, defaultContact])
          }}>
          + Adicionar novo contato
        </div>
      </div>

      <Button
        label="Salvar"
        className="p-button-help w-full mt-4"
        onClick={handleSubmit}
        disabled={contacts.length <= 0}
      />
    </Dialog>
  )
}
