import { Button } from 'primereact/button'
import { AiOutlineLock, AiOutlineUnlock } from 'react-icons/ai'
import { useReactFlow, useViewport } from 'reactflow'
import { useCallback, useState } from 'react'
import { MdOutlineViewInAr } from 'react-icons/md'
import { HiMagnifyingGlassMinus, HiMagnifyingGlassPlus } from 'react-icons/hi2'

export const ControlsCustom = ({
  setConnection,
  setDrag,
}: {
  setConnection: (connection: boolean) => void
  setDrag: (drag: boolean) => void
}) => {
  const [icon, setIcon] = useState(<AiOutlineLock color="black" size={22} />)
  const [lock, setLock] = useState(true)
  const { setViewport } = useReactFlow()
  const { x, y, zoom } = useViewport()
  const handleTransform = useCallback(() => {
    setViewport({ x: 454, y: 470, zoom: 0.8 }, { duration: 800 })
  }, [setViewport])

  const HandleZoomOut = useCallback(() => {
    if (zoom <= 0.2) return
    setViewport({ x, y, zoom: zoom - 0.1 }, { duration: 500 })
  }, [setViewport, x, y, zoom])

  const HandleZoomIn = useCallback(() => {
    if (zoom >= 1.5) return
    setViewport({ x, y, zoom: zoom + 0.1 }, { duration: 500 })
  }, [setViewport, x, y, zoom])

  const handleLock = () => {
    if (lock) {
      setConnection(false)
      setDrag(false)
      setIcon(<AiOutlineUnlock color="black" size={22} />)
      setLock(false)
    } else {
      setConnection(true)
      setDrag(true)
      setIcon(<AiOutlineLock color="black" size={22} />)
      setLock(true)
    }
  }

  return (
    <div>
      <div className="flex flex-column">
        <Button
          className="mb-2 w-2rem h-2rem bg-primary-reverse border-none shadow-2"
          onClick={() => HandleZoomIn()}
          icon={<HiMagnifyingGlassPlus color="black" size={22} />}
        />
        <Button
          className="mb-2 w-2rem h-2rem bg-primary-reverse border-none shadow-2"
          onClick={() => HandleZoomOut()}
          icon={<HiMagnifyingGlassMinus color="black" size={22} />}
        />
        <Button
          className="mb-2 w-2rem h-2rem bg-primary-reverse border-none shadow-2"
          onClick={handleTransform}
          icon={<MdOutlineViewInAr color="black" size={22} />}
        />
        <Button
          className="mb-2 w-2rem h-2rem bg-primary-reverse border-none shadow-2"
          onClick={handleLock}
          icon={icon}
        />
      </div>
    </div>
  )
}
