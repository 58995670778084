import { Button } from 'primereact/button'
import React, { useEffect, useRef, useState } from 'react'
import {
  AiFillVideoCamera,
  AiOutlineApi,
  AiOutlineFileText,
  AiOutlineSound,
  AiOutlineThunderbolt,
} from 'react-icons/ai'
import { BsFillChatLeftTextFill, BsImage, BsMenuButtonWide } from 'react-icons/bs'
import { FaHourglassEnd, FaPlus, FaRandom } from 'react-icons/fa'
import { RiContactsBookFill, RiQuestionnaireLine } from 'react-icons/ri'
import { RxSwitch } from 'react-icons/rx'
import { TbExchange } from 'react-icons/tb'

import styled from 'styled-components'

const RoundIcon = styled.span`
  width: 2.5rem;
  height: 2.5rem;
`

const NewDiv = styled.div`
  top: 150px;
  right: 90px;
`

export default function Menu() {
  const [display, setDisplay] = useState('hidden')

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: Event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setDisplay('hidden')
        }
      }
      document.addEventListener('click', handleClickOutside)
      return () => {
        document.removeEventListener('click', handleClickOutside)
      }
    }, [ref])
  }

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  const dragStart = (event: React.DragEvent<HTMLDivElement>, data: string) => {
    event.dataTransfer.setData('application/reactflow', data)
    event.dataTransfer.effectAllowed = 'move'
  }

  const handleClick = () => {
    if (display === 'hidden') {
      setDisplay('flex')
    } else {
      setDisplay('hidden')
    }
  }

  return (
    <>
      <div ref={wrapperRef}>
        <NewDiv className={`fixed z-1 border-1 w-28rem bg-white border-round border-teal-400 shadow-7 ${display}`}>
          <div className="flex flex-column">
            <div
              className={`flex flex-column my-2 align-items-start font-semibold text-xl text-gray-600`}
              style={{ marginLeft: '0.75rem' }}>
              Para adicionar o bloco, arraste e solte
            </div>
            <div className={`flex flex-column my-2 align-items-start`} style={{ marginLeft: '0.75rem' }}>
              <span className="font-bold text-700">Texto</span>
              <div className="flex">
                <div
                  className="w-5rem h-5rem border-1 mt-1 ml-1 border-teal-400 border-round flex flex-column align-items-center justify-content-center"
                  draggable
                  onDragEnd={() => setDisplay('hidden')}
                  onDragStart={(event) => {
                    dragStart(event, 'TEXT_MESSAGE')
                  }}>
                  <RoundIcon className="bg-teal-400 border-circle flex align-items-center align-content-center justify-content-center ">
                    <BsFillChatLeftTextFill size={20} color="white" />
                  </RoundIcon>
                  <span className="text-teal-400 mt-2 font-bold text-sm">Texto</span>
                </div>
                <div
                  className="w-5rem h-5rem border-1 mt-1 ml-1 border-teal-400 border-round flex flex-column align-items-center justify-content-center"
                  draggable
                  onDragEnd={() => setDisplay('hidden')}
                  onDragStart={(event) => {
                    dragStart(event, 'USER_INPUT')
                  }}>
                  <RoundIcon className="bg-teal-400 border-circle flex align-items-center align-content-center justify-content-center ">
                    <RiQuestionnaireLine size={28} color="white" />
                  </RoundIcon>
                  <span className="text-teal-400 mt-2 font-bold text-sm">Pergunta</span>
                </div>
                <div
                  className="w-5rem h-5rem border-1 mt-1 ml-1 border-teal-400 border-round flex flex-column align-items-center justify-content-center"
                  draggable
                  onDragEnd={() => setDisplay('hidden')}
                  onDragStart={(event) => {
                    dragStart(event, 'CONDITION')
                  }}>
                  <RoundIcon className="bg-teal-400 border-circle flex align-items-center align-content-center justify-content-center ">
                    <RxSwitch size={28} color="white" />
                  </RoundIcon>
                  <span className="text-teal-400 mt-2 font-bold text-sm">Condição</span>
                </div>
                <div
                  className="w-5rem h-5rem border-1 mt-1 ml-1 border-bluegray-400 border-round flex flex-column align-items-center justify-content-center"
                  draggable
                  onDragEnd={() => setDisplay('hidden')}
                  onDragStart={(event) => {
                    dragStart(event, 'BUTTONS')
                  }}>
                  <RoundIcon className="bg-bluegray-400 border-circle flex align-items-center align-content-center justify-content-center">
                    <BsMenuButtonWide size={25} color="white" />
                  </RoundIcon>
                  <span className="text-bluegray-400 mt-2 font-bold text-sm">Menu de opções</span>
                </div>
              </div>
              <span className="font-bold text-700 mt-3">Mídia</span>
              <div className="flex">
                <div
                  className="w-5rem h-5rem border-1 mt-1 ml-1 border-purple-400 border-round flex flex-column align-items-center justify-content-center"
                  draggable
                  onDragEnd={() => setDisplay('hidden')}
                  onDragStart={(event) => {
                    dragStart(event, 'VIDEO')
                  }}>
                  <RoundIcon className="bg-purple-400 border-circle flex align-items-center align-content-center justify-content-center">
                    <AiFillVideoCamera size={25} color="white" />
                  </RoundIcon>
                  <span className="text-purple-400 mt-2 font-bold text-sm">Video</span>
                </div>
                <div
                  className="w-5rem h-5rem border-1 mt-1 ml-1 border-purple-400 border-round flex flex-column align-items-center justify-content-center"
                  draggable
                  onDragEnd={() => setDisplay('hidden')}
                  onDragStart={(event) => {
                    dragStart(event, 'IMAGE')
                  }}>
                  <RoundIcon className="bg-purple-400 border-circle flex align-items-center align-content-center justify-content-center">
                    <BsImage size={25} color="white" />
                  </RoundIcon>
                  <span className="text-purple-400 mt-2 font-bold text-sm">Imagem</span>
                </div>
                {/* <div 
                                className="w-5rem h-5rem border-1 mt-1 ml-1 border-purple-400 border-round flex flex-column align-items-center justify-content-center" 
                                draggable 
                                onDragStart={(event)=>{dragStart(event,'StickNode')}} 
                            >
                                <RoundIcon className="bg-purple-400 border-circle flex align-items-center align-content-center justify-content-center" >
                                    <BsStickies size={25} color='white'/>
                                </RoundIcon>
                                <span className="text-purple-400 mt-2 font-bold text-sm">
                                    Sticker
                                </span>
                            </div>          */}
                <div
                  className="w-5rem h-5rem border-1 mt-1 ml-1 border-purple-400 border-round flex flex-column align-items-center justify-content-center"
                  draggable
                  onDragEnd={() => setDisplay('hidden')}
                  onDragStart={(event) => {
                    dragStart(event, 'AUDIO')
                  }}>
                  <RoundIcon className="bg-purple-400 border-circle flex align-items-center align-content-center justify-content-center">
                    <AiOutlineSound size={25} color="white" />
                  </RoundIcon>
                  <span className="text-purple-400 mt-2 font-bold text-sm text-sm">Áudio</span>
                </div>
                <div
                  className="w-5rem h-5rem border-1 mt-1 ml-1 border-purple-400 border-round flex flex-column align-items-center justify-content-center"
                  draggable
                  onDragEnd={() => setDisplay('hidden')}
                  onDragStart={(event) => {
                    dragStart(event, 'FILE')
                  }}>
                  <RoundIcon className="bg-purple-400 border-circle flex align-items-center align-content-center justify-content-center">
                    <AiOutlineFileText size={25} color="white" />
                  </RoundIcon>
                  <span className="text-purple-400 mt-2 font-bold text-sm">Documento</span>
                </div>
              </div>
              <span className="font-bold text-700 mt-3">Ações</span>
              <div className="flex">
                <div
                  className="w-5rem h-5rem border-1 mt-1 ml-1 border-yellow-400 border-round flex flex-column align-items-center justify-content-center"
                  draggable
                  onDragEnd={() => setDisplay('hidden')}
                  onDragStart={(event) => {
                    dragStart(event, 'ACTION')
                  }}>
                  <RoundIcon className="bg-yellow-400 border-circle flex align-items-center align-content-center justify-content-center">
                    <AiOutlineThunderbolt size={25} color="white" />
                  </RoundIcon>
                  <span className="text-bluegray-400 mt-1 font-bold text-sm">Ação</span>
                </div>
                <div
                  className="w-5rem h-5rem border-1 mt-1 ml-1 border-yellow-400 border-round flex flex-column align-items-center justify-content-center"
                  draggable
                  onDragEnd={() => setDisplay('hidden')}
                  onDragStart={(event) => {
                    dragStart(event, 'DELAY')
                  }}>
                  <RoundIcon className="bg-yellow-400 border-circle flex align-items-center align-content-center justify-content-center">
                    <FaHourglassEnd size={25} color="white" />
                  </RoundIcon>
                  <span className="text-bluegray-400 mt-1 font-bold text-sm">Atraso inteligente</span>
                </div>
                <div
                  className="w-5rem h-5rem border-1 mt-1 ml-1 border-yellow-400 border-round flex flex-column align-items-center justify-content-center"
                  draggable
                  onDragEnd={() => setDisplay('hidden')}
                  onDragStart={(event) => {
                    dragStart(event, 'MOVE_FLOW')
                  }}>
                  <RoundIcon className="bg-yellow-400 border-circle flex align-items-center align-content-center justify-content-center">
                    <TbExchange size={25} color="white" />
                  </RoundIcon>
                  <span className="text-bluegray-400 mt-1 font-bold text-sm">Mover Fluxo</span>
                </div>
                <div
                  className="w-5rem h-5rem border-1 mt-1 ml-1 border-yellow-400 border-round flex flex-column align-items-center justify-content-center"
                  draggable
                  onDragEnd={() => setDisplay('hidden')}
                  onDragStart={(event) => {
                    dragStart(event, 'INTEGRATION')
                  }}>
                  <RoundIcon className="bg-yellow-400 border-circle flex align-items-center align-content-center justify-content-center">
                    <AiOutlineApi size={25} color="white" />
                  </RoundIcon>
                  <span className="text-bluegray-400 mt-1 font-bold text-sm">Integração</span>
                </div>
                <div
                  className="w-5rem h-5rem border-1 mt-1 ml-1 border-yellow-400 border-round flex flex-column align-items-center justify-content-center"
                  draggable
                  onDragEnd={() => setDisplay('hidden')}
                  onDragStart={(event) => {
                    dragStart(event, 'SEND_CONTACTS')
                  }}>
                  <RoundIcon className="bg-yellow-400 border-circle flex align-items-center align-content-center justify-content-center">
                    <RiContactsBookFill size={25} color="white" />
                  </RoundIcon>
                  <span className="text-bluegray-400 mt-1 font-bold text-sm">Enviar contato(s)</span>
                </div>
              </div>
              <div
                className="w-5rem h-5rem border-1 mt-1 ml-1 border-yellow-400 border-round flex flex-column align-items-center justify-content-center"
                draggable
                onDragEnd={() => setDisplay('hidden')}
                onDragStart={(event) => {
                  dragStart(event, 'RANDOMIZER')
                }}>
                <RoundIcon className="bg-yellow-400 border-circle flex align-items-center align-content-center justify-content-center">
                  <FaRandom size={25} color="white" />
                </RoundIcon>
                <span className="text-bluegray-400 mt-1 font-bold text-sm">Randomizador</span>
              </div>
            </div>
          </div>
        </NewDiv>
        <Button className="border-circle w-4rem h-4rem " onClick={handleClick}>
          <FaPlus size={30} color="white" />
        </Button>
      </div>
    </>
  )
}
