import { Route, Routes } from 'react-router-dom'
import { ReactFlowProvider } from 'reactflow'
import Flow from './Flow/Flow'
import './style.css'

export default function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/:flowId"
          element={
            <ReactFlowProvider>
              <Flow />
            </ReactFlowProvider>
          }></Route>
      </Routes>
    </div>
  )
}
