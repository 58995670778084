import { IGetIntegrationLogsResponseProps } from '../../@types/services/integration-logs'

import * as integration from './integration'

const selectedModule = integration

export const getIntegrationLogs: IGetIntegrationLogs = selectedModule.getIntegrationLogs

export type IGetIntegrationLogs = ({
  flowId,
  nodeId,
}: {
  flowId: string
  nodeId: string
}) => Promise<IGetIntegrationLogsResponseProps[]>
