/* eslint-disable no-irregular-whitespace */
/* eslint-disable react-hooks/rules-of-hooks */
import he from 'he'
import { useCustomizeFieldsStore } from '../store/customizeFields'

export function convertStringToDb(text: string) {
  const optionsField = useCustomizeFieldsStore.getState().optionsField

  let replacedText = text
  optionsField.forEach((option) => {
    const regex = new RegExp(
      `<span class="mention" data-value="${option.value}" data-id="${option.id}" data-denotation-char="">﻿<span contenteditable="false"><span class="ql-mention-denotation-char"></span>${option.value}</span>﻿</span>`,
      'g'
    )

    if (replacedText.search(option.id) !== -1) {
      replacedText = replacedText.replaceAll(regex, option.id)
    }
  })

  return he.decode(replacedText)
}

export function convertStringFromDb(text: string) {
  const optionsField = useCustomizeFieldsStore.getState().optionsField

  let changedText = text

  optionsField.forEach((option) => {
    const regex = new RegExp(option.id, 'g')

    if (changedText.search(regex) !== -1) {
      changedText = changedText.replaceAll(
        regex,
        `<span class="mention" data-value="${option.value}" data-id="${option.id}" data-denotation-char="">﻿<span contenteditable="false"><span class="ql-mention-denotation-char"></span>${option.value}</span>﻿</span>`
      )
    }
  })

  return he.decode(changedText)
}

export function convertTimeUnit(value: string) {
  switch (value) {
    case 'Segundo(s)':
      return 'SECONDS'

    case 'Minuto(s)':
      return 'MINUTES'

    case 'Hora(s)':
      return 'HOURS'

    case 'Dia(s)':
      return 'DAYS'
  }
}

export function reConvertTimeUnit(value: string) {
  switch (value) {
    case 'SECONDS':
      return 'Segundo(s)'

    case 'MINUTES':
      return 'Minuto(s)'

    case 'HOURS':
      return 'Hora(s)'

    case 'DAYS':
      return 'Dia(s)'

    default:
      return 'Segundo(s)'
  }
}

export function operationNamed(operation?: string) {
  switch (operation) {
    case 'define':
      return 'Definir'
    case 'subtract':
      return 'Subtrair'
    case 'sum':
      return 'Somar'
    default:
      return ''
  }
}
