import { memo, useEffect, useState } from 'react'
import { FaHourglassEnd } from 'react-icons/fa'
import { Handle, NodeProps, Position, useUpdateNodeInternals } from 'reactflow'
import { reConvertTimeUnit } from '../../utils/convertStrings'
import { formatDayAbbreviations } from '../../utils/format'
import { ModalSmartDelay } from './components/ModalSmartDelay'
import { OptionsHover } from '../../components/OptionsHover'

const SmartDelayNode = ({ id, data, isConnectable }: NodeProps) => {
  const [hover, setHover] = useState('hidden')
  const [title, setTitle] = useState('')
  const [modalOpen, setModalOpen] = useState(false)

  const updateNodeInternals = useUpdateNodeInternals()

  useEffect(() => {
    updateNodeInternals(id)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <div onMouseEnter={() => setHover('flex')} onMouseLeave={() => setHover('hidden')}>
      <OptionsHover id={id} className={hover} setModalOpen={setModalOpen} />
      {modalOpen && <ModalSmartDelay isOpen={modalOpen} onClose={() => setModalOpen(false)} data={data} id={id} />}
      <div className="border-round-lg w-18rem" style={{ border: '1px solid #1FCEC3', backgroundColor: '#FFF' }}>
        <div className="flex pl-4 pr-3 py-3 relative border-bottom-1" style={{ borderColor: '#9E9E9E' }}>
          <div>
            <div className="w-2rem h-2rem flex justify-content-center align-items-center border-circle bg-yellow-300">
              <FaHourglassEnd size={22} color="white" />
            </div>
            <div>
              <Handle
                type="target"
                position={Position.Left}
                id="a"
                style={{
                  background: '#FFF',
                  border: 'solid 1px #1FCEC3',
                  height: '16px',
                  width: '16px',
                  left: '-9px',
                }}
                isConnectable={isConnectable}
              />
            </div>
          </div>
          <div className="flex ml-3 flex-column align-items-start">
            <div className="">
              <input
                className="input-textNode-styled border-none w-10rem border-bottom-1 border-dashed font-bold h-1rem line-height-2 text-base vertical-align-middle"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Atraso inteligente"
                name=""
                id=""
              />
            </div>
            <div className="font-normal text-sm text-yellow-300">Atraso inteligente</div>
          </div>
        </div>

        {data.waitingDuration ? (
          <div onClick={() => setModalOpen(true)} className="cursor-pointer">
            <div className="flex align-items-center justify-content-center">
              <div className="w-10 my-3 text-left text-xl line-height-2 text-800">
                Aguarde{' '}
                <b>
                  {data.waitingDuration} {reConvertTimeUnit(data.waitingDurationType)}
                </b>{' '}
                e então continue
                {data.daysRepeat &&
                  ` às ${data.hourInitial} - ${data.hourFinal} em 
                  ${
                    data.daysRepeat.length === 7
                      ? 'Qualquer dia'
                      : data.daysRepeat.map((day: string) => formatDayAbbreviations(day))
                  }`}
              </div>
            </div>
            <div
              className="py-4 justify-content-end flex aling-items-center relative h-2rem border-top-1 border-round-bottom-lg"
              style={{ borderColor: '#E5E7EB', backgroundColor: '#FBFBFC' }}>
              <div className="flex justify-content-center align-items-center">
                <span className="text-xs line-height-1 cursor-pointer mr-3">Próximo passo</span>
              </div>
              <Handle
                type="source"
                position={Position.Right}
                id="a"
                style={{
                  background: '#FFF',
                  border: 'solid 1px #1FCEC3',
                  height: '18px',
                  width: '18px',
                  right: '-9px',
                }}
                isConnectable={isConnectable}
              />
            </div>

            {data.hourInitial && data.hourFinal ? (
              <div
                className="py-4 justify-content-end flex aling-items-center relative h-2rem border-round-bottom-lg"
                style={{ borderColor: '#E5E7EB', backgroundColor: '#FBFBFC' }}>
                <div className="flex justify-content-center align-items-center">
                  <span className="text-xs line-height-1 cursor-pointer mr-3">Se estiver fora do horário</span>
                </div>

                <Handle
                  type="source"
                  position={Position.Right}
                  id={'error'}
                  style={{
                    background: '#FFF',
                    border: 'solid 1px red',
                    height: '18px',
                    width: '18px',
                    right: '-9px',
                  }}
                  isConnectable={isConnectable}
                />
              </div>
            ) : null}
          </div>
        ) : (
          <>
            <div onClick={() => setModalOpen(true)} className="text-blue-400 my-3 cursor-pointer">
              Configurar etapa do fluxo
            </div>
            <Handle
              type="source"
              position={Position.Right}
              id="a"
              style={{
                background: '#FFF',
                border: 'solid 1px #1FCEC3',
                height: '18px',
                width: '18px',
                right: '-9px',
                visibility: 'hidden',
              }}
              isConnectable={isConnectable}
            />
          </>
        )}
      </div>
    </div>
  )
}

SmartDelayNode.displayName = 'SmartDelayNode'

export default memo(SmartDelayNode)
