export enum OptionsConditionsEnum {
  TAGS = 'TAGS',
  CONTACT = 'CONTACT',
  FLOW = 'FLOW',
  SERVICE_HOURS = 'SERVICE_HOURS',
}

export const OPTIONS_HOURS = [
  '00:00',
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
]

export const OPTIONS_DAYS = [
  {
    id: '0',
    value: 'Domingo',
  },
  {
    id: '1',
    value: 'Segunda',
  },
  {
    id: '2',
    value: 'Terça',
  },
  {
    id: '3',
    value: 'Quarta',
  },
  {
    id: '4',
    value: 'Quinta',
  },
  {
    id: '5',
    value: 'Sexta',
  },
  {
    id: '6',
    value: 'Sábado',
  },
]

export const OPTIONS_CONDITIONS = [
  {
    label: 'Está preenchido',
    value: 'isFilled',
    use: [OptionsConditionsEnum.CONTACT, OptionsConditionsEnum.FLOW],
    typeAccept: ['STRING', 'NUMBER', 'MONETARY', 'MASK', 'EMAIL', 'PHONE', 'TIME'],
  },
  {
    label: 'Não está preenchido',
    value: 'notIsFilled',
    use: [OptionsConditionsEnum.CONTACT, OptionsConditionsEnum.FLOW],
    typeAccept: ['STRING', 'NUMBER', 'MONETARY', 'MASK', 'EMAIL', 'PHONE', 'TIME'],
  },
  {
    label: 'É igual a',
    value: 'equals',
    use: [OptionsConditionsEnum.CONTACT, OptionsConditionsEnum.FLOW],
    typeAccept: ['STRING', 'NUMBER', 'MONETARY', 'MASK', 'EMAIL', 'PHONE', 'TIME', 'BOOLEAN'],
  },
  {
    label: 'É maior que',
    value: 'biggerThan',
    use: [OptionsConditionsEnum.CONTACT, OptionsConditionsEnum.FLOW],
    typeAccept: ['NUMBER', 'MONETARY', 'TIME'],
  },
  {
    label: 'É menor que',
    value: 'lessThan',
    use: [OptionsConditionsEnum.CONTACT, OptionsConditionsEnum.FLOW],
    typeAccept: ['NUMBER', 'MONETARY', 'TIME'],
  },
  {
    label: 'É maior ou igual',
    value: 'biggerOrEqual',
    use: [OptionsConditionsEnum.CONTACT, OptionsConditionsEnum.FLOW],
    typeAccept: ['NUMBER', 'MONETARY', 'TIME'],
  },
  {
    label: 'É menor ou igual',
    value: 'lessOrEqual',
    use: [OptionsConditionsEnum.CONTACT, OptionsConditionsEnum.FLOW],
    typeAccept: ['NUMBER', 'MONETARY', 'TIME'],
  },
  {
    label: 'É diferente de',
    value: 'differentThan',
    use: [OptionsConditionsEnum.CONTACT, OptionsConditionsEnum.FLOW],
    typeAccept: ['STRING', 'NUMBER', 'MONETARY', 'MASK', 'EMAIL', 'PHONE', 'TIME', 'BOOLEAN'],
  },
  {
    label: 'Começa com',
    value: 'startsWith',
    use: [OptionsConditionsEnum.CONTACT, OptionsConditionsEnum.FLOW],
    typeAccept: ['STRING', 'NUMBER', 'MONETARY', 'MASK', 'PHONE'],
  },
  {
    label: 'Termina com',
    value: 'endsWith',
    use: [OptionsConditionsEnum.CONTACT, OptionsConditionsEnum.FLOW],
    typeAccept: ['STRING', 'NUMBER', 'MASK', 'PHONE'],
  },
  {
    label: 'Contém uma das',
    value: 'contains',
    use: [OptionsConditionsEnum.TAGS],
    typeAccept: [],
  },
  {
    label: 'Contém apenas',
    value: 'containsSome',
    use: [OptionsConditionsEnum.TAGS],
    typeAccept: [],
  },
  {
    label: 'Não contém uma das',
    value: 'notContains',
    use: [OptionsConditionsEnum.TAGS],
    typeAccept: [],
  },
  {
    label: 'Não contém nenhuma',
    value: 'notContainsSome',
    use: [OptionsConditionsEnum.TAGS],
    typeAccept: [],
  },
  {
    label: 'A data é igual a',
    value: 'equalsDate',
    use: [OptionsConditionsEnum.CONTACT, OptionsConditionsEnum.FLOW],
    typeAccept: ['DATE'],
  },
  {
    label: 'A data é maior que',
    value: 'biggerThanDate',
    use: [OptionsConditionsEnum.CONTACT, OptionsConditionsEnum.FLOW],
    typeAccept: ['DATE'],
  },
  {
    label: 'A data é menor que',
    value: 'lessThanDate',
    use: [OptionsConditionsEnum.CONTACT, OptionsConditionsEnum.FLOW],
    typeAccept: ['DATE'],
  },
  {
    label: 'A data é maior ou igual',
    value: 'biggerOrEqualDate',
    use: [OptionsConditionsEnum.CONTACT, OptionsConditionsEnum.FLOW],
    typeAccept: ['DATE'],
  },
  {
    label: 'A data é menor ou igual',
    value: 'lessOrEqualDate',
    use: [OptionsConditionsEnum.CONTACT, OptionsConditionsEnum.FLOW],
    typeAccept: ['DATE'],
  },
  {
    label: 'Está dentro do horário',
    value: 'isOnSchedule',
    use: [OptionsConditionsEnum.SERVICE_HOURS],
    typeAccept: [],
  },
  {
    label: 'Está fora do horário',
    value: 'isNotOnSchedule',
    use: [OptionsConditionsEnum.SERVICE_HOURS],
    typeAccept: [],
  },
]
