import { memo, useEffect, useRef, useState } from 'react'
import { Handle, NodeProps, Position, useUpdateNodeInternals } from 'reactflow'
import ModalCondition from './components/ModalCondition'
import { OptionsHover } from '../../components/OptionsHover'
import { RxSwitch } from 'react-icons/rx'
import { Divider } from 'primereact/divider'
import { OPTIONS_CONDITIONS, OptionsConditionsEnum } from '../../utils/constants'
import useStore from '../../store/store'
import { handleFormatConditionType } from '../../utils/format'

const ConditionNode = ({ id, data, isConnectable }: NodeProps) => {
  const { tags } = useStore((state) => ({
    tags: state.tags,
  }))

  const divRef = useRef(null)

  const [hover, setHover] = useState('hidden')
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [counter, setCounter] = useState(0)

  const updateNodeInternals = useUpdateNodeInternals()
  const iHaveEdge = useStore((state) => state.iHaveEdge)

  const existConditions = data.conditions && Object.keys(data.conditions).length > 0

  useEffect(() => {
    updateNodeInternals(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (divRef.current) {
      if (existConditions) {
        const items = Object.entries(data.conditions).map(([key, value]: any) => value.map(() => key))

        const map = items.map((item) => ({
          id: item[0],
          label: '',
        }))

        if (counter > map.length) return
        if (map.length === counter) {
          iHaveEdge(map as any)
        } else {
          setCounter(counter + 1)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })

  const displayItemName = (item: string) => {
    switch (item) {
      case OptionsConditionsEnum.TAGS:
        return 'etiqueta(s)'
      case OptionsConditionsEnum.SERVICE_HOURS:
        return 'horário de atendimento'
      default:
        return item
    }
  }

  return (
    <div onMouseEnter={() => setHover('flex')} onMouseLeave={() => setHover('hidden')}>
      {isOpenModal && (
        <ModalCondition isOpen={isOpenModal} onClose={() => setIsOpenModal(!isOpenModal)} id={id} data={data} />
      )}
      <OptionsHover id={id} className={hover} setModalOpen={setIsOpenModal} />

      <div
        onClick={() => setIsOpenModal(true)}
        className="border-round-lg w-18rem border-1"
        style={{
          border: '1px solid #1FCEC3',
          backgroundColor: '#FFF',
        }}>
        <div className="flex pl-4 pr-3 py-3 relative border-bottom-1" style={{ borderColor: '#9E9E9E' }}>
          <div>
            <div className="w-2rem h-2rem flex justify-content-center align-items-center border-circle bg-teal-300">
              <RxSwitch size={26} color="white" />
            </div>
            <div>
              <Handle
                type="target"
                position={Position.Left}
                id="a"
                style={{
                  background: '#FFF',
                  border: 'solid 1px #1FCEC3',
                  height: '16px',
                  width: '16px',
                  left: '-9px',
                }}
                isConnectable={isConnectable}
              />
            </div>
          </div>
          <div className="flex ml-3 flex-column align-items-start">
            <div className="border-none border-bottom-1 border-dashed font-bold line-height-2 text-color-secondary">
              Condição
            </div>
            <div className="font-normal text-sm text-teal-300">Condição</div>
          </div>
        </div>
        {existConditions ? (
          <div
            onClick={() => setIsOpenModal(true)}
            className="flex justify-content-end mt-4 cursor-pointer flex-column gap-4">
            {Object.entries(data.conditions).map(([key, value]: any) => (
              <div className="mb-2 relative" key={key}>
                <Divider style={{ marginTop: 'unset' }}>
                  <span className="text-sm font-bold text-gray-800">
                    {key !== Object.keys(data.conditions)[0] ? 'Ou, se' : 'Verifique se'}
                  </span>
                </Divider>
                {value[0].fieldValue && value[0]?.conditions[0]?.validation ? (
                  <>
                    {value.map((item: any) =>
                      item.conditions.map((condition: any, index: number) => (
                        <div className="mb-3 mr-2 ml-2" key={index}>
                          <div>
                            <span className="text-sm cursor-pointer mr-1 text-gray-700">
                              {index !== 0 ? handleFormatConditionType(value[0].conditionType) : 'o valor'}{' '}
                              <b className="text-gray-800">{displayItemName(value[0].fieldValue)}</b>
                            </span>
                            <span className="text-sm cursor-pointer text-gray-700 mr-1">
                              {OPTIONS_CONDITIONS.find(
                                (option) => option.value === condition.validation
                              )?.label.toLowerCase()}
                            </span>
                            <span className="text-sm cursor-pointer mr-1 text-gray-700">
                              <b className="text-gray-800">
                                {Array.isArray(condition.value) ? (
                                  value[0].fieldValue === 'TAGS' ? (
                                    <p>
                                      {tags
                                        ?.filter((tag) => condition.value.includes(tag.id))
                                        .map((value) => value.name)
                                        .join(', ')}
                                    </p>
                                  ) : (
                                    ''
                                  )
                                ) : (
                                  condition.value
                                )}
                              </b>
                            </span>
                          </div>
                        </div>
                      ))
                    )}
                    <Handle
                      type="source"
                      position={Position.Right}
                      id={key}
                      ref={divRef}
                      key={key}
                      style={{
                        background: '#FFF',
                        border: 'solid 1px #1FCEC3',
                        height: '18px',
                        width: '18px',
                        right: '-9px',
                      }}
                      isConnectable={isConnectable}
                    />
                  </>
                ) : (
                  <span className="text-sm text-gray-700">Condição não preenchida</span>
                )}
              </div>
            ))}
            <div
              className="flex relative border-top-1 border-round-bottom-lg flex-column"
              style={{ borderColor: '#E5E7EB', backgroundColor: '#FBFBFC' }}>
              <span className="text-sm cursor-pointer my-3 text-gray-700">
                Se não correspondeu a nenhuma <br /> das condições acima
              </span>
              <Handle
                type="source"
                position={Position.Right}
                id="error"
                style={{
                  background: '#FFF',
                  border: 'solid 1px red',
                  height: '18px',
                  width: '18px',
                  right: '-9px',
                }}
                isConnectable={isConnectable}
              />
            </div>
          </div>
        ) : (
          <div
            className="flex relative border-top-1 border-round-bottom-lg flex-column"
            style={{ borderColor: '#E5E7EB', backgroundColor: '#FBFBFC' }}>
            <div onClick={() => setIsOpenModal(true)} className="text-blue-400 my-3 cursor-pointer">
              Configurar etapa do fluxo
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

ConditionNode.displayName = 'ConditionNode'

export default memo(ConditionNode)
