import { memo, useEffect, useState } from 'react'
import { Handle, NodeProps, Position } from 'reactflow'
import { TbExchange } from 'react-icons/tb'
import { FaExternalLinkAlt } from 'react-icons/fa'
import useStore from '../../store/store'
import { OptionsHover } from '../../components/OptionsHover'
import ModalFile from '../../components/ModalFile'
import { IFlows } from '../../data/types/nodeType'

const MoveFlowNode = ({ id, data, isConnectable }: NodeProps) => {
  const [hover, setHover] = useState('hidden')
  const [title, setTitle] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [option, setOption] = useState('')
  const [flows, setFlows] = useState<{ value: string; name: string }[]>()

  const { updateNodeData, getFlows } = useStore((state) => ({
    updateNodeData: state.updateNodeData,
    getFlows: state.getFlows,
  }))

  const getFlowName = (id: string) => {
    if (!flows) return
    const name = flows.find((flow: { value: string; name: string }) => flow.value === id)
    return name?.name || ''
  }

  useEffect(() => {
    if (!data) return
    if (data.moveToFlowId) {
      setOption(data.moveToFlowId)
    }
  }, [data])

  useEffect(() => {
    if (modalOpen) return
    if (option === '') return
    if (data !== undefined) if (option === data.moveToFlowId) return
    const nodeData = {
      moveToFlowId: option,
    }
    updateNodeData(id, nodeData)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen])

  useEffect(() => {
    getFlows().then((res: any) => {
      const flowsData = res.data.content.filter((flow: IFlows) => flow.id !== useStore.getState().flowId)
      const flows = flowsData.map((flow: any) => {
        return {
          value: flow.id,
          name: flow.name,
        }
      })

      setFlows(flows)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div onMouseEnter={() => setHover('flex')} onMouseLeave={() => setHover('hidden')}>
      <OptionsHover id={id} className={hover} setModalOpen={setModalOpen} />

      <ModalFile
        dropDownFluxoFromNode={option}
        setDropDownFluxo={setOption}
        visible={modalOpen}
        onHide={() => setModalOpen(false)}
        text="Iniciar outro fluxo"
        icon={<TbExchange color="white" />}
        changeFluxoOptions={flows}
        color="yellow"
        hasChageFluxo
      />

      <div className="border-round-lg w-18rem" style={{ border: '1px solid #1FCEC3', backgroundColor: '#FFF' }}>
        <div className="flex pl-4 pr-3 py-3 relative border-bottom-1" style={{ borderColor: '#9E9E9E' }}>
          <div>
            <div className="w-2rem h-2rem flex justify-content-center align-items-center border-circle bg-yellow-300">
              <TbExchange size={26} color="white" />
            </div>
            <div>
              <Handle
                type="target"
                position={Position.Left}
                id="a"
                style={{
                  background: '#FFF',
                  border: 'solid 1px #1FCEC3',
                  height: '16px',
                  width: '16px',
                  left: '-9px',
                }}
                isConnectable={isConnectable}
              />
            </div>
          </div>
          <div className="flex ml-3 flex-column align-items-start">
            <div className="">
              <input
                className="input-textNode-styled border-none w-9rem border-bottom-1 border-dashed font-bold h-1rem line-height-2 text-base vertical-align-middle"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Mover de fluxo"
                name=""
                id=""
              />
            </div>
            <div className="font-normal text-sm text-yellow-300">Iniciar outro fluxo</div>
          </div>
        </div>
        {option ? (
          <div
            className="flex align-items-center flex-column align-content-center mb-2 cursor-pointer"
            onClick={() => setModalOpen(true)}>
            <div className="w-9">
              <div className="font-bold my-2">Fluxo de destino:</div>
              <div className="w-full bg-bluegray-500 border-1 border-round border-bluegray-700 my-2 text-white h-auto">
                <p className="my-1">{getFlowName(option)}</p>
              </div>
              <p className="my-2 text-xs text-blue-400 cursor-pointer underline">
                Ver fluxo <FaExternalLinkAlt className="ml-2" />
              </p>
            </div>
          </div>
        ) : (
          <div onClick={() => setModalOpen(true)} className="text-blue-400 my-3 cursor-pointer">
            Configurar etapa do fluxo
          </div>
        )}
      </div>
    </div>
  )
}

MoveFlowNode.displayName = 'MoveFlowNode'

export default memo(MoveFlowNode)
