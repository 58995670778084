import { axiosInstance } from '../Https/axios-instance'
import { Data, ICreate, IFlow, IFlows, INodes } from './../types/nodeType'

class NodeService {
  async get(flowId: string) {
    return await axiosInstance.get<INodes>(`/nodes`, { headers: { flowid: flowId } })
  }

  async getFlow(flowId: string) {
    return await axiosInstance.get<IFlow>(`/flows/${flowId}`)
  }

  async updateViewPort(flowId: string, viewPort: { x: string; y: string; zoom: string }) {
    return await axiosInstance.put(`/flows/${flowId}`, { viewport: viewPort })
  }

  async getFlows() {
    return await axiosInstance.get<IFlows[]>(`/flows`, {
      params: {
        limit: 50,
      },
    })
  }

  async create(
    position: { x: string; y: string },
    nodeType: string,
    height: number,
    width: number,
    flowId: string,
    data?: any
  ) {
    return await axiosInstance.post<ICreate>(
      `/nodes`,
      {
        height,
        width,
        type: nodeType,
        position,
        ...(data?.mediaUrl && {
          data: {
            ...data,
          },
        }),
      },
      {
        headers: {
          flowid: `${flowId}`,
        },
      }
    )
  }

  async delete(nodeId: string, flowId: string) {
    return await axiosInstance.delete(`/nodes/${nodeId}`, {
      headers: { flowid: `${flowId}` },
    })
  }

  async updatePosition(nodeId: string, flowId: string, position: { x: string; y: string }) {
    return await axiosInstance.put(`/nodes/${nodeId}`, { position }, { headers: { flowid: flowId } })
  }

  async updateData(nodeId: string, flowId: string, data: Data) {
    return await axiosInstance.put(`/nodes/${nodeId}`, { data }, { headers: { flowid: flowId } })
  }

  async uploadFile(nodeId: string, flowId: string, file: FormData, onUpload?: (event: any) => void) {
    return await axiosInstance.post(`/nodes/${nodeId}/upload-media`, file, {
      headers: { flowid: flowId, 'Content-Type': 'multipart/form-data' },
      onUploadProgress(progressEvent) {
        if (onUpload) onUpload(progressEvent)
      },
    })
  }

  async deleteFile(nodeId: string, flowId: string) {
    return await axiosInstance.delete(`/nodes/${nodeId}/media`, {
      headers: { flowid: flowId },
    })
  }
}

export const nodeService = new NodeService()
