import { IGetIntegrationLogs } from '.'
import { axiosInstance } from '../../data/Https/axios-instance'

export const getIntegrationLogs: IGetIntegrationLogs = async (params) => {
  const { data } = await axiosInstance.get('/integration-logs', {
    params: {
      ...params,
    },
  })

  return data
}
