import { MutableRefObject, useEffect } from 'react'

export function useOnClickOutside<T>(ref: MutableRefObject<T>, handler: (event: any) => void): void {
  useEffect(() => {
    const listener = (event: any) => {
      const current: any = ref && ref.current

      if (!current || current.contains(event.target)) return

      handler(event)
    }

    document.addEventListener('mousedown', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
    }
  }, [ref, handler])
}

export default useOnClickOutside
