import { memo, useEffect, useState } from 'react'
import { BsFillChatLeftTextFill, BsKeyboard } from 'react-icons/bs'
import { Handle, NodeProps, Position, useUpdateNodeInternals } from 'reactflow'

import styled from 'styled-components'
import { convertStringFromDb, convertStringToDb, convertTimeUnit, reConvertTimeUnit } from '../../utils/convertStrings'
import useStore from './../../store/store'
import './styles.css'
import { OptionsHover } from '../../components/OptionsHover'
import ModalFile from '../../components/ModalFile'

const TextDiv = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: '-webkit-box';
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  max-height: 100px;
  background-color: #e8faf9;
  border-color: #1fcec3;
  word-break: 'break-all';
`

const TextNode = ({ id, data, isConnectable }: NodeProps) => {
  const [hover, setHover] = useState('hidden')
  const [modalopen, setModalOpen] = useState(false)
  const [optionSelect, setOptionSelect] = useState<any>(0)
  const [mensagemShow, setMensageShow] = useState<string>()
  const [bollean, setBollean] = useState(false)
  const [oldBollean, setOldBollean] = useState(false)
  const [textTime, setTextTime] = useState('')

  const updateNodeInternals = useUpdateNodeInternals()
  const updateNodeData = useStore((state) => state.updateNodeData)

  useEffect(() => {
    if (modalopen) return
    if (mensagemShow === undefined) return
    if (
      mensagemShow === convertStringFromDb(data.text || '') &&
      optionSelect === data.typingDuration &&
      textTime === reConvertTimeUnit(data.typingDurationType) &&
      oldBollean === bollean
    )
      return
    if (bollean) {
      const nodeData = {
        text: convertStringToDb(mensagemShow),
        typingDuration: optionSelect,
        typingDurationType: convertTimeUnit(textTime),
      }
      updateNodeData(id, nodeData)
    } else {
      const nodeData = {
        text: convertStringToDb(mensagemShow),
        typingDuration: 0,
        typingDurationType: 'SECONDS',
      }

      updateNodeData(id, nodeData)
      setOptionSelect(0)
      setTextTime(reConvertTimeUnit('SECONDS'))
    }
    setOldBollean(bollean)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalopen])

  useEffect(() => {
    updateNodeInternals(id)
    if (data === undefined) return
    if (data.text) setMensageShow(convertStringFromDb(data.text || ''))
    if (data.typingDuration) {
      setBollean(true)
      setOptionSelect(data.typingDuration)
      setOldBollean(true)
      setTextTime(reConvertTimeUnit(data.typingDurationType))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleClick = () => {
    setModalOpen(true)
  }

  return (
    <div onMouseEnter={() => setHover('flex')} onMouseLeave={() => setHover('hidden')}>
      <OptionsHover id={id} className={hover} setModalOpen={setModalOpen} />
      <ModalFile
        text="Mensagem"
        textFromNode={mensagemShow}
        setAnswer={setBollean}
        timeAnswerFromNode={bollean}
        setShowMesage={setMensageShow}
        dropDownNumberFromNode={textTime}
        setNumberOption={setTextTime}
        hasText={true}
        visible={modalopen}
        onHide={() => setModalOpen(false)}
        icon={<BsFillChatLeftTextFill color="white" />}
        setInputNumber={setOptionSelect}
        timeFromNode={optionSelect}
        hasDropdown={true}
        color="teal"
      />
      <div
        onClick={handleClick}
        className="border-round-lg w-18rem"
        style={{ border: '1px solid #1FCEC3', backgroundColor: '#FFF' }}>
        <div className="flex pl-4 pr-3 py-3 relative border-bottom-1" style={{ borderColor: '#9E9E9E' }}>
          <div>
            <div
              className="w-2rem h-2rem flex justify-content-center align-items-center border-circle"
              style={{ backgroundColor: '#1FCEC3' }}>
              <BsFillChatLeftTextFill color="white" />
            </div>
            <div>
              <Handle
                type="target"
                position={Position.Left}
                id="a"
                style={{
                  background: '#FFF',
                  border: 'solid 1px #1FCEC3',
                  height: '16px',
                  width: '16px',
                  left: '-9px',
                }}
                isConnectable={isConnectable}
              />
            </div>
          </div>
          <div className="flex ml-3 flex-column align-items-start">
            <div className="">
              <label className="input-textNode-styled border-none w-9rem border-bottom-1 border-dashed font-bold h-1rem line-height-2 text-base vertical-align-middle text-gray-600">
                Enviar mensagem
              </label>
              {/* <input 
                            className="input-textNode-styled border-none w-9rem border-bottom-1 border-dashed font-bold h-1rem line-height-2 text-base vertical-align-middle"
                            
                            type="text" value={title} onChange={handleChange} placeholder='Enviar mensagem' name="" id="" /> */}
            </div>
            <div className="font-normal text-sm " style={{ color: 'rgb(23, 153, 140)' }}>
              texto
            </div>
          </div>
        </div>
        {mensagemShow ? (
          <>
            <div onClick={() => setModalOpen(true)} className="flex justify-content-center py-2 cursor-pointer">
              <TextDiv
                className="w-15rem p-2 border-round-lg border-1 text-left"
                dangerouslySetInnerHTML={{ __html: mensagemShow }}
              />
            </div>
            <div
              className="py-4 justify-content-between flex aling-items-center relative h-2rem border-top-1 border-round-bottom-lg "
              style={{ borderColor: '#E5E7EB', backgroundColor: '#FBFBFC' }}>
              {!bollean ? (
                <div></div>
              ) : (
                <div className="flex  justify-content-center align-items-center ml-1">
                  <BsKeyboard size={35} className="mb-3 mr-1" color="#1FCEC3" />
                  <div className="flex flex-column text-sm text-500">
                    <span>Digitando por</span>
                    <span>
                      <strong>{optionSelect}</strong> {textTime}
                    </span>
                  </div>
                </div>
              )}
              <div className="flex justify-content-center align-items-center">
                <span className="text-xs line-height-1 cursor-pointer mr-3">Próximo passo</span>
              </div>
              <Handle
                type="source"
                position={Position.Right}
                id="a"
                style={{
                  background: '#FFF',
                  border: 'solid 1px #1FCEC3',
                  height: '18px',
                  width: '18px',
                  right: '-9px',
                }}
                isConnectable={isConnectable}
              />
            </div>
          </>
        ) : (
          <>
            <div
              onClick={() => setModalOpen(true)}
              className="px-4 py-3 cursor-pointer justify-content-center align-items-center flex text-sm"
              style={{ color: '#4182F6' }}>
              configurar etapa do fluxo
            </div>
            <Handle
              type="source"
              position={Position.Right}
              id="a"
              style={{
                background: '#FFF',
                border: 'solid 1px #1FCEC3',
                height: '18px',
                width: '18px',
                right: '-9px',
                visibility: 'hidden',
              }}
              isConnectable={isConnectable}
            />
          </>
        )}
      </div>
    </div>
  )
}

TextNode.displayName = 'TextNode'

export default memo(TextNode)
