import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Divider } from 'primereact/divider'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { SelectButton } from 'primereact/selectbutton'
import { Toast } from 'primereact/toast'
import { FormEvent, useRef, useState } from 'react'
import { z } from 'zod'
import { createField } from '../../services/fields'
import { useCustomizeFieldsStore } from '../../store/customizeFields'

import diacritics from 'diacritics'

import styles from './styles.module.css'
import { RiInformationLine } from 'react-icons/ri'
import { OverlayPanel } from 'primereact/overlaypanel'

const schema = z.object({
  type: z.string().nonempty({
    message: 'Campo obrigatório',
  }),
  name: z.string().nonempty({
    message: 'Campo obrigatório',
  }),
  key: z.string().nonempty({
    message: 'Campo obrigatório',
  }),
  useField: z.string().nonempty({
    message: 'Campo obrigatório',
  }),
  mask: z
    .string()
    .nonempty({
      message: 'Campo obrigatório',
    })
    .optional(),
})

export function ModalCustomizeField() {
  const toast = useRef<Toast>(null)
  const op = useRef<OverlayPanel>(null)
  const { isOpenModalCustomizeField, onToggleModalCustomizeField, setOptionsField } = useCustomizeFieldsStore(
    (state) => ({
      isOpenModalCustomizeField: state.isOpenModalCustomizeField,
      onToggleModalCustomizeField: state.onToggleModalCustomizeField,
      setOptionsField: state.setOptionsField,
    })
  )

  const OPTIONS_USE_FIELD = [
    {
      label: 'Campo do contato',
      value: 'CONTACT',
    },
    {
      label: 'Campo da automação',
      value: 'FLOW',
    },
  ]

  const OPTIONS_TYPE = [
    {
      label: 'Texto',
      value: 'string',
    },
    {
      label: 'Email',
      value: 'email',
    },
    {
      label: 'Telefone',
      value: 'phone',
    },
    {
      label: 'Data',
      value: 'date',
    },
    {
      label: 'Horário',
      value: 'time',
    },
    {
      label: 'Número',
      value: 'number',
    },
    {
      label: 'Verdadeiro ou falso',
      value: 'boolean',
    },
    {
      label: 'Customizado',
      value: 'mask',
    },
    {
      label: 'Monetário',
      value: 'monetary',
    },
    // {
    //   label: 'JSON',
    //   value: 'JSON',
    // },
  ]

  const [fields, setFields] = useState({
    type: '',
    name: '',
    key: '',
    useField: '',
    mask: '',
  })

  const [fieldsError, setFieldsError] = useState({
    type: '',
    name: '',
    key: '',
    useField: '',
    mask: '',
  })

  const header = () => {
    return (
      <>
        <div className="flex align-items-center">
          <span className="text-600 text-base ml-2">Campos personalizados</span>
        </div>
        <Divider />
      </>
    )
  }

  const generateKey = (value: string) => {
    const withoutAccents = diacritics.remove(value) // Remove os acentos
    const lower = withoutAccents.toLowerCase().replace(/[^a-z0-9_]/g, '_') // Substitui caracteres não permitidos por underscores

    return lower
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    try {
      schema.parse({
        type: fields.type,
        name: fields.name,
        key: fields.key,
        useField: fields.useField,
        ...(fields.type === 'mask' && {
          mask: fields.mask,
        }),
      })

      const renamedKey = `${fields.useField.toLowerCase()}.${fields.key}`

      await createField({
        key: renamedKey,
        label: fields.name,
        type: fields.type,
        useIn: fields.useField,
        ...(fields.mask && {
          mask: fields.mask,
        }),
      })

      setOptionsField({
        type: fields.type,
        key: renamedKey,
        name: fields.name,
        useIn: fields.useField,
        ...(fields.mask && {
          mask: fields.mask,
        }),
      })

      toast?.current?.show({
        severity: 'success',
        summary: 'Sucesso!',
        detail: 'O campo foi adicionado com sucesso.',
        life: 3000,
      })
      onToggleModalCustomizeField(false)
    } catch (error: any) {
      if (error.formErrors) {
        return setFieldsError(error.formErrors.fieldErrors)
      }

      toast?.current?.show({
        severity: 'error',
        summary: 'Ops! Algo deu errado.',
        detail: error.response.status === 409 ? 'Já existe um campo com esses dados.' : 'Verifique novamente os dados.',
        life: 3000,
      })
    }
  }

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        className={styles.mypanel}
        header={header}
        onHide={() => {
          onToggleModalCustomizeField(false)
        }}
        visible={isOpenModalCustomizeField}
        style={{ width: '500px' }}
        draggable={false}>
        <OverlayPanel ref={op} style={{ width: 400 }}>
          <p className="font-bold">Utilizando máscaras personalizadas</p>

          <p className="mt-2 text-gray-600">
            As máscaras personalizadas facilitam a validação de entradas de texto do usuário.
          </p>
          <p className="mt-1 mb-3 text-gray-600">
            Para criar uma máscara personalizada utilize o caracter &quot;#&quot; (cerquilha) para delimitar a entrada
            de texto.
          </p>
          <p className="mt-1 mb-3 text-gray-600">Exemplo validar o campo CEP: #####-###</p>
        </OverlayPanel>
        <form className="flex flex-column" onSubmit={handleSubmit}>
          <div className="flex flex-column mb-4 mt-2">
            <SelectButton
              value={fields.useField}
              onChange={(e) => setFields({ ...fields, useField: e.value ?? '' })}
              options={OPTIONS_USE_FIELD}
              className={`${fieldsError.useField ? `p-invalid` : ''}`}
            />
            {fieldsError.useField && <small className="p-error">{fieldsError.useField}</small>}
          </div>
          <div className="flex flex-column mb-4">
            <span className="font-bold mb-2">Tipo de campo</span>
            <Dropdown
              className={`${fieldsError.type ? `p-invalid` : ''}`}
              onChange={(e) => setFields({ ...fields, type: e.value })}
              options={OPTIONS_TYPE}
              value={fields.type}
              optionValue="value"
              optionLabel="label"
            />
            {fieldsError.type && <small className="p-error">{fieldsError.type}</small>}
          </div>
          <div className="flex flex-column mb-4">
            <span className="font-bold mb-2">Nome do campo</span>
            <InputText
              className={`w-full h-3rem ${fieldsError.name ? `p-invalid` : ''}`}
              value={fields.name}
              onChange={(e) => {
                setFields({ ...fields, name: e.target.value, key: generateKey(e.target.value) })
              }}
            />
            {fieldsError.name && <small className="p-error">{fieldsError.name}</small>}
          </div>
          <div className="flex flex-column mb-4">
            <span className="font-bold mb-2">Chave</span>
            <InputText
              className={`w-full h-3rem ${fieldsError.key ? `p-invalid disabled surface-100 ` : 'surface-100'}`}
              value={fields.key}
              disabled
              onChange={(e) => setFields({ ...fields, key: generateKey(e.target.value) })}
              keyfilter={/^[a-zA-Z0-9_\-.]+$/}
            />
            {fieldsError.key && <small className="p-error">{fieldsError.key}</small>}
          </div>

          {fields.type === 'mask' && (
            <div className="flex flex-column mb-6">
              <span className="font-bold mb-2 flex gap-2 cursor-pointer" onClick={(e) => op.current?.toggle(e)}>
                Máscara personalizada <RiInformationLine size={20} />
              </span>
              <InputText
                className={`w-full h-3rem ${fieldsError.mask ? `p-invalid` : ''}`}
                value={fields.mask}
                onChange={(e) => setFields({ ...fields, mask: e.target.value })}
              />
              {fieldsError.mask && <small className="p-error">{fieldsError.mask}</small>}
            </div>
          )}

          <Button label="Salvar" type="submit" className="p-button-help w-full mt-4" />
        </form>
      </Dialog>
    </>
  )
}
