import { FC, useState } from 'react'
import { EdgeProps, getBezierPath } from 'reactflow'
import styled from 'styled-components'
import { FaRegTrashAlt } from 'react-icons/fa'
import useStore from '../../store/store'
import styles from './styles.module.css'

const foreignObjectSize = 150

const ButtonDiv = styled.div`
  background: transparent;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
`

export const ButtonEdge: FC<EdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
}) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })
  const [display, setDisplay] = useState('hidden')

  const deleteEdge = useStore((state) => state.deleteEdge)

  return (
    <>
      <path id={id} className={`${styles.myEdge}`} d={edgePath} />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={labelX - foreignObjectSize / 2}
        y={labelY - foreignObjectSize / 2}
        requiredExtensions="http://www.w3.org/1999/xhtml">
        <ButtonDiv onMouseEnter={() => setDisplay('flex')} onMouseLeave={() => setDisplay('hidden')}>
          <div
            className={`${display} border-circle bg-white w-2rem h-2rem align-items-center justify-content-center border-2 border-teal-600`}
            onClick={() => deleteEdge(id)}>
            <FaRegTrashAlt color={'red'} />
          </div>
        </ButtonDiv>
      </foreignObject>
    </>
  )
}
export default ButtonEdge
