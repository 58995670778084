import { memo, useEffect, useState } from 'react'
import { RiQuestionnaireLine } from 'react-icons/ri'
import { Handle, NodeProps, Position, useUpdateNodeInternals } from 'reactflow'
import styled from 'styled-components'
import { useCustomizeFieldsStore } from '../../store/customizeFields'
import { OptionsHover } from '../../components/OptionsHover'
import { ModalQuest } from './components/ModalQuest'
import { convertStringFromDb, reConvertTimeUnit } from '../../utils/convertStrings'
import { BsKeyboard } from 'react-icons/bs'

const TextDiv = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: '-webkit-box';
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  max-height: 100px;
  background-color: #e8faf9;
  border-color: #1fcec3;
  word-break: 'break-all';
`

const QuestNode = ({ id, data, isConnectable }: NodeProps) => {
  const { optionsField } = useCustomizeFieldsStore((state) => ({
    optionsField: state.optionsField,
  }))

  const [hover, setHover] = useState('hidden')
  const [modalOpen, setModalOpen] = useState(false)
  const [contactField, setContactField] = useState<string>('')
  const updateNodeInternals = useUpdateNodeInternals()

  useEffect(() => {
    updateNodeInternals(id)

    if (data.contactField) {
      const contact = optionsField.find((field) => field.id === data.contactField)
      setContactField(contact?.id || '') // ALTERAR
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <div onMouseEnter={() => setHover('flex')} onMouseLeave={() => setHover('hidden')}>
      <OptionsHover id={id} className={hover} setModalOpen={setModalOpen} />
      {modalOpen && <ModalQuest data={data} id={id} isOpen={modalOpen} onClose={() => setModalOpen(!modalOpen)} />}
      <div
        className="border-round-lg w-18rem"
        style={{ border: '1px solid #1FCEC3', backgroundColor: '#FFF' }}
        onClick={() => setModalOpen(true)}>
        <div className="flex pl-4 pr-3 py-3 relative border-bottom-1" style={{ borderColor: '#9E9E9E' }}>
          <div>
            <div className="w-2rem h-2rem flex justify-content-center align-items-center border-circle bg-teal-300">
              <RiQuestionnaireLine size={26} color="white" />
            </div>
            <div>
              <Handle
                type="target"
                position={Position.Left}
                id="a"
                style={{
                  background: '#FFF',
                  border: 'solid 1px #1FCEC3',
                  height: '16px',
                  width: '16px',
                  left: '-9px',
                }}
                isConnectable={isConnectable}
              />
            </div>
          </div>
          <div className="flex ml-3 flex-column align-items-start">
            <div className="">
              <input
                className="input-textNode-styled border-none w-10rem border-bottom-1 border-dashed font-bold h-1rem line-height-2 text-base vertical-align-middle"
                type="text"
                placeholder="Fazer uma pergunta"
                name=""
                id=""
              />
            </div>
            <div className="font-normal text-sm text-teal-300">Pergunta</div>
          </div>
        </div>
        {data ? (
          <div className="flex flex-column">
            {data.text && (
              <div onClick={() => setModalOpen(true)} className="flex justify-content-center py-2 cursor-pointer">
                <TextDiv
                  className="w-15rem p-2 border-round-lg border-1 text-left"
                  style={{
                    backgroundColor: '#E8FAF9',
                    borderColor: '#1FCEC3',
                    wordBreak: 'break-all',
                    overflow: 'hidden',
                  }}>
                  <div dangerouslySetInnerHTML={{ __html: convertStringFromDb(data.text) }} />
                </TextDiv>
              </div>
            )}

            {contactField && (
              <div className="flex justify-content-between my-3">
                <div></div>
                <div className="mr-3 flex flex-column align-items-end">
                  <span className="text-base text-800">Salvar resposta no campo:</span>
                  <div className="mt-1">
                    <b>{optionsField.find((option) => contactField === option.id)?.value}</b>
                  </div>
                </div>
              </div>
            )}
            {data.waitingDuration || data.typingDuration ? (
              <div
                className="flex relative border-top-1 border-round-bottom-lg pb-3 pt-3 flex-column"
                style={{ borderColor: '#E5E7EB', backgroundColor: '#FBFBFC' }}>
                {data.typingDuration ? (
                  <div className="pb-4 flex justify-content-center align-items-center">
                    <BsKeyboard size={35} color="#1FCEC3" />
                    <div className="text-sm text-500 ml-2">
                      <span>Digitando por</span>
                      <span>
                        <strong> {data.typingDuration}</strong> {reConvertTimeUnit(data.typingDurationType)}
                      </span>
                    </div>
                  </div>
                ) : null}
                <div className="flex justify-content-center align-items-end relative flex-column w-full">
                  <div className="relative">
                    <span className="text-xs line-height-1 cursor-pointer mr-3">Após responder</span>
                    <Handle
                      type="source"
                      position={Position.Right}
                      id="a"
                      style={{
                        background: '#FFF',
                        border: 'solid 1px #1FCEC3',
                        height: '18px',
                        width: '18px',
                        right: '-9px',
                      }}
                      isConnectable={isConnectable}
                    />
                  </div>
                  {data.waitingDuration > 0 ? (
                    <div className="relative mt-4">
                      <span className="text-xs line-height-1 cursor-pointer mr-3">
                        Se não responder em{' '}
                        <b>
                          {data.waitingDuration} {reConvertTimeUnit(data.waitingDurationType)}
                        </b>
                      </span>
                      <Handle
                        type="source"
                        position={Position.Right}
                        id={'error'}
                        style={{
                          background: '#FFF',
                          border: 'solid 1px red',
                          height: '18px',
                          width: '18px',
                          right: '-9px',
                        }}
                        isConnectable={isConnectable}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
              <div
                className="py-4 justify-content-between flex aling-items-center relative h-2rem border-top-1 border-round-bottom-lg "
                style={{ borderColor: '#E5E7EB', backgroundColor: '#FBFBFC' }}>
                <div></div>
                <div className="flex justify-content-center align-items-end relative flex-column">
                  <div className="relative">
                    <span className="text-xs line-height-1 cursor-pointer mr-3">Após responder</span>
                    <Handle
                      type="source"
                      position={Position.Right}
                      id="a"
                      style={{
                        background: '#FFF',
                        border: 'solid 1px #1FCEC3',
                        height: '18px',
                        width: '18px',
                        right: '-9px',
                      }}
                      isConnectable={isConnectable}
                    />
                    <Handle
                      type="source"
                      position={Position.Right}
                      id="error"
                      style={{
                        background: '#FFF',
                        border: 'solid 1px #1FCEC3',
                        height: '18px',
                        width: '18px',
                        right: '-9px',
                        visibility: 'hidden',
                      }}
                      isConnectable={isConnectable}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <>
            <div onClick={() => setModalOpen(true)} className="text-blue-400 my-3 cursor-pointer">
              Configurar etapa do fluxo
            </div>
            <Handle
              type="source"
              position={Position.Right}
              id="a"
              style={{
                background: '#FFF',
                border: 'solid 1px #1FCEC3',
                height: '18px',
                width: '18px',
                right: '-9px',
                visibility: 'hidden',
              }}
              isConnectable={isConnectable}
            />
            <Handle
              type="source"
              position={Position.Right}
              id="error"
              style={{
                background: '#FFF',
                border: 'solid 1px #1FCEC3',
                height: '18px',
                width: '18px',
                right: '-9px',
                visibility: 'hidden',
              }}
              isConnectable={isConnectable}
            />
          </>
        )}
      </div>
    </div>
  )
}

QuestNode.displayName = 'QuestNode'

export default memo(QuestNode)
