import { memo, useEffect, useState } from 'react'
import { Handle, NodeProps, Position } from 'reactflow'
import styled from 'styled-components'
import { BsImage } from 'react-icons/bs'
import { Image } from 'primereact/image'
import useStore from './../../store/store'
import { ProgressBar } from 'primereact/progressbar'
import { convertStringFromDb, convertStringToDb } from '../../utils/convertStrings'
import { OptionsHover } from '../../components/OptionsHover'
import ModalFile from '../../components/ModalFile'

const TextDiv = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: '-webkit-box';
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  max-height: 100px;
  word-break: 'break-all';
`

const ImageNode = ({ id, data, isConnectable }: NodeProps) => {
  const [hover, setHover] = useState('hidden')
  const [title, setTitle] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [imagemShow, setImageShow] = useState<string>()
  const [textShow, setShowText] = useState<string>()
  const [file, setFile] = useState<File>()
  const [load, setLoad] = useState(false)
  const closeModal = () => {
    setModalOpen(false)
  }
  const [count, setCount] = useState<number>(0)

  const updateNodeData = useStore((state) => state.updateNodeData)
  const uploadFile = useStore((state) => state.uploadFile)

  const upload = (event: any) => {
    const progress: number = Math.round((event.loaded * 100) / event.total)
    setCount(progress)
  }

  useEffect(() => {
    if (modalOpen) return
    if (textShow === undefined && file === undefined) return
    if (imagemShow === data.mediaUrl && textShow === convertStringFromDb(data.text || '')) return
    if (file) {
      if (imagemShow === data.mediaUrl) {
        if (textShow === undefined) return
        if (textShow.length < 1) return
        const nodeData = {
          text: convertStringToDb(textShow || ''),
          mediaUrl: imagemShow,
        }
        updateNodeData(id, nodeData)
      } else {
        const formData = new FormData()
        formData.append('file', file)
        setLoad(true)
        uploadFile(id, formData, upload).then((res) => {
          if (convertStringFromDb(data.text || '') !== textShow) {
            const nodeData = {
              text: convertStringToDb(textShow || ''),
              mediaUrl: res.data.data.mediaUrl,
            }
            updateNodeData(id, nodeData)
          }
          setImageShow(res.data.data.mediaUrl)
          setLoad(false)
          setCount(0)
        })
      }
    } else {
      if (textShow === undefined) return
      if (textShow.length < 1) return
      const nodeData = {
        text: convertStringToDb(textShow || ''),
        mediaUrl: imagemShow,
      }
      updateNodeData(id, nodeData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen])

  useEffect(() => {
    if (data === undefined) return
    if (!data.mediaUrl) return
    if (data.mediaUrl) setImageShow(data.mediaUrl)
    if (data.text) setShowText(convertStringFromDb(data.text))
  }, [data])

  useEffect(() => {
    const handler = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      event.returnValue = ''
    }

    if (load) {
      window.addEventListener('beforeunload', handler)

      return () => {
        window.removeEventListener('beforeunload', handler)
      }
    }
  }, [load])

  return (
    <div onMouseEnter={() => setHover('flex')} onMouseLeave={() => setHover('hidden')}>
      <OptionsHover id={id} className={hover} setModalOpen={setModalOpen} />
      <ModalFile
        type="image"
        textFromNode={textShow}
        nodeId={id}
        fileFromNode={imagemShow}
        setShowMesage={setShowText}
        setShowFile={setImageShow}
        setShowDoc={setFile}
        hasFileUpload={true}
        hasText={true}
        visible={modalOpen}
        onHide={closeModal}
        text="Imagem"
        icon={<BsImage color="white" />}
        accept="image/*"
      />
      <div
        className="border-round-lg w-18rem"
        style={{ border: '1px solid #1FCEC3', backgroundColor: '#FFF' }}
        onClick={() => setModalOpen(true)}>
        <div className="flex pl-4 pr-3 py-3 relative border-bottom-1" style={{ borderColor: '#9E9E9E' }}>
          <div>
            <div className="w-2rem h-2rem flex justify-content-center align-items-center border-circle bg-purple-300">
              <BsImage size={24} color="white" />
            </div>
            <div>
              <Handle
                type="target"
                position={Position.Left}
                id="a"
                style={{
                  background: '#FFF',
                  border: 'solid 1px #1FCEC3',
                  height: '16px',
                  width: '16px',
                  left: '-9px',
                }}
                isConnectable={isConnectable}
              />
            </div>
          </div>
          <div className="flex ml-3 flex-column align-items-start">
            <div className="">
              <input
                className="input-textNode-styled border-none w-9rem border-bottom-1 border-dashed font-bold h-1rem line-height-2 text-base vertical-align-middle"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Enviar imagem"
                name=""
                id=""
              />
            </div>
            <div className="font-normal text-sm text-purple-300">Imagem</div>
          </div>
        </div>
        {imagemShow || textShow ? (
          <>
            <div className="flex justify-content-center py-2 cursor-pointer">
              <div
                className="bg-purple-300 flex flex-column align-items-start py-2 cursor-pointer w-15rem p-2 border-round-lg border-1 text-left border-purple-400"
                onClick={() => setModalOpen(true)}>
                <span className="">
                  {load ? (
                    <div className="flex flex-column align-items-center">
                      <label>Fazendo Upload</label>
                      <ProgressBar style={{ width: '220px' }} value={count}></ProgressBar>
                    </div>
                  ) : (
                    <Image width="100%" src={imagemShow} />
                  )}
                </span>
                <TextDiv className=" text-left" style={{}}>
                  <div dangerouslySetInnerHTML={{ __html: textShow || '' }} />
                </TextDiv>
              </div>
            </div>
            <div
              className="py-4 justify-content-end flex aling-items-center relative h-2rem border-top-1 border-round-bottom-lg "
              style={{ borderColor: '#E5E7EB', backgroundColor: '#FBFBFC' }}>
              <div className="flex justify-content-center align-items-center">
                <span className="text-xs line-height-1 cursor-pointer mr-3">Próximo passo</span>
              </div>
              <Handle
                type="source"
                position={Position.Right}
                id="a"
                style={{
                  background: '#FFF',
                  border: 'solid 1px #1FCEC3',
                  height: '18px',
                  width: '18px',
                  right: '-9px',
                }}
                isConnectable={isConnectable}
              />
            </div>
          </>
        ) : (
          <>
            <div onClick={() => setModalOpen(true)} className="text-blue-400 my-3 cursor-pointer">
              Configurar etapa do fluxo
            </div>
            <Handle
              type="source"
              position={Position.Right}
              id="a"
              style={{
                background: '#FFF',
                border: 'solid 1px #1FCEC3',
                height: '18px',
                width: '18px',
                right: '-9px',
                visibility: 'hidden',
              }}
              isConnectable={isConnectable}
            />
          </>
        )}
      </div>
    </div>
  )
}

ImageNode.displayName = 'ImageNode'

export default memo(ImageNode)
