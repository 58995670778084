import { FaEdit, FaRegTrashAlt } from 'react-icons/fa'
import { BiDuplicate } from 'react-icons/bi'
import useStore from './../../store/store'
import styled from 'styled-components'

interface HoverNodeProps {
  id: string
  className?: string
  setModalOpen: (boolean: boolean) => void
}

const Hover = styled.div`
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: #e6e6e6;
  }
`

export function OptionsHover(props: HoverNodeProps) {
  const deleteNode = useStore((state) => state.deleteNode)
  const duplicateNode = useStore((state) => state.duplicateNode)

  const handleDuplicateNode = async (id: string) => {
    duplicateNode(id)
  }

  const handleDeleteNode = async (id: string) => {
    deleteNode(id)
  }

  return (
    <div className="relative w-full h-2rem mb-2">
      <div
        style={{ left: '30%' }}
        className={`${props.className} border-round bg-white w-8rem h-2rem absolute align-items-center justify-content-between shadow-1`}>
        <Hover className="border-round-left " onClick={() => handleDuplicateNode(props.id)}>
          <BiDuplicate size={18} color={'#2196F3'} />
        </Hover>
        <Hover onClick={() => props.setModalOpen(true)}>
          <FaEdit size={18} color="#008074" />
        </Hover>
        <Hover className={` border-round-right`} onClick={() => handleDeleteNode(props.id)}>
          <FaRegTrashAlt size={18} color={'red'} />
        </Hover>
      </div>
    </div>
  )
}
