import { Dialog } from 'primereact/dialog'

import styles from './styles.module.css'
import { RiQuestionnaireLine } from 'react-icons/ri'
import { Divider } from 'primereact/divider'
import { useEffect, useState } from 'react'
import EditorComponent from '../../../../components/QuillComponent'
import { Button } from 'primereact/button'
import { itemTemplate } from '../../../../utils/templates'
import { useCustomizeFieldsStore } from '../../../../store/customizeFields'
import LabelText from '../../../../components/LabelText'
import { Dropdown } from 'primereact/dropdown'
import { Checkbox } from 'primereact/checkbox'
import { InputNumber } from 'primereact/inputnumber'
import { z } from 'zod'
import {
  convertStringFromDb,
  convertStringToDb,
  convertTimeUnit,
  reConvertTimeUnit,
} from '../../../../utils/convertStrings'
import useStore from '../../../../store/store'
import { InputTextarea } from 'primereact/inputtextarea'
import { formatUseIn, handleFormatField } from '../../../../utils/format'

type IFieldsProps = {
  text: string
  contactField: string
  waitingDuration: number | null
  waitingDurationType: string
  typingDuration: number | null
  typingDurationType: string
  incorrectAnswerMessage: string
  retryAnswerMessage: string
}

const schema = z.object({
  waitingDuration: z.coerce.number().min(1, {
    message: 'Campo obrigatório',
  }),
  waitingDurationType: z.string().nonempty({
    message: 'Campo obrigatório',
  }),
})

const schemaOther = z.object({
  typingDuration: z.coerce.number().min(1, {
    message: 'Campo obrigatório',
  }),
  typingDurationType: z.string().nonempty({
    message: 'Campo obrigatório',
  }),
})

export function ModalQuest({
  isOpen,
  onClose,
  id,
  data,
}: {
  isOpen: boolean
  onClose: () => void
  id: string
  data: any
}) {
  const { onToggleModalCustomizeField, optionsFieldFormatted } = useCustomizeFieldsStore((state) => ({
    onToggleModalCustomizeField: state.onToggleModalCustomizeField,
    optionsFieldFormatted: state.optionsFieldFormatted,
  }))
  const updateNodeData = useStore((state) => state.updateNodeData)
  const deleteNoNescessariEdge = useStore((state) => state.deleteNoNescessariEdge)

  const [fields, setFields] = useState<IFieldsProps>({
    contactField: '',
    text: '',
    typingDuration: null,
    typingDurationType: '',
    waitingDuration: null,
    waitingDurationType: '',
    incorrectAnswerMessage: 'Resposta inválida, tente novamente digitando.',
    retryAnswerMessage: '3',
  })
  const [errorsFields, setErrorsFields] = useState({
    waitingDuration: '',
    waitingDurationType: '',
    typingDuration: '',
    typingDurationType: '',
  })

  const [isTimeResponse, setIsTimeResponse] = useState(false)
  const [isTypingFeedback, setIsTypingFeedback] = useState(false)

  const header = () => {
    return (
      <>
        <div className="flex align-items-center">
          <div className={`w-2rem h-2rem flex justify-content-center align-items-center border-circle bg-teal-300`}>
            <RiQuestionnaireLine size={20} color="white" />
          </div>
          <span className="text-600 text-base ml-2">Pergunta</span>
        </div>
        <Divider />
      </>
    )
  }

  const handleSubmit = async () => {
    try {
      if (isTimeResponse) {
        schema.parse({
          waitingDuration: fields.waitingDuration,
          waitingDurationType: fields.waitingDurationType,
        })
      }

      if (isTypingFeedback) {
        schemaOther.parse({
          typingDuration: fields.typingDuration,
          typingDurationType: fields.typingDurationType,
        })
      }

      const nodeData = {
        ...(isTimeResponse
          ? {
              waitingDuration: fields.waitingDuration,
              waitingDurationType: convertTimeUnit(fields.waitingDurationType),
            }
          : {
              waitingDuration: 0,
              waitingDurationType: 'SECONDS',
            }),
        ...(isTypingFeedback
          ? {
              typingDuration: fields.typingDuration,
              typingDurationType: convertTimeUnit(fields.typingDurationType),
            }
          : {
              typingDuration: 0,
              typingDurationType: 'SECONDS',
            }),
        text: fields.text ? convertStringToDb(fields.text) : '',
        contactField: fields.contactField,
        incorrectAnswerMessage: fields.incorrectAnswerMessage,
        retryAnswerMessage: fields.retryAnswerMessage,
      }

      if (!isTimeResponse) {
        deleteNoNescessariEdge(id, 'error')
      }
      await updateNodeData(id, nodeData)
      onClose()
    } catch (error: any) {
      if (error.formErrors.fieldErrors) {
        setErrorsFields(error.formErrors.fieldErrors)
      }
    }
  }

  const groupedItemTemplate = (option: any) => {
    return (
      <>
        {option.label === 'add-new-field' ? (
          <div
            className="flex align-items-center cursor-pointer font-normal"
            onClick={() => onToggleModalCustomizeField(true)}>
            <span className="text-blue-500">+ Adicionar novo campo</span>
          </div>
        ) : (
          <div className="flex align-items-center">
            <div>{formatUseIn(option.label)}</div>
          </div>
        )}
      </>
    )
  }

  const optionsField = optionsFieldFormatted({})

  useEffect(() => {
    if (data.text || data.waitingDuration || data.typingDuration || data.contactField) {
      setFields({
        contactField: data.contactField,
        text: convertStringFromDb(data.text),
        waitingDuration: data.waitingDuration,
        waitingDurationType: reConvertTimeUnit(data.waitingDurationType),
        typingDuration: data.typingDuration,
        typingDurationType: reConvertTimeUnit(data.typingDurationType),
        incorrectAnswerMessage: data.incorrectAnswerMessage || fields.incorrectAnswerMessage,
        retryAnswerMessage: String(data.retryAnswerMessage) || fields.retryAnswerMessage,
      })

      data.waitingDuration && setIsTimeResponse(true)
      data.typingDuration && setIsTypingFeedback(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Dialog
      className={styles.mypanel}
      header={header}
      onHide={onClose}
      visible={isOpen}
      style={{ width: '500px' }}
      draggable={false}>
      <div className="mb-4 mt-1">
        <LabelText>
          Simular &quot;digitando...&quot;?
          <Checkbox
            className="ml-3"
            checked={isTypingFeedback}
            onChange={(e) => setIsTypingFeedback(e.checked || false)}
          />
        </LabelText>
        {isTypingFeedback && (
          <div className="mt-3">
            <div className="flex gap-2 mt-2">
              <div className="w-full relative">
                <InputNumber
                  className={`w-full ${errorsFields.typingDuration ? 'p-invalid' : ''}`}
                  placeholder="Tempo"
                  value={fields.typingDuration}
                  onValueChange={(e) => setFields({ ...fields, typingDuration: e.value || null })}
                />
                {errorsFields.typingDuration && (
                  <small className="p-error absolute top-100 left-0">{errorsFields.typingDuration}</small>
                )}
              </div>
              <div className="w-full relative">
                <Dropdown
                  className={`w-full ${errorsFields.typingDurationType ? 'p-invalid' : ''}`}
                  placeholder="Medida de tempo"
                  options={['Segundo(s)', 'Minuto(s)']}
                  value={fields.typingDurationType}
                  onChange={(e) => setFields((prevState) => ({ ...prevState, typingDurationType: e.value }))}
                />
                {errorsFields.typingDurationType && (
                  <small className="p-error absolute top-100 left-0">{errorsFields.typingDurationType}</small>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="flex flex-column mb-6 ">
        <label className="font-bold mb-2">Mensagem:</label>
        <EditorComponent
          text={fields.text}
          setText={(value) => setFields((prevState) => ({ ...prevState, text: value }))}
        />
      </div>

      <div className="mb-2">
        <LabelText>Salvar resposta no campo:</LabelText>
      </div>
      <div className="w-full">
        <Dropdown
          value={fields.contactField}
          onChange={(e) => setFields({ ...fields, contactField: e.value })}
          options={[
            {
              label: 'add-new-field',
              code: 'add-new-field',
              items: [],
            },
            ...optionsField,
          ]}
          optionLabel="value"
          optionValue="id"
          optionGroupLabel="label"
          optionGroupChildren="items"
          itemTemplate={itemTemplate}
          optionGroupTemplate={groupedItemTemplate}
          className="w-full"
          filter
          placeholder="Selecione um campo para salvar a resposta"
        />
      </div>
      {fields.contactField && (
        <small>A resposta deverá estar no formato: {handleFormatField(fields.contactField)}</small>
      )}

      <div className="mb-4 mt-4">
        <LabelText>
          Configurar tempo de resposta?
          <Checkbox className="ml-3" checked={isTimeResponse} onChange={(e) => setIsTimeResponse(e.checked || false)} />
        </LabelText>

        {isTimeResponse && (
          <div className="mt-3">
            <label className="font-bold">Deve responder em:</label>
            <div className="flex gap-2 mt-2">
              <div className="w-full relative">
                <InputNumber
                  className={`w-full ${errorsFields.waitingDuration ? 'p-invalid' : ''}`}
                  placeholder="Tempo"
                  value={fields.waitingDuration}
                  onValueChange={(e) => setFields({ ...fields, waitingDuration: e.value || null })}
                />
                {errorsFields.waitingDuration && (
                  <small className="p-error absolute top-100 left-0">{errorsFields.waitingDuration}</small>
                )}
              </div>
              <div className="w-full relative">
                <Dropdown
                  className={`w-full ${errorsFields.waitingDurationType ? 'p-invalid' : ''}`}
                  placeholder="Medida de tempo"
                  options={['Segundo(s)', 'Minuto(s)', 'Hora(s)', 'Dia(s)']}
                  value={fields.waitingDurationType}
                  onChange={(e) => setFields({ ...fields, waitingDurationType: e.value })}
                />
                {errorsFields.waitingDurationType && (
                  <small className="p-error absolute top-100 left-0">{errorsFields.waitingDurationType}</small>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="mb-2">
        <LabelText>Resposta incorreta:</LabelText>
      </div>
      <div className="flex align-items-center">
        <span className="white-space-nowrap">Tente novamente</span>
        <Dropdown
          options={['0', '1', '2', '3', '4', '5']}
          scrollHeight="300px"
          className="flex align-items-center h-2rem w-4rem ml-2 mr-2"
          value={fields.retryAnswerMessage}
          onChange={(e) => {
            setFields({ ...fields, retryAnswerMessage: e.value })
          }}
        />
        <span className="white-space-nowrap"> vezes se a resposta for inválida</span>
      </div>

      <InputTextarea
        value={fields.incorrectAnswerMessage}
        className="w-full mt-2"
        autoResize
        placeholder="Repita a seguinte mensagem se a resposta for incorreta"
        onChange={(e) => setFields({ ...fields, incorrectAnswerMessage: e.target.value })}
        rows={5}
      />

      <Button label="Salvar" className="p-button-help w-full mt-4" onClick={handleSubmit} />
    </Dialog>
  )
}
